import React, { useState, useRef, useEffect } from "react";
import { styled } from "@material-ui/core";
interface Props {
    children: React.ReactNode,
    scrollContainerStyles?: React.CSSProperties;
    scrollStyles?: React.CSSProperties;
    prevStyles?: React.CSSProperties,
    nextStyles?: React.CSSProperties
}


const CardScroll: React.FC<Props> = (props: Props) => {
    const { children, scrollContainerStyles, scrollStyles, prevStyles, nextStyles } = props;
    const [isPrevBtnDisabled, setIsPrevBtnDisabled] = useState(true);
    const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(true);
    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const mainContainerRef = useRef<HTMLDivElement>(null)

    const checkScroll = () => {
        const currentScroll = scrollContainerRef?.current?.scrollLeft;
        let scrollLength = 0;
        let scrollVisibleWidth = 0;
        
        if (scrollContainerRef && scrollContainerRef.current) {
            scrollLength = scrollContainerRef?.current?.scrollWidth as number - scrollContainerRef?.current?.clientWidth as number;
            scrollVisibleWidth = scrollContainerRef.current.clientWidth;
        }

        if (currentScroll === 0) {
            setIsPrevBtnDisabled(true);
            setIsNextBtnDisabled(false);
            if((scrollLength+scrollVisibleWidth) <= scrollVisibleWidth){
                setIsNextBtnDisabled(true);
            }
        } else if (currentScroll === scrollLength) {
            setIsNextBtnDisabled(true);
            setIsPrevBtnDisabled(false)
        } else {
            setIsPrevBtnDisabled(false);
            setIsNextBtnDisabled(false);
        }
    }

    const leftScroll = () => {
        scrollContainerRef.current?.scrollBy({
            left: -200,
            behavior: "smooth"
        });
    }

    const rightScroll = () => {
        scrollContainerRef.current?.scrollBy({
            left: 200,
            behavior: "smooth"
        });
    }

    useEffect(() => {
        scrollContainerRef.current?.addEventListener("scroll", checkScroll, true);
        window.addEventListener("resize", checkScroll, true);
        checkScroll()

        return () => {
            scrollContainerRef.current?.removeEventListener("scroll", checkScroll, true);
            window.removeEventListener("resize", checkScroll, true);
        }
    }, [children])

    return (
        <StyledCardScroll className="CardScroll_StyledCardScroll" ref={mainContainerRef}>
            <div className='prev' onClick={() => leftScroll()} style={{ display: isPrevBtnDisabled ? "none" : "", ...prevStyles }}>
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.99953 11.6553L0.345703 6.00148L5.99953 0.347656L7.05335 1.40148L2.45335 6.00148L7.05335 10.6015L5.99953 11.6553Z" fill="white" />
                </svg>
            </div>
            <div className='next' onClick={() => rightScroll()} style={{ display: isNextBtnDisabled ? "none" : "", ...nextStyles }}>
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.00047 0.344693L7.6543 5.99852L2.00047 11.6523L0.946646 10.5985L5.54665 5.99852L0.946646 1.39852L2.00047 0.344693Z" fill="white" />
                </svg>
            </div>
            <div style={{ overflow: "scroll", ...scrollContainerStyles }}>
                <div
                    className="scroll"
                    id="scroll"
                    ref={scrollContainerRef}
                    style={scrollStyles}
                >
                    {
                        children
                    }
                </div>
            </div>
        </StyledCardScroll>
    )
}

const StyledCardScroll = styled("div")({
    "&.CardScroll_StyledCardScroll": {
        position: "relative",
        width: "100% !important",
        display: "flex",
        "& .prev, .next": {
            position: "absolute",
            top: "50%",
            zIndex: 2,
            color: "white",
            cursor: "pointer",
            width: "1.5rem",
            height: "1.5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
            borderRadius: "100%"
        },
        "& .next": {
            right: -10,
            transform: "translate(-50%,-50%)",
        },
        "& .prev": {
            transform: "translate(50%,-50%)",
        },
        "& .scroll": {
            display: "flex",
            width: "100%",
            transition: "transform 0.3s ease-in-out",
            overflow: "scroll",
            "scroll-behavior": "smooth",
            "-webkit-overflow-scrolling": "touch"
        }
    }
})

export default CardScroll;