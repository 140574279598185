import React from "react";
// Customizable Area Start
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { styled } from "@material-ui/styles";
import DashboardEvents from "./components/DashboardEvents.web";
import DashboardReminders from "./components/DashboardReminders.web";
import ConfirmationPopUp from "../../../components/src/ConfirmationPopUp.web";
import CalendarHeader from "../../scheduling/src/components/CalendarHeader.web";
import ReminderDetailsPopup from "../../tasks/src/components/ReminderDetailsPopup.web";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  DashboardContainer = ()=>{
   const { 
    events, 
    appointments, 
    reminders, 
    confirmationPopUpData,
    loading,
    reminderDetails
  } = this.state;
   return (
     <StyledDashboardContainer className="Dashboard_StyledDashboardContainer_Web">
       <CalendarHeader isGridView={true} redirectTo={this.redirectTo} />
       <DashboardEvents 
         redirectTo={this.redirectTo} 
         header="Events" 
         type="event" 
         events={events}
         loading={loading}
        />
       <DashboardEvents 
         redirectTo={this.redirectTo} 
         header="Appointments" 
         type="appointment" 
         events={appointments}
         loading={loading}
        />
       <DashboardReminders 
         reminders={reminders} 
         redirectTo={this.redirectTo} 
         onDelete={this.openConfirmationPopup}
         loading={loading}
         openReminderDetailsPopup={this.openReminderDetailsPopup}
        />
       {
         confirmationPopUpData && (
           <ConfirmationPopUp
             open={Boolean(confirmationPopUpData)}
             header={confirmationPopUpData.header}
             message={confirmationPopUpData.message}
             onAccept={() => this.deleteReminder(confirmationPopUpData.id)}
             onReject={this.onCloseConfirmationPopup}
             onClose={this.onCloseConfirmationPopup}
           />
         )
       }

       {
         reminderDetails && (
           <ReminderDetailsPopup
             reminder={reminderDetails}
             onEdit={(reminderId)=> this.redirectTo("EditReminder", { id: reminderId })}
             onDelete={(title, reminderId) => this.openConfirmationPopup(reminderId, title)}
             onCloseUserListModal={this.closeReminderDetailsPopup}
             onMarkAsComplete={this.onMarkAsCompleted}
           />
         )
       }
     </StyledDashboardContainer>
   )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <NavigationMenu {...this.props} children={this.DashboardContainer()} />
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledDashboardContainer = styled("div")({
  "&.Dashboard_StyledDashboardContainer_Web":{
    display: "flex",
    flexDirection: "column",
    gap:'1.5rem',
    padding: "1rem"
  }
})
// Customizable Area End
