export interface EventsAttributes {
    title: string;
    name?: string;
    date_time_event: string;
    start_time_event: string;
    end_time_event: string;
    venue_details?: string;
    location: {
        id: number;
        latitude: number;
        longitude: number;
        van_id: number | null;
        address: string | null;
        locationable_type: string;
        locationable_id: number;
        place_id: string;
        country: string;
        state: string;
        city: string;
    } ;
    description: string;
    event_capacity?: string;
    is_ticket_price?: boolean;
    event_type?: string;
    ticket_price?: string;
    created_by?: string;
    status: string;
    image_url: string;
    user_contacts: UserContact[];
    assignee_email?: EmailList[];
    click_count?: number;
    account_id: number;
    rsvp_status: string | null;
    is_trending?: boolean;
    invitation_members?:{[key:string]: string}[];
    id?: number | string;
}

export interface IEventData {
    id: string;
    type: string;
    attributes: EventsAttributes;
}

export interface ContactList {
    id: number;
    name: string;
    full_phone_number: string;
    email: string;
    invited_by?: "email" | "phone_number" | null;
    isNonRegistered?: boolean;
    image_url?: string;
}

export interface EmailList {
    id: number;
    full_name: string;
    email: string;
    image_url?: string;
}

export interface IEventCard {
    image: string,
    title: string,
    id: string | number,
    location: {
        id: number;
        latitude: number;
        longitude: number;
        van_id: number | null;
        address: string | null;
        locationable_type: string;
        locationable_id: number;
        place_id: string;
        country: string;
        state: string;
        city: string;
      };
    time: string,
    price: string,
    isTrending: boolean,
    start_time?: string,
    end_time?: string
}

export const EVENT_TYPES = {
    UPCOMING: "upcoming_event",
    BEST: "best_event",
}

export interface IMetaPagination {
    "total_pages": number,
    "total_count": number,
    "current_page": number,
    "next_page": number | null,
    "previous_page": boolean | null
}

export type SearchLocation = {
    description: string;
    place_id: string;
    structured_formatting: {
        main_text: string;
        secondary_text: string;
    }
}

export interface IAddPost {
    id?: number | string;
    postText: string,
    postImages: Array<{selectedUrl: string, imgInvalid: boolean, type: string, blobId?: number, uniqId?: string | number | undefined}>;
    postImgFiles?: Array<File>;
}

export interface UserContact {
    id:number;
    name:string;
    rsvp_status:string | null;
    full_phone_number:string;
    invited_by?: string | null;
    email:string;
    organizer?: boolean;
    image_url?: string;
}

export interface IPhonebookContact {
    invite_record?: string;
    contact_name?: string;
    id: string;
}
  export interface AppointmentAttributes {
    title: string;
    location: {
      [key:string]:string
    };
    appointment_date: string;
    appointment_unique_id?: string;
    start_time: string;
    end_time: string;
    description: string;
    meeting_type: string;
    meeting_link:string;
    reminder_frequency: string;
    account_id: number;
    status: string;
    created_by: string;
    image_url: string | null;
    assignee_email: EmailList[];
    user_contacts: UserContact[];
    rsvp_status: string | null,
    invitation_members?: {[key: string]: string}[],
    id?: string;
  }
  
  export interface IAppointmentData {
    id: string;
    type: string;
    attributes: AppointmentAttributes;
  }

 export interface INavigation{
    navigate: (destination: string, params?:{[key:string]: string})=> void;
    getParam: (param: string)=> string;
    goBack: ()=> void;
 }


 export interface IInterest {
    id: string,
    type: string,
    attributes: {
      name: string,
      created_at: string,
      description?: string;
      manual?: boolean;
    }
  }
 export interface IReminderAttribute {
    title: string,
    date_reminder: string,
    time_reminder: string,
    description: string,
    reminder_frequency: string,
    account_id: number,
    reminder_status: string,
    lapsed_status: boolean,
    created_at: string,
    updated_at: string,
    isSelected?: boolean,
  }

export interface IReminderItem {
    id: string,
    type: string,
    attributes: IReminderAttribute
}

export interface IPostAttribute {
    "id": string | number,
    "name"?: string,
    "description": string | null,
    "body": string,
    "account_id": number,
    "created_at": string,
    "updated_at": string,
    "account_info": {
        "full_name": string,
        "full_phone_number": string,
        "country_code": number,
        "phone_number": number,
        "email": string,
        "activated": boolean,
        "image_url": string | null,
        "created_at": string,
    },
    "images_and_videos": Array<{ id: number, url: string, blob_id?: number }>,
    "total_likes": number,
    "liked_by_me": boolean,
    "comments_count": number,
    "comments_list"?: IComment[],
    "show_comments_section"?: boolean;
    "event_id"?: number | null;
    "group_id"?: number | null; 
}

export interface IPost {
    id: string,
    type: string,
    attributes: IPostAttribute
}
  
export interface IComment {
    id: string;
    type: string;
    attributes: {
        id: number;
        account_id: number;
        commentable_id: number;
        commentable_type: string;
        comment: string;
        created_at: string;
        updated_at: string;
        account_info: {
            full_name: string;
            full_phone_number: string;
            country_code: number;
            phone_number: number;
            email: string;
            activated: boolean;
            image_url: string;
            created_at: string;
        };
        total_likes: number;
        liked_by_me?: boolean;
        raw_created_at?: string;
        reply_count: number;
        show_replies_section?: boolean;
    }
}

interface Interest {
    id: number;
    created_at: string;
    updated_at: string;
    name: string;
    role: string;
};

interface IAccountAttributes {
    activated: boolean;
    country_code: string;
    email: string | null;
    first_name: string | null;
    full_phone_number: string;
    last_name: string | null;
    phone_number: number;
    type: string;
    created_at: string;
    updated_at: string;
    device_id: string | null;
    unique_auth_id: string;
    date_of_birth: string | null;
    user_name: string;
    full_name: string;
    interests: Interest[];
    image_url: string | null;
};

export interface IAccount {
    id: string;
    type: string;
    attributes: IAccountAttributes;
};

export interface IGroupPendingRequest { 
    "id": string,
    "type": string,
    "attributes": {
        "id": number,
        "joined_status": string,
        "account_id": number,
        "group_id": number,
        "joined_by_invitation": boolean,
        "role": string,
        "created_at": string,
        "group_info": {
            "id": number,
            "name": string,
            "description": string,
            "created_at": string,
            "updated_at": string,
            "group_type": string,
            "auto_approve": boolean,
            "click_count": number,
            "image_url": null | string
        },
        "account_info": {
            "id": number,
            "full_phone_number": string,
            "user_name": string,
            "full_name": string,
            "image_url": null | string,
        }
    }
}

export interface IMentionSearch {
    "id": string,
    "type": string,
    "attributes": {
        "account_info": {
            "id": number,
            "full_phone_number": string,
            "user_name": string,
            "full_name": string
        }
    }
} 

export interface IMentionInputType {
    id: string | number, 
    display?: string,
}

export interface IReply {
    "id": string,
    "type": string,
    "attributes": {
        "id": number,
        "descripation": string,
        "comment_id": number,
        "raw_created_at": string,
        "account_info": {
            "id": number,
            "full_phone_number": string,
            "user_name": string,
            "full_name": string,
            "image_url"?: string,
        },
        "likes_count": number,
        "liked_by_me"?: boolean,
    }
}

export interface ILocation {
    id: number;
    latitude: number;
    longitude: number;
    van_id: number | null;
    address: string | null;
    locationable_type: string;
    locationable_id: number;
    place_id: string;
    country: string;
    state: string;
    city: string;
}

export interface IBucketListGoal {
    "id": string,
    "type": string,
    "attributes": {
        "title": string,
        "description": string,
        "location": ILocation,
        "reminder_radius": number,
        "account_id": number,
        "image_url": null | string,
        "status": string,
        "target_date": string,
    }
}

export interface ITask {
    id: string;
    type: "tasks";
    attributes: {
      title: string;
      description: string | null;
      reminder_frequency: "daily" | "weekly" | "monthly" | "yearly"; // Assuming only common frequencies, adjust as necessary
      account_id: number;
      days_to_reminder: number[];
      select_date_to_reminder: string[];
      status: "active" | "completed" | "lapsed"; // Adjust based on possible statuses
      task_date: string; // Date string format
      task_end_date: string; // Date string format
      start_time: string; // Time in AM/PM format
      end_time: string;
      interests: IInterest[];
      cancel_task_record: ISubTask[];
    };
  }
  
  export interface ISubTask {
    id: string;
    type: "sub_tasks";
    attributes: {
      status: "active" | "inactive"; // Adjust based on possible statuses
      account_id: number;
      task_id: number;
      task_cancel_date: string; // Date string format
      task_cancel_end_date: string; // Date string format
    };
  }
export interface IChatGroup {
        "guid": string;
        "name": string;
        "type": string;
        "membersCount"?: number;
        "conversationId": string;
        "createdAt": number;
        "owner": string;
        "updatedAt": number;
        appointment?: AppointmentAttributes | null;
        event?: EventsAttributes | null;
        "isOpen"?: boolean;
}
export interface IGroupChat {
    guid: string[];
    id: string;
    name: string;
    type: string;
    conversationId: string;
    createdAt: number;
    owner: string;
    event_name: string;
    appointment_name: string;
    appointment?: AppointmentAttributes | null;
    event?: EventsAttributes | null;
    isOpen?: boolean;
}

export interface ICometChat {
    id: string;
    type: "comet_chat";
    attributes: {
        guid: string[]; // Array of strings
        comet_name: string;
        comet_type: string | null;
        comet_conversation_id: string | null;
        comet_created_at_timestamp: string | null;
        comet_owner: string | null;
        participants: any[]; // Replace `any` with a specific type if the participant structure is known
    };
}

export interface IChatData {
    group:{
        data: {
            guid: string;
            name: string;
            type: string; // Adjust as per valid chat types
            membersCount: number;
            conversationId: string;
            createdAt: number; // Unix timestamp
            owner: string; // Owner's ID or identifier
        };
    };
    appointment: AppointmentAttributes | null
    event: EventsAttributes | null;
}

export interface IUpdatedChat {
    "id": string;
    "type": string;
    "attributes": {
        "guid": string[];
        "comet_name": string;
        "comet_type": string;
        "comet_conversation_id": string;
        "comet_created_at_timestamp": any;
        "comet_owner": string;
        "participants": string[];
        "isOpen"?: boolean;
    }
}