import React, { useState } from "react";
import { styled } from "@material-ui/styles";
import { CheckIcon, CrossIcon2 } from "../../blocks/appointmentmanagement/src/assets";
import { Box } from "@material-ui/core";
const profile_icon = require("../../blocks/dashboard/assets/profile_icon.jpeg");
interface Props {
    text?: string | null;
    image_url?: string | null;
    status?: string | null;
}


const AvatarComponent: React.FC<Props> = (props: Props) => {
    const { text, image_url, status } = props;
    const [isImageLoading, setIsImageLoading] = useState((image_url) ? true : false);

    const updateImageLoading = () => {
        setIsImageLoading(false)
    }
    
    return (
        <StyledAvatar className="AvatarComponent_StyledAvatar">
            {
                image_url && (
                    <img src={image_url} onError={(e: any) => {
                        e.target.onError = null;
                        e.target.src = `${profile_icon}`;
                      }} onLoad={updateImageLoading} />
                )
            }
            {
                isImageLoading && (
                    <Box top={"22px"} position={"absolute"} className="shimmer shimmer-img"></Box>
                )
            }
            {
                !image_url && text && (
                    <div className="avatar-text">{text[0]}</div>
                )
            }
            {
                !image_url && !text && (
                    <div onLoad={updateImageLoading} className="avatar-text"></div>
                )
            }
            {
                status === "accepted" && (
                    <div className="icon">
                        <img src={CheckIcon} />
                    </div>
                )
            }

            {
                status === "rejected" && (
                    <div className="icon not-going">
                        <img src={CrossIcon2} />
                    </div>
                )
            }
        </StyledAvatar>
    )
}

const StyledAvatar = styled("div")({
    "&.AvatarComponent_StyledAvatar":{
        position: "relative",
        "& > img":{
            height: "2rem",
            width: "2rem",
            borderRadius: "100%"
        },
        "& .shimmer-img": {
            height: "2rem",
            width: "2rem",
            borderRadius: "100%",
            position: "absolute",
            top: "0px"
        },
        "& > .icon": {
            width: "0.75rem",
            height: "0.75rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            right: 0,
            bottom: 0,
            transform: "translate(25%,25%)",
            backgroundColor: "#00FF7F",
            borderRadius: "100%",
            zIndex: 4,
            "&.not-going": {
                backgroundColor: "#FF2F3F"
            }
        },
    }
})

export default AvatarComponent;