// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
import { IGroup } from "../../groups/src/types";
import { IMentionInputType, IPost } from "../../../components/src/CommonTypes";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    groupDetail: IGroup | null;
    postImages?: Array<{ selectedUrl: string, imgInvalid: boolean, type: string }>;
    uploadedFiles?: Array<{file: File, uniqId: number | string}>;
    handleSetIsDetailLoading: (value: boolean) => void;
    redirectTo?: (endpoint: string, params?:{[key:string]:string | number})=> void;
    refreshMentionsList?: (event: any)=> void;
    updateMentionsList?: boolean;
    handleSetClearMentionsListCallback?: () => void;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    attachmentAnchorElement: HTMLImageElement | null;
    inputRef: React.RefObject<HTMLInputElement>;
    inputVideoRef: React.RefObject<HTMLInputElement>;
    inputAllRef: React.RefObject<HTMLInputElement>;
    postImages: Array<{ selectedUrl: string, imgInvalid: boolean, type: string, id?: string | number, blobId?: string | number, uniqId?: number | string }>
    uploadedFiles: Array<{file: File, uniqId: number | string}>;
    selectedImageIndex: number;
    message: string;
    isPostUploading: boolean;
    currentUploadingBlobIds: string[];
    groupPostsList: IPost[];
    postPageNum: number;
    isAllGroupPostsFetched: boolean;
    deletePostId: string | number;
    isEditMode: boolean;
    editPostId: string | number;
    commentMentionsList: IMentionInputType[];
    isPostLoading: boolean;
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class GroupPostCreationController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    uploadFileApiCallIds: string[] = [];
    createGroupPostApiCallId: string = "";
    getAllGroupPostsListApiId: string = "";
    deleteGroupPostApiCallId: string = "";
    editGroupPostApiCallId: string = "";
    removeAttachmentApiCallId: string = "";
    getSearchMentionsListApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            attachmentAnchorElement: null,
            inputRef: React.createRef<HTMLInputElement>(),
            inputVideoRef: React.createRef<HTMLInputElement>(),
            inputAllRef: React.createRef<HTMLInputElement>(),
            postImages: [],
            uploadedFiles: [],
            selectedImageIndex: 0,
            message: "",
            isPostUploading: false,
            currentUploadingBlobIds: [],
            groupPostsList: [],
            isAllGroupPostsFetched: false,
            postPageNum: 1,
            deletePostId: "",
            isEditMode: false,
            editPostId: "",
            commentMentionsList: [],
            isPostLoading: false,
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.getToken();
        // Customizable Area Start
        if(this.props && this.props.postImages && this.props.uploadedFiles){
            this.setState({
                postImages: this.props.postImages,
                uploadedFiles: this.props.uploadedFiles
            })
        }
        this.getGroupPostsList();
        this.getCommentMentionsList();
        if(this.props.refreshMentionsList) {
            this.props.refreshMentionsList(this.getCommentMentionsList)
        }
        // Customizable Area End
    }

    async componentDidUpdate(prevProps: Readonly<Props>) {
        const { groupDetail } = prevProps;
        if(groupDetail && this.props.groupDetail && groupDetail.id !== this.props.groupDetail.id){
           this.clearImages();
           this.setState({ postPageNum: 1, groupPostsList: [] }, () => {
            this.getGroupPostsList();
            this.getCommentMentionsList();
           })
        }
        if(this.props.updateMentionsList) {
            this.getCommentMentionsList();
            this.props.handleSetClearMentionsListCallback?.()
        }
    }

    getToken = () => {
        const message: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(message);
    };

    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = message.getData(getName(MessageEnum.SessionResponseToken));
            if (!token) {
                token = await helper.getStorageData("authToken");
            }
            this.setToken(token);
        }

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
            switch(webApiRequestCallId){
                case this.createGroupPostApiCallId:
                 this.handleCreatePostApiCall(message);
                 break;
                case this.getAllGroupPostsListApiId:
                 this.handleGetGroupPostListCall(message);
                 break;
                case this.deleteGroupPostApiCallId:
                 this.handleDeleteGroupPostCall(message);
                 break;
                case this.editGroupPostApiCallId:
                 this.handleEditPostApiCall(message);
                 break;
                case this.removeAttachmentApiCallId:
                 this.handleDeleteAttachmentFromGroupPostCall(message);
                 break;
                case this.getSearchMentionsListApiCallId:
                 this.handleGetCommentMentionsListCall(message);
                 break;
            }
      
            if(this.uploadFileApiCallIds.includes(webApiRequestCallId)){
              this.handlerUploadFileApiCall(message);
            }
          }

        // Customizable Area End
    };

    // Customizable Area Start

    setToken = async (token: string | null) => {
       this.setState({ token })
    }

    scrollToTopOnAddPost = () => {
        const topDiv = document.getElementById("top_div");
        if(topDiv){
            topDiv.scrollIntoView({
                block: "center",
                behavior: "smooth",
              })
        }
    }

    onOpenAttachmentPopup = (event: React.MouseEvent<HTMLImageElement>)=>{
        if(!this.state.isPostLoading) {
            this.setState({
                attachmentAnchorElement: event.currentTarget
            })
        }
    }

    onCloseAttachmentPopup = ()=>{
        this.setState({
            attachmentAnchorElement: null
        })
    }

    onAddImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { postImages, uploadedFiles } = this.state;
        let invalidImageUploaded = false;
        if (event.target.files?.length) {
            const files = event.target.files;
            const keys = Object.keys(files);
            const resultImageArr: Array<{ selectedUrl: string, imgInvalid: boolean, type: string, uniqId?: string | number }> = [...postImages];
            const filesUploaded: Array<{file: File, uniqId: string | number}> = [...uploadedFiles];
            const promises = keys?.map(async (key: any) => {
                const file = files[key];
                const uuid = uuidv4();
                const result = await helper.covertFileToObjectUrl(file);
                    if(resultImageArr.length < 10) {
                        if (!result.imgInvalid) {
                            const resultObj = { ...result, uniqId: uuid}
                            resultImageArr.push(resultObj);
                            filesUploaded.push({file: file, uniqId: uuid});
                        }else{
                            invalidImageUploaded = true;
                        }
                    }
            })
            await Promise.allSettled(promises);
            if(invalidImageUploaded){
                helper.showErrorToast("Please upload JPG, PNG, or PDF under 2 MB and videos up to 10 MB")
            }
            const validImagesArr = resultImageArr.filter((item) => !item.imgInvalid).slice(0, 10);

            this.setState({
                postImages: [...validImagesArr],
                uploadedFiles: [...filesUploaded]
            })
            this.onCloseAttachmentPopup()
            event.target.value = "";
        }
    }

    handleDeleteAttachmentFromGroupPostCall = (message: Message) => {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
    
        if (responseJson?.errors) {
          const errorPostAttachmentDelToastMsg = responseJson?.errors?.[0]?.message
            ? responseJson.errors[0].message
            : "Something went wrong while removing the file, please try again!";
          helper.showErrorToast(errorPostAttachmentDelToastMsg);
        } 
    };
    
    onRemoveAttachmentFromGroupPost = async (postId: string | number, attachmentBlobId: number | string) => {
        const { groupDetail } = this.props;
        this.removeAttachmentApiCallId = await helper.apiCall({
          method: configJSON.deletePostAPiMethod,
          endPoint: `${configJSON.removeGroupPostAttachment}?group_id=${groupDetail?.id}&blob_id=${attachmentBlobId}&id=${postId}`,
          contentType: configJSON.validationApiContentType,
          token: this.state.token,
        });
    };

    removeFile = (fileUniqId: string | number | undefined) => {
        const postFileItem = this.state.postImages.find((item) => item.uniqId === fileUniqId);
        if(postFileItem?.blobId && this.state.editPostId) {
            this.onRemoveAttachmentFromGroupPost(this.state.editPostId, postFileItem.blobId)
        }
        const findCurrentIndex = this.state.postImages.findIndex((item) => item.uniqId === fileUniqId)

        this.setState((prevState) => {
            const { selectedImageIndex, postImages, uploadedFiles } = prevState;
            let newSelectImageIndex = selectedImageIndex;
            if (findCurrentIndex < newSelectImageIndex) {
                newSelectImageIndex--;
            } else if (findCurrentIndex === newSelectImageIndex) {
                newSelectImageIndex = newSelectImageIndex - 1 >= 0 ? (newSelectImageIndex - 1) : 0; // Adjust for boundary case
            }

            this.changeSelectedImageIndex(newSelectImageIndex)
    
            return {
                postImages: postImages.filter((item) => item.uniqId !== fileUniqId),
                uploadedFiles: uploadedFiles.filter((item) => item.uniqId !== fileUniqId)
            };
        });
    }

    changeSelectedImageIndex = (fileIndex: number)=>{
        this.setState({
            selectedImageIndex: fileIndex
        })
    }

    onChangeHandler = (value: string)=>{
       const sanitizedValue = value.replace(/^\s+/, '');
       this.setState({
          message: sanitizedValue
       })
    }

    clearImages = ()=>{
        this.setState({
            postImages: [],
            uploadedFiles: [],
            selectedImageIndex: 0,
            message: ""
        })
    }

    handleKeyPressSendPost = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && (this.state.message?.trim() || this.state.postImages.length > 0)) {
            this.onClickSend();
        }
    }

    onClickSend = async () => {
        const { uploadedFiles } = this.state;
        this.setState({ isPostLoading: true })
        if (uploadedFiles && uploadedFiles.length) {
            uploadedFiles.forEach(async (file) => {
                const formData = new FormData();
                formData.append("media", file.file);

                const apiId = await helper.uploadFilesToServer({
                    body: formData,
                    type: "formData",
                    token: this.state.token
                });
                this.uploadFileApiCallIds.push(apiId)
            })
        } else {
            this.onCreatePost();
        }
    }

    handlerUploadFileApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.blob_id) {
            this.setState(prevState => {
                return {
                    currentUploadingBlobIds: [...prevState.currentUploadingBlobIds, responseJson.blob_id]
                }
            }, () => {
                const { currentUploadingBlobIds, uploadedFiles } = this.state;
                if (uploadedFiles.length === currentUploadingBlobIds.length) {
                    this.onCreatePost();
                }
            })
        }else{
            if(responseJson){
                const {
                   errors
                } = responseJson;
                if(errors && errors.length){
                    errors.forEach((error: {message: string}) => {
                        helper.showErrorToast(error.message);
                    });
                }
            }else{
                helper.showErrorToast("Something went wrong! please try again");
            }
            this.setState({ isPostLoading: false })
            helper.hideLoader();
        }
    }

    onCreatePost = async () => {
        this.setState({ isPostLoading: true })
        this.props.handleSetIsDetailLoading(true)
        const { currentUploadingBlobIds, message, isEditMode, editPostId } = this.state;
        const { groupDetail } = this.props;
        const body: { body?: string; blob_ids?: string[], group_id?: string | number } = {};

        body.body = message?.trim() || ""

        if(currentUploadingBlobIds?.length){
            body.blob_ids = [currentUploadingBlobIds.join(",")]
        }

        if(groupDetail){
            const formData = new FormData();
            formData.append("body", message.trim() || "")
            formData.append("group_id", (groupDetail.id).toString())
            if (currentUploadingBlobIds.length) {
                formData.append("blob_ids", `[${JSON.stringify(currentUploadingBlobIds.join(","))}]`)
            }
            if(isEditMode) {
                this.editGroupPostApiCallId = await helper.apiCall({
                    method: configJSON.putAPiMethod,
                    token: this.state.token,
                    endPoint: `${configJSON.groupPostApiEndpoint}/${editPostId}`,
                    body: formData,
                    type: "formData",
                });
            } else {
                this.createGroupPostApiCallId = await helper.apiCall({
                    method: configJSON.PostAPiMethod,
                    token: this.state.token,
                    endPoint: `${configJSON.groupPostApiEndpoint}?group_id=${groupDetail.id}`,
                    body: body,
                    contentType: configJSON.exampleApiContentType,
                });
            }
        }
        
        this.setState({
            postImages: [],
            uploadedFiles: [],
            selectedImageIndex: 0,
            currentUploadingBlobIds: [],
            message: "",
            isEditMode: false,
        });
        this.uploadFileApiCallIds = []
    };

    handleCreatePostApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(responseJson && responseJson.data){
            this.handleAddCreatedPost(responseJson.data);
        } else {
            if (responseJson?.errors) {
                const errorDelToastMsg = responseJson?.errors?.[0]
                    ? responseJson.errors[0]
                    : "Something went wrong while removing the file, please try again!";
                helper.showErrorToast(errorDelToastMsg);
            }
        }
        this.setState({ isPostLoading: false })
        this.props.handleSetIsDetailLoading(false)
    }

    handleEditPostApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(responseJson?.data){
            const updatedPostsList = this.state.groupPostsList.map((item) => {
                if(item.id === this.state.editPostId) {
                    item = { ...responseJson.data}
                }
                return item;
            })
            this.setState({ groupPostsList: updatedPostsList })
        } else{
            if(responseJson){
                const {
                   errors
                } = responseJson;
                if(errors && errors.length){
                    errors.forEach((error: { message: string }) => {
                        helper.showErrorToast(error.message);
                    });
                }
            }else{
                helper.showErrorToast("Something went wrong! please try again");
            }
        }
        this.setState({ editPostId: "", isPostLoading: false })
        this.props.handleSetIsDetailLoading(false)
    }

    onEditGroupPost = (postDetails: IPost) => {
        this.setState({ message: postDetails.attributes.body, isEditMode: true, editPostId: postDetails.id })
        const postAttachments: Array<{ selectedUrl: string, imgInvalid: boolean, type: string, blobId?: string | number, uniqId: string | number }> = postDetails.attributes.images_and_videos.map((item) => {
            return (
                { id: item.id, selectedUrl: item.url, imgInvalid: false, blobId: item.blob_id, type: helper.getFileTypeFromURL(item.url), uniqId: uuidv4() }
            )
        })
        if(postAttachments.length) {
            this.setState({ postImages: postAttachments })
        }
    }

    handleChangePageNumber = () => {
        if (!this.state.isAllGroupPostsFetched) {
          this.setState({ postPageNum: this.state.postPageNum + 1 }, () => this.getGroupPostsList());
        }
    };

    handleGetCommentMentionsListCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson?.data) {
            const mentionsList: Array<IMentionInputType> = responseJson.data?.map((item: { attributes: { account_info: { id: number, user_name: string, name: string } } }) => {
                return {
                    id: item.attributes.account_info.id.toString(),
                    display: item.attributes.account_info.user_name,
                }
            })
            this.setState({
                commentMentionsList: [...mentionsList],
            });
        } else {
            this.setState({ commentMentionsList: [] });
        }
    };

    getCommentMentionsList = async () => {
        const { groupDetail } = this.props;
        const token = await helper.getStorageData("authToken");
        this.getSearchMentionsListApiCallId = await helper.apiCall({
            method: configJSON.validationApiMethodType,
            contentType: configJSON.validationApiContentType,
            token,
            endPoint: `${configJSON.searchMention}?group_id=${groupDetail?.id}`,
        });
    }
    
    handleGetGroupPostListCall = (message: Message) => {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (!responseJson?.meta?.next_page) {
          this.setState({ isAllGroupPostsFetched: true });
        }
        if (responseJson?.data) {
          this.setState({
            groupPostsList: [...this.state.groupPostsList, ...responseJson.data],
          });
        } else {
          this.setState({ groupPostsList: [] });
        }
        this.setState({ isPostLoading: false })
        this.props.handleSetIsDetailLoading(false)
    };
    
    getGroupPostsList = async () => {
        this.setState({ isPostLoading: true })
        this.props.handleSetIsDetailLoading(true)
        const { groupDetail } = this.props;
        const token = await helper.getStorageData("authToken")
        this.getAllGroupPostsListApiId = await helper.apiCall({
          method: configJSON.validationApiMethodType,
          contentType: configJSON.validationApiContentType,
          token: token,
          endPoint: `${configJSON.groupPostApiEndpoint}?group_id=${groupDetail?.id}&per_page=15&page_no=${this.state.postPageNum}`,
        });
    };

    handleUpdateLikeGroupPost = (postId: number | string, action?: string) => {
        const updatedPostList = this.state.groupPostsList.map((item) => {
            if(item.id === postId){
                if(item.attributes.liked_by_me) {
                    item.attributes.total_likes -= 1
                } else {
                    item.attributes.total_likes += 1
                }
                item.attributes.liked_by_me = !item.attributes.liked_by_me;
            }
            return item
        })
        this.setState({ groupPostsList: updatedPostList })
    }

    handleUpdatePostCommentsCount = (postId: number | string, action?: string) => {
        const updatedPostList = this.state.groupPostsList.map((item) => {
            if(item.id === postId){
                if(action === "decrease") {
                    item.attributes.comments_count -= 1
                } else {
                    item.attributes.comments_count += 1
                }
            }
            return item
        })
        this.setState({ groupPostsList: updatedPostList })
    }

    handleShowCommentSection = (postId: number | string) => {
        const updatedPostList = this.state.groupPostsList.map((item) => {
            if(item.id === postId){
                item.attributes.show_comments_section = true
            } else { 
                item.attributes.show_comments_section = false
            }
            return item
        })
        this.setState({ groupPostsList: updatedPostList })
    }

    handleHideCommentSection = (postId: number | string) => {
        const updatedPostList = this.state.groupPostsList.map((item) => {
            if(item.id === postId){
                item.attributes.show_comments_section = false
            }
            return item
        })
        this.setState({ groupPostsList: updatedPostList })
    }
    
    handleAddCreatedPost = (postDetail: IPost) => {
        this.setState({ groupPostsList: [{ ...postDetail}, ...this.state.groupPostsList]});
        this.scrollToTopOnAddPost();
    }

    handleDeleteGroupPostCall = (message: Message) => {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (!responseJson?.errors) {
          helper.showSuccessToast("Group Post deleted successfully!");
          const { groupPostsList, deletePostId } = this.state;
          const updatedPostsList = groupPostsList.filter(
            (item) => item.id !== deletePostId
          );
          this.setState({ groupPostsList: updatedPostsList });
        } else {
          const errorDelToastMsg = responseJson?.errors?.[0]?.message
            ? responseJson.errors[0].message
            : "Something went wrong, please try again!";
          helper.showErrorToast(errorDelToastMsg);
        }
        this.setState({ deletePostId: "", isPostLoading: false });
        this.props.handleSetIsDetailLoading(false)
    };
    
    onDeleteGroupPost = async (postId: string | number, groupId: number | string) => {
        this.setState({ isPostLoading: true })
        this.props.handleSetIsDetailLoading(true)
        this.setState({ deletePostId: postId }, async() => {
            this.deleteGroupPostApiCallId = await helper.apiCall({
              method: configJSON.deletePostAPiMethod,
              endPoint: `${configJSON.groupPostApiEndpoint}/${postId}?group_id=${groupId}`,
              contentType: configJSON.validationApiContentType,
              token: this.state.token,
            });
        })
    };

    // Customizable Area End
}