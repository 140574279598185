// Customizable Area Start
import React from "react";
import GroupPostCreationController, {
    Props
} from "./GroupPostCreationController";
import { styled } from "@material-ui/styles";
import { Menu } from "@material-ui/core";
import { SendIcon, AttachIcon, ImageIcon, VideoIcon, pdfPlaceholder, closeIcon, AddIcon } from "./assets";
import { CardScroll } from "../../../components/src";
import GroupPostsList from "../../activityfeed/src/GroupPostsList.web";
// Customizable Area End

export default class GroupPostCreation extends GroupPostCreationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    AttachmentPopup = () => {
        const {
            attachmentAnchorElement,
            inputRef,
            inputVideoRef
        } = this.state;
        return (
            <>
                <Menu
                    open={Boolean(attachmentAnchorElement)}
                    anchorEl={attachmentAnchorElement}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={this.onCloseAttachmentPopup}
                    PaperProps={{
                        style: {
                            background: "transparent",
                            transform: "translate(-30%, -120%)"
                        }
                    }}
                    MenuListProps={{
                        style: {
                            padding: "0",
                        }
                    }}
                    getContentAnchorEl={null}
                >
                    <StyledAttachmentPopup className="AttachmentPopup_StyledAttachmentPopup">
                        <div className="item" onClick={()=> {
                            if(inputRef.current){
                                inputRef.current.click()
                            }
                        }}>
                            <img src={ImageIcon} />
                            Upload Image/Pdf
                        </div>
                        <div className="item" onClick={()=> {
                            if(inputVideoRef.current){
                                inputVideoRef.current.click()
                            }
                        }}>
                            <img src={VideoIcon} />
                            Upload Video
                        </div>
                    </StyledAttachmentPopup>
                </Menu>
            </>
        )
    }

    InputField = () => {
        const {
            inputRef,
            inputVideoRef,
            inputAllRef,
            message,
            postImages,
            isPostLoading
        } = this.state;
        return (
            <StyledInputField className="GroupPostCreation_StyledInputField">
                <input placeholder="Message" onKeyPress={this.handleKeyPressSendPost} value={message} onChange={(event)=> this.onChangeHandler(event.target.value)} />
                <div className="actions">
                    <img src={AttachIcon} className={`${!isPostLoading ? "" : "disabled"}`} onClick={this.onOpenAttachmentPopup} />
                    <img 
                      src={SendIcon} 
                      className={`${((message?.trim() || postImages.length > 0) && !isPostLoading) ? "" : "disabled"}`} 
                      onClick={()=>{
                        if((message?.trim() || postImages.length > 0) && !isPostLoading){
                            this.onClickSend()
                        }
                      }} 
                    />
                    {this.AttachmentPopup()}
                </div>
                <input
                    type="file"
                    ref={inputRef}
                    onChange={this.onAddImage}
                    multiple
                    accept="image/png, image/jpg, image/jpeg, application/pdf"
                    style={{display: "none"}}
                />
                <input
                    type="file"
                    ref={inputVideoRef}
                    onChange={this.onAddImage}
                    multiple
                    accept="video/*"
                    style={{display: "none"}}
                />
                <input
                    type="file"
                    ref={inputAllRef}
                    onChange={this.onAddImage}
                    multiple
                    accept="image/png, image/jpg, image/jpeg, video/*, application/pdf"
                    style={{display: "none"}}
                />
            </StyledInputField>
        )
    }

    SelectedImageContainer = () => {
        const {
            postImages,
            selectedImageIndex
        } = this.state;
        return (
            <>
                {
                    postImages[selectedImageIndex] && (
                        <StyledSelectedImageContainer className="GroupPostCreation_StyledSelectedImageContainer">
                            {postImages[selectedImageIndex].type?.includes("image") && <img className="post-img" src={postImages[selectedImageIndex].selectedUrl} alt="uploaded-post" />}
                            {postImages[selectedImageIndex].type?.includes("pdf") && <img className="post-img pdf" src={pdfPlaceholder} alt="uploaded-post" />}
                            {postImages[selectedImageIndex].type?.includes("video") && <video controls controlsList="nodownload" className="post-img" src={postImages[selectedImageIndex].selectedUrl} />}
                        </StyledSelectedImageContainer>
                    )
                }
            </>
        )
    }

    ImageScrollContainer = ()=>{
      const {
        postImages,
        inputAllRef,
        isPostLoading,
      } = this.state;
      return (
          <StyledImageScrollContainer className="GroupPostCreation_StyledImageScrollContainer">
             <CardScroll 
               scrollContainerStyles={{ display: "flex", width: "100%", justifyContent: 'center' }} 
               scrollStyles={{ width: "fit-content" }}
            >
                {
                   postImages.map((item, imgIndex)=>{
                      return (
                          <div className="card" key={item.selectedUrl} onClick={()=> this.changeSelectedImageIndex(imgIndex)}>
                              {item.type?.includes("image") && <img className="post-img" src={item.selectedUrl} alt="uploaded-post" />}
                              {item.type?.includes("pdf") && <img className="post-img" src={pdfPlaceholder} alt="uploaded-post" />}
                              {item.type?.includes("video") && <video id="video-player" controls={false} onClick={(event)=> event.preventDefault()} controlsList="nodownload" className="post-img" src={item.selectedUrl} />}
                              <div className="remove-post-img-icon" onClick={() => this.removeFile(item.uniqId)} style={{position: "absolute", top: "0.5rem", right: "0.5rem"}}>
                                  <img src={closeIcon} />
                              </div>
                          </div>
                      )
                   })
                }
                <div style={{ opacity: (postImages.length === 10 || isPostLoading) ? 0.6 : 1}} className={`card add-image ${postImages.length === 10 ? "cursor-none" : ""}`} onClick={()=>{
                   if(inputAllRef.current && postImages.length < 10 && !isPostLoading){
                    inputAllRef.current.click()
                   } 
                }}>
                   <img src={AddIcon} />
                </div>
             </CardScroll>
          </StyledImageScrollContainer>
      )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { groupDetail } = this.props;
        const {
            postImages,
            groupPostsList,
            commentMentionsList,
            isPostLoading,
        } = this.state;
        return (
            <StyledGroupPostCreationContainer 
              className={`GroupPostCreationContainer_StyledGroupPostCreationContainer ${postImages.length > 0 ? "active" : ""}`}
            >
                {groupDetail && postImages.length === 0 && <GroupPostsList redirectTo={this.props.redirectTo} isPostLoading={isPostLoading} commentMentionsList={commentMentionsList} handleUpdateLikeGroupPost={this.handleUpdateLikeGroupPost} handleDeleteGroupPost={this.onDeleteGroupPost} handleUpdateCommentsCount={this.handleUpdatePostCommentsCount} handleCommentSectionShow={this.handleShowCommentSection} groupId={groupDetail?.id} handleCommentSectionHide={this.handleHideCommentSection} groupPostsList={groupPostsList} handleFetchNextPagePosts={() => this.handleChangePageNumber()} handleEditGroupPost={this.onEditGroupPost} />}
                {
                    postImages.length > 0 && (
                        <div>
                            <div className="close-icon" onClick={this.clearImages}>
                                <img src={closeIcon} />
                            </div>
                        </div>
                    )
                }
                {
                   postImages.length > 0 && (
                      this.SelectedImageContainer()
                   )
                }
                {groupDetail?.is_current_user_member && this.InputField()}
                {
                   postImages.length > 0 && (
                      this.ImageScrollContainer()
                   )
                }
            </StyledGroupPostCreationContainer>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledGroupPostCreationContainer = styled("div")({
    "&.GroupPostCreationContainer_StyledGroupPostCreationContainer": {
        position: "absolute",
        bottom: "1rem",
        left: "1rem",
        width: "calc(100% - 2rem)",
        boxSizing: "border-box",
        display: "flex",
        maxHeight: "94%",
        flexDirection: "column",
        overflowY: "auto",
        "&.active":{
            height: "calc(100% - 2rem)",
            top: "1rem",
        },
        "& .close-icon":{
            background: "rgba(0, 0, 0, 0.2)",
            width: "2.5rem",
            height: "2.5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            borderRadius: "100%"
        }
    }
})

const StyledInputField = styled("div")({
    "&.GroupPostCreation_StyledInputField": {
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        border: "1px solid rgba(25, 181, 185, 0.27)",
        borderRadius: "6.25rem",
        display: "flex",
        width: "100%",
        boxSizing: "border-box",
        height: "3rem",
        alignItems: "center",
        gap: "1rem",
        padding: "0rem 1rem",
        flexShrink: 0,
        "& > input": {
            width: "100%",
            border: "none",
            outline: "none",
            background: "transparent",
            fontSize: "1rem",
            fontWeight: 400,
            color: "rgba(255, 255, 255, 0.8)"
        },
        "& > .actions": {
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            "& > img": {
                "&:nth-child(2)": {
                    width: "24px"
                },
                cursor: "pointer",
                "&.disabled":{
                    opacity: 0.3,
                    cursor: "not-allowed"
                }
            }
        }
    }
})

const StyledAttachmentPopup = styled("div")({
    "&.AttachmentPopup_StyledAttachmentPopup": {
        display: "flex",
        flexDirection: "column",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        border: "1px solid rgba(25, 181, 185, 0.27)",
        borderRadius: "0.5rem",
        width: "13.375rem",
        height: "4.75rem",
        justifyContent: "space-around",
        padding: "0rem 1rem",
        boxSizing: "border-box",
        "& .item": {
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            fontSize: '1rem',
            fontWeight: 400,
            color: "rgba(255, 255, 255, 1)",
            cursor: "pointer"
        }
    }
})

const StyledSelectedImageContainer = styled("div")({
    "&.GroupPostCreation_StyledSelectedImageContainer": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "2rem",
        marginTop: "1rem",
        height: "42.0625rem",
        "& .pdf": {
            objectFit: "contain",
            maxHeight: "23.9375rem",
            maxWidth: "42.0625rem",
            borderRadius: "1.65rem",
            minWidth: "auto"
        },
        "& > img": {
            maxHeight: "23.9375rem",
            maxWidth: "42.0625rem",
            minWidth: "80%",
            borderRadius: "1.65rem"
        },
        "& > video": {
            maxHeight: "23.9375rem",
            maxWidth: "42.0625rem",
            minWidth: "80%",
            borderRadius: "1.65rem"
        }
    }
})

const StyledImageScrollContainer = styled("div")({
    "&.GroupPostCreation_StyledImageScrollContainer":{
        display: "flex",
        width: "100%",
        boxSizing: "border-box",
        marginTop: "1rem",
        "& .card":{
            width: "4.98rem",
            height: "5.0325rem",
            display: "flex",
            flexShrink: 0,
            marginRight: "0.5rem",
            cursor: "pointer",
            userSelect: "none",
            position: "relative",
            "& > img": {
                width: "100%",
                height: "100%",
                borderRadius: "1rem",
                objectFit: "cover",
            },
            "& > video": {
                width: "100%",
                height: "100%",
                borderRadius: "1rem",
                objectFit: "cover",
            },
            "&:last-child":{
                marginRight: 0
            },
            "& .remove-post-img-icon": {
                background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
                width: "1.25rem",
                height: "1.25rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "100%",
                cursor: "pointer",
                "& img": {
                    width: "6px",
                    height: "6px"
                }
            },
            "&.add-image":{
                background: "rgba(0, 0, 0, 0.1)",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "1rem",
                "& > img":{
                    width: "1rem",
                    height: "1rem"
                }
            }
        }
    }
})
// Customizable Area End
