import React from "react";
import { styled } from "@material-ui/styles";
import Button from "../../../../components/src/Button.web";
import CustomNoDataComponent from "../../../../components/src/CustomNoDataComponent.web";
import DashboardReminderCard from "./DashboardReminderCard.web"
import { IReminders, IReminderItem } from "../../../tasks/src/types";


interface DashboardRemindersProps {
    reminders: IReminders[];
    redirectTo: (endpoint: string, params?: {[key:string]: string})=> void;
    onDelete: (id?: string, title?: string)=> void;
    loading?: boolean;
    openReminderDetailsPopup?: (details: IReminderItem)=> void; 
}


const DashboardReminders: React.FC<DashboardRemindersProps> = (props: DashboardRemindersProps) => {
    const { reminders, redirectTo, onDelete, loading, openReminderDetailsPopup } = props;
    const goToReminders = ()=>{
        redirectTo("Reminders")
    }
    return (
        <StyledDashboardReminders className="DashboardReminders_StyledDashboardReminders_Container">
            <div className="events-header">
                <header>Reminders</header>
                <Button
                  text="View All"
                  type="link"
                  onClick={goToReminders}
                />
            </div>
            {
                reminders && reminders.length > 0 && (
                    <div className="reminders-container">
                       {
                        reminders.map((reminder, reminderInd)=> {
                            return (
                                <DashboardReminderCard
                                  onDelete={onDelete} 
                                  key={reminderInd} 
                                  reminder={reminder} 
                                  redirectTo={redirectTo} 
                                  openReminderDetailsPopup={openReminderDetailsPopup}
                                />
                            )
                        })
                       }
                    </div>
                )
            }
            {
                !loading && reminders && reminders.length === 0 && (
                    <CustomNoDataComponent 
                      titleMsg={"No Reminders"} 
                      subTitleMsg={"No reminders. Stay tuned for the updates."}
                      useCommonEmptyImg={true} 
                    />
                )
            }
        </StyledDashboardReminders>
    )
}

const StyledDashboardReminders = styled("div")({
  "&.DashboardReminders_StyledDashboardReminders_Container":{
     display: "flex",
     flexDirection: "column",
     gap: "0.5rem",
     "& .events-header":{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& > header":{
            fontSize: "1rem",
            fontWeight: 500,
            color: "rgba(255, 255, 255, 1)"
        }
     },
     "& > .reminders-container":{
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
     }
  }
})

export default DashboardReminders;