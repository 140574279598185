// Customizable Area Start
import React from "react";
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import ViewGroupChatController, {
    Props
} from "./ViewGroupChatController";
import { styled } from "@material-ui/styles";
import { ChatInputField, Avatar, ModalLoader } from "../../../components/src";
import GroupChatInfo from "./GroupChatInfo.web";
import { CometChat } from '@cometchat-pro/chat';
import { Tooltip } from "@material-ui/core";
const {
    HelperFunctions: helper,
} = require("../../../components/src/HelperFunctions");
// Customizable Area End

export default class ViewGroupChat extends ViewGroupChatController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    Message = (message: CometChat.BaseMessage) => {
        return (
            <StyledMessage className={`ViewGroupChat_StyledMessage ${this.isSendByMe(message) ? "send" : "received"}`}>
                {
                    !this.isSendByMe(message) && (
                        <Avatar
                            text={message.getSender().getName()}
                            image_url={message.getSender().getAvatar()}
                        />
                    )
                }
                <div className="message">
                    {
                        !this.isSendByMe(message) && (
                            <p className="name">{message.getSender().getName()}</p>
                        )
                    }
                    <p id="comet-message" className="text">{(message as CometChat.TextMessage).getText && helper.replaceChatUsernamesWithStyling((message as CometChat.TextMessage).getText(), this.handleGoToMentionedProfile)}</p>
                    <p className="time">{this.formatTime(message.getSentAt())}</p>
                </div>
            </StyledMessage>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { closeGroupChatPopup, isFromAppointmentOrEvent } = this.props;
        const {
            openChatInfo,
            messages,
            loading,
            group,
            groupMembers,
            hideWhenOpenChatInfo,
            chatData,
            chatMentionsList,
        } = this.state;
        return (
            <StyledViewGroupChat className={`ViewGroupChat_StyledViewGroupChat ${openChatInfo ? "info-active" : ""}`}>
                {!hideWhenOpenChatInfo && <div className="chats">
                    <div className="header">
                        {
                            group && (
                                <div className="clickable-container" onClick={this.toggleChatInfo}>
                                    <div className="chat-image">{helper.getInitials(group.getName())}</div>
                                    <Tooltip placement="top-start" title={helper.toPascalCase(group.getName())}>
                                        <p className="group-name capital-text ellipsis">{group.getName()}</p>
                                    </Tooltip>
                                </div>
                            )
                        }
                        {(isFromAppointmentOrEvent && chatData) && <ClearSharpIcon id="close_view_group_chat" onClick={() => closeGroupChatPopup?.(chatData.group.data.guid)} />}
                    </div>
                    <div className="chat-messages">
                        <div className="messages show-scrollbar" id="chat-container">
                            <div className="welcome-message">Welcome to the appointment chat</div>
                            {
                                messages && messages.filter(message=> message.getCategory() === "message").map((message, message_index) => {
                                    return (
                                        <React.Fragment key={message_index}>
                                            {this.Message(message)}
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                        <div className="input-field">
                            <ChatInputField mentionesUserList={chatMentionsList} postImages={[]} onClickSend={this.sendMessage} />
                        </div>
                    </div>
                </div>}
                {
                    openChatInfo && (
                        <div className={isFromAppointmentOrEvent ? "info in-popup" : "info"}>
                           <GroupChatInfo 
                             {...this.props} 
                             closeInfo={this.toggleChatInfo}
                             group={group}
                             groupMembers={groupMembers}
                             isFromAppointmentOrEvent={isFromAppointmentOrEvent}
                             closeChatRoom={() => closeGroupChatPopup?.(chatData?.group.data.guid)}
                             onClickAddMemberButton={this.props.onClickAddMemberButton}
                             onClickEditButton={this.props.onClickEditButton}
                             onDeleteGroupChat={this.props.onDeleteGroupChat}
                             chatData={chatData}
                             onClickRemoveMember={this.props.onClickRemoveMember}
                            />
                        </div> 
                    )
                }
                <ModalLoader isLoading={loading} />
            </StyledViewGroupChat>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledViewGroupChat = styled("div")({
    "&.ViewGroupChat_StyledViewGroupChat": {
        display: "flex",
        height: "100%",
        position: "relative",
        "& > .chats": {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            "& > .header": {
                display: "flex",
                padding: "1rem",
                boxSizing: "border-box",
                width: "100%",
                height: "4.75rem",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                "& > .clickable-container": {
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    cursor: "pointer",
                    "& > .chat-image": {
                        width: "2.5rem",
                        height: "2.5rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "rgba(0, 0, 0, 0.2)",
                        borderRadius: "0.5rem",
                        fontSize: "1.25rem",
                        fontWeight: 400,
                        color: "rgba(255, 255, 255, 0.8)",
                        textTransform: "uppercase"
                    },
                    "& > p": {
                        fontSize: "1.125rem",
                        fontWeight: 500,
                        color: "rgba(255, 255, 255, 1)"
                    }
                },
                "& svg": {
                    color: "#fff",
                    cursor: "pointer",
                },
                "& .group-name": {
                    width: "15rem",
                    [`@media screen and (max-width: 960px)`]: {
                        width: "12rem",
                    },
                    [`@media screen and (max-width: 344px)`]: {
                        width: "9rem !important",
                    },
                }
            },
            "& > .chat-messages": {
                display: "flex",
                width: "100%",
                height: "calc(100% - 4.75rem)",
                flexDirection: "column",
                "& > .messages": {
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    gap: "1.5rem",
                    padding: "1rem",
                    boxSizing: "border-box",
                    overflow: "auto",
                    "& > .welcome-message": {
                        fontSize: "0.75rem",
                        fontWeight: 400,
                        color: "rgba(255, 255, 255, 0.8)",
                        textAlign: "center"
                    }
                },
                "& > .input-field": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0.5rem 1rem 1rem"
                }
            }
        },
        "& > .info": {
            width: "50%"
        },
        "& > .in-popup": {
            width: "100% !important"
        },
        "&.info-active": {
            "& > .chats": {
                width: "50%",
                borderRight: "1px solid rgba(255, 255, 255, 0.1)"
            }
        }
    }
})

const StyledMessage = styled("div")({
    "&.ViewGroupChat_StyledMessage": {
        display: "flex",
        gap: "0.25rem",
        alignItems: "flex-end",
        width: "100%",
        "& > .message": {
            background: "rgba(0, 0, 0, 0.15)",
            padding: "0.375rem 0.5rem",
            borderRadius: "0.75rem 0.75rem 0.75rem 0",
            boxSizing: "border-box",
            "& > .name": {
                fontSize: "0.875rem",
                fontWeight: 500,
                color: "rgba(244, 116, 116, 1)"
            },
            "& > .text": {
                fontSize: "0.875rem",
                fontWeight: 400,
                color: "rgba(255, 255, 255, 1)"
            },
            "& > .time": {
                fontSize: "0.75rem",
                fontWeight: 400,
                color: "rgba(255, 255, 255, 0.5)",
                textAlign: "right"
            }
        },
        "&.send": {
            justifyContent: "flex-end",
            "& > .message": {
                borderRadius: "0.75rem 0.75rem 0rem 0.75rem"
            }
        }
    }
})
// Customizable Area End
