import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import _ from "lodash";
import { UserContact } from "./AppointmentsController";
const { ApiCallFunction: apiCallFunction, rearrangeContacts } = require("./ApiCallFunction");
export const configJSON = require("./config.js");
import { ContactList, IGroupChat, AppointmentAttributes, IChatData, IAppointmentData } from "../../../components/src/CommonTypes";
const { HelperFunctions } = require("../../../components/src/HelperFunctions");
import { searchContactApiCallHandler,
  createChat, 
  handleEditGroupChat,
  handleAddMembersInGroupChat,
  deleteGroupChat,
  handleDeleteGroupChat,
  addMembersInGroupChat,
  editGroupChat,
  removeMembersInGroupChat,
  handleRemoveMembersFromGroupChat,
  getGroupChatInfo} from "../../chat/src/actions";
import { CometChat } from "@cometchat-pro/chat";
import React from "react";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  id: string | number;
  userId: number | undefined;
  token: string;
  appointment: IAppointmentData | null;
  isRsvpStatusUpdateMode: boolean;
  invitedUserList: {
    owner_id:number;
    invited_users: UserContact[],
    invitation_members: {[key: string]: string}[]
  } | null;
  activeTab: string | null;
  optionAnchorEle: HTMLElement | null;
  isDeleteModalOpen: boolean;
  meetingTitle: string;
  chatList: IGroupChat[];
  openChatList: IGroupChat[];
  isChatListOpen: boolean;
  chatSearchString: string;
  isChatLoading: boolean;
  matchMedia: boolean;
  openChatCreateForm: boolean;
  contactList: ContactList[];
  phoneBookContactList: ContactList[];
  fullContactList: ContactList[];
  fullPhoneBookContactList: ContactList[];
  chatAppointment: AppointmentAttributes | null;
  selectedApptGroupChatForEdit: IChatData | null;
  editChatMode: boolean;
  isAddMemberToChatMode: boolean;
  selectedMembersForEditChat: ContactList[];
  selectedDeletedChatGuid: string;
  updateChildChatComponent?: any;
  registeredAppointmentContactsPagination: {
    per_page: number,
    page_no: number,
    hasMore: boolean
  }
  callbackForContacts?: any;
  isLoadingContacts: boolean;
  chatPage: number;
  isAllChatsLoaded: boolean;
  isAddMemberModeFromEditScreen: boolean;
  selectedGroupChatForAddMemberEdit: IChatData | null;
  confirmationRemoveMemberFromApptChatPopUpData: {
      header: string;
      message: string;
      type: string;
      guid: string;
  } | null;
  tempMemberIdToRemove: number;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AppointmentDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAppointmentApiCallId?: string;
  rsvpStatusUpdateApiCallId?: string;
  deleteAppointmentApiCallId?: string;
  removeMemberChatApiCallId: string = "";
  fetchChatsListApiCallId: string = "";
  fetchRegularContactsApiCallId: string = "";
  fetchPhonebookContactsApiCallId: string = "";
  searchContactApiCallId: string = "";
  createAppointmentChatApiCallId: string = "";
  deleteAppointmentChatApiCallId: string = "";
  editAppointmentChatApiCallId: string = "";
  addMemberInAppointmentChatApiCallId: string = "";
  mediaQueryForSmallMediumScreens = window.matchMedia("(max-width: 960px)");
  bottomRef: React.RefObject<HTMLDivElement>;
  observer: IntersectionObserver | null = null
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: 0,
      userId: undefined,
      token: "",
      appointment: null,
      isRsvpStatusUpdateMode: false,
      invitedUserList: null,
      activeTab: null,
      optionAnchorEle: null,
      isDeleteModalOpen: false,
      meetingTitle: "",
      chatList: [],
      openChatList: [],
      isChatListOpen: false,
      chatSearchString: "",
      isChatLoading: false,
      matchMedia: this.mediaQueryForSmallMediumScreens.matches,
      openChatCreateForm: false,
      contactList: [],
      fullContactList: [],
      fullPhoneBookContactList: [],
      phoneBookContactList: [],
      chatAppointment: null,
      selectedApptGroupChatForEdit: null,
      editChatMode: false,
      isAddMemberToChatMode: false,
      selectedMembersForEditChat: [],
      selectedDeletedChatGuid: "",
      registeredAppointmentContactsPagination: {
        per_page: 50,
        page_no: 1,
        hasMore: true
      },
      isLoadingContacts: false,
      chatPage: 1,
      isAllChatsLoaded: false,
      isAddMemberModeFromEditScreen: false,
      selectedGroupChatForAddMemberEdit: null,
      confirmationRemoveMemberFromApptChatPopUpData: null,
      tempMemberIdToRemove: 0,
      // Customizable Area End
    };

    // Customizable Area Start
    this.handleMediaSizeChange = this.handleMediaSizeChange.bind(this);
    this.removeIdFromUrl = this.removeIdFromUrl.bind(this);
    this.bottomRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.setToken()
    const userData = await HelperFunctions.getUserData();
    if (userData) {
      this.setState({ userId: userData.id });
    }
    document.addEventListener("click", this.closeOptionMenu, true);
    this.removeIdFromUrl();
    document.addEventListener('beforeunload', this.removeIdFromUrl);
    this.mediaQueryForSmallMediumScreens.addEventListener('change', this.handleMediaSizeChange);
    this.observer = new IntersectionObserver(
      this.handleObserver,
      { root: null, threshold: 1.0 }
    );

    if (this.bottomRef.current) {
      this.observer.observe(this.bottomRef.current);
    }
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      switch (webApiRequestCallId) {
        case this.getAppointmentApiCallId:
          this.handleGetAppointmentCall(message);
          break;

        case this.rsvpStatusUpdateApiCallId:
          this.handleRsvpStatusUpdate(message);
          break;

        case this.deleteAppointmentApiCallId:
          this.handleDeleteAppointment(message);
          break;

        case this.fetchChatsListApiCallId:
          this.handleGetAppointmentChatsListCall(message);
          break;

        case this.searchContactApiCallId:
          this.searchContactApiCallHandler(message)
          break;

        case this.createAppointmentChatApiCallId:
          this.onCreateChatApiHandler(message);
          break;

        case this.editAppointmentChatApiCallId:
          this.handleEditGroupChatApiCall(message);
          break;

        case this.addMemberInAppointmentChatApiCallId:
          this.handleAddMemberApiCall(message);
          break;

        case this.removeMemberChatApiCallId:
          this.handleRemoveMemberApiCall(message);
          break;

        case this.deleteAppointmentChatApiCallId:
          this.handleOnDeleteGroupChat(message);
          break;
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  async componentWillUnmount(): Promise<void> {
    document.removeEventListener("click", this.closeOptionMenu, true);
    document.removeEventListener('beforeunload', this.removeIdFromUrl);
    this.mediaQueryForSmallMediumScreens.removeEventListener('change', this.handleMediaSizeChange);
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    // Re-observe the bottom element if chatList is updated
    if (!prevState.isChatListOpen && this.state.isChatListOpen) {
      this.initObserver();
    }
    if (prevState.chatList !== this.state.chatList && this.state.isChatListOpen && this.bottomRef.current && this.observer) {
      this.observer.observe(this.bottomRef.current);
    }
    if (prevState.isChatListOpen && !this.state.isChatListOpen && this.observer) {
      this.observer.disconnect();
    }
  }

  removeIdFromUrl = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete('chat_id');
    window.history.pushState(null, '', url.toString());
  };

  initObserver = () => {
    this.observer = new IntersectionObserver(this.handleObserver, {
      threshold: 0.5, // Trigger when fully in view
    });

    if (this.bottomRef.current) {
      this.observer.observe(this.bottomRef.current);
    }
  };

  handleObserver = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    if (entry.isIntersecting && !this.state.isAllChatsLoaded) {
      this.loadMoreItems();
    }
  };

  loadMoreItems = () => {
    const { isChatLoading } = this.state;
    if (isChatLoading) return;
    this.setState({ isChatLoading: true, chatPage: this.state.chatPage + 1 }, () => {
      this.getAppointmentChats();
    });
  };

  handleMediaSizeChange = (e: { matches: boolean }) => {
    this.setState({ matchMedia: e.matches }, () => {
      if(this.state.matchMedia && this.state.openChatList.length >= 2) {
        this.setState({ openChatList: this.state.openChatList.splice(1)})
      }
    });
  }

  onChangeSearchHandler = (value: string) => {
    this.setState({
      chatSearchString: value,
      chatPage: 1,
      isAllChatsLoaded: false,
    }, () => {
      this.getAppointmentChats()
    })
  }

  toggleChatList = () => {
    this.setState({ isChatListOpen: !this.state.isChatListOpen })
  }

  openChatCreate = () => {
    this.setState({ openChatCreateForm: true })
    // this.fetchPhoneBookContacts();
  }

  closeChatCreate = () => {
    this.setState({ 
      openChatCreateForm: false, 
      selectedApptGroupChatForEdit: null,
      editChatMode: false,
      isAddMemberToChatMode: false,
      selectedMembersForEditChat: [],
      contactList: this.state.fullContactList,
      phoneBookContactList: this.state.fullPhoneBookContactList,
      registeredAppointmentContactsPagination: {
        per_page: 50,
        page_no: 1,
        hasMore: true
      }
    })
  }

  setOpenChat = (chatDetail: IGroupChat) => {
    const { openChatList } = this.state;
    let updatedOpenChatList = [ ...openChatList];
    const maxOpenChatLists = this.state.matchMedia ? 1 : 2;
    const findIndex = updatedOpenChatList.findIndex((item) => item.guid[0] === chatDetail.guid[0]);
    if(findIndex === -1) {
      if (openChatList.length >= maxOpenChatLists) {
        updatedOpenChatList.pop();
      } 
      updatedOpenChatList = [{ ...chatDetail}, ...updatedOpenChatList];
    }
    this.setState({ openChatList: _.uniqBy(updatedOpenChatList, "guid") })
  }

  setOpenSelectedChat = (chatGuid: string) => {
    const { openChatList } = this.state;
    let updatedOpenChatList = openChatList.map((item) => {
      if(item.guid[0] === chatGuid) {
        item.isOpen = true
      } else {
        item.isOpen = false
      }
      return item;
    })
    this.props.navigation.history.push(`${this.props.navigation.history.location.pathname}?chat_id=${chatGuid}`)
    this.setState({ openChatList: updatedOpenChatList })
  }


  onSearchContact = async (contact: string) => {
    this.setState({ contactList: [], phoneBookContactList: [], isLoadingContacts: true })
    if (contact) {
      const searchedAppointmentRegisteredContacts = this.state.fullContactList.filter((contactItem) => {
        return ( 
          contactItem.name?.toLowerCase()?.includes(contact.toLowerCase()) || 
          contactItem.full_phone_number?.includes(contact)
        );
      })
      const searchedAppointmentPhonebookContacts = this.state.fullPhoneBookContactList.filter((phonebookContact) => {
        return ( 
          phonebookContact.name?.toLowerCase()?.includes(contact.toLowerCase()) || 
          phonebookContact.full_phone_number?.includes(contact)
        );
      })
      this.setState({ contactList: searchedAppointmentRegisteredContacts, phoneBookContactList: searchedAppointmentPhonebookContacts }, () => {
        this.setState({ isLoadingContacts: false })
      })
    } else {
      this.setState({
        registeredAppointmentContactsPagination: {
          per_page: 50,
          page_no: 1,
          hasMore: true
        },
        contactList: this.state.fullContactList,
        phoneBookContactList: this.state.fullPhoneBookContactList
      }, () => {
        this.setState({ isLoadingContacts: false })
      })
      // this.fetchPhoneBookContacts()
    }
  }

  setCloseSelectedChat = (chatGuid?: string) => {
    const { openChatList } = this.state;
    let updatedOpenChatList: IGroupChat[] = [];
    if(chatGuid) {
      updatedOpenChatList = openChatList.filter((item) => item.guid[0] !== chatGuid)
    } else {
      updatedOpenChatList = openChatList.filter((item) => !item.isOpen)
    }
    this.setState({ openChatList: updatedOpenChatList })
  }

  searchContactApiCallHandler = (message: Message) => {
    const responseJson = searchContactApiCallHandler(message);
    this.setState({
      contactList: responseJson.data,
      phoneBookContactList: responseJson.phoneBookContactsList,
      isLoadingContacts: false,
    })
  }

  onClickRemoveMemberFromInfoScreen = (memberId: number, memberName: string, guid: string) => {
    this.setState({ confirmationRemoveMemberFromApptChatPopUpData: { guid: guid, header: "Remove Member", message: `Are you sure you want to remove ${memberName}?`, type: "chat" }, tempMemberIdToRemove: memberId })
  }

  onCloseRemoveMemberConfirmationModal = () => {
    this.setState({ confirmationRemoveMemberFromApptChatPopUpData: null, tempMemberIdToRemove: 0 })
  }

  onConfirmRemoveMemberFromChat = () => {
    this.removeExistingMemberFromChat(this.state.tempMemberIdToRemove.toString())
    this.onCloseRemoveMemberConfirmationModal();
  }

  removeExistingMemberFromChat = async (memberId: string) => {
    const { selectedApptGroupChatForEdit, confirmationRemoveMemberFromApptChatPopUpData } = this.state;
    this.removeMemberChatApiCallId = await removeMembersInGroupChat({
      token: this.state.token,
      body: {
        guid: confirmationRemoveMemberFromApptChatPopUpData?.guid ? confirmationRemoveMemberFromApptChatPopUpData.guid : selectedApptGroupChatForEdit?.group.data.guid,
        uid: memberId
      }
    })
  }

  handleRemoveMemberApiCall = async (message: Message) => {
    const removeMemberResponse = handleRemoveMembersFromGroupChat(message);
    if (removeMemberResponse) {
      HelperFunctions.showSuccessToast(removeMemberResponse.message)
      this.state.updateChildChatComponent?.()
    } else {
      HelperFunctions.showErrorToast("Something went wrong! Please try again")
    }
  }

  onCreateAppointmentChat = async (body: any) => {
    const { selectedApptGroupChatForEdit, editChatMode, isAddMemberToChatMode} = this.state;
    if (!editChatMode && !isAddMemberToChatMode) {
    this.closeChatCreate();
    HelperFunctions.showLoader();

      this.createAppointmentChatApiCallId = await createChat({
        token: this.state.token,
        body
      })
    } else if (selectedApptGroupChatForEdit) {
      if ((selectedApptGroupChatForEdit.group.data.name !== body.name) && editChatMode) {
        this.editAppointmentChatApiCallId = await editGroupChat({
          token: this.state.token,
          body: {
            guid: selectedApptGroupChatForEdit.group.data.guid,
            name: body.name,
            type: "public"
          }
        })
      }

      if (isAddMemberToChatMode) {
        this.addMemberInAppointmentChatApiCallId = await addMembersInGroupChat({
          token: this.state.token,
          body: {
            guid: selectedApptGroupChatForEdit.group.data.guid,
            members: body.members.participants
          }
        })
      }
      this.closeChatCreate();
    }
  }

  onCreateChatApiHandler = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.comet_response?.data) {
      const addedChatGrp: IGroupChat = {
        ...responseJson.comet_response.data,
        guid: [responseJson.comet_response.data?.guid],
        appointment: this.state.appointment,
      }
      HelperFunctions.showSuccessToast(responseJson.message)
      this.setState({ chatList: [{...addedChatGrp}, ...this.state.chatList]})
    } else if (responseJson && responseJson.errors) {
      HelperFunctions.showErrorToast(responseJson.errors)
    } else {
      HelperFunctions.showErrorToast("Something went wrong please try again!")
    }
    HelperFunctions.hideLoader();
  }

  handleGetAppointmentChatsListCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.meta?.next_page) {
      this.setState({ isAllChatsLoaded: true });
    }
    if (responseJson?.chats) {
      const filteredData = responseJson.chats?.filter((item: any) => item.name)
      if (this.state.chatPage === 1) {
        this.setState({
          chatList: _.uniqBy([...filteredData], "guid")
        })
      } else {
        this.setState({
          chatList: _.uniqBy([...this.state.chatList, ...filteredData], "guid")
        })
      }
    }
    this.setState({ isChatLoading: false })
  }

  getAppointmentChats = async () => {
    this.setState({ isChatLoading: true })
    this.fetchChatsListApiCallId = await apiCallFunction({
      method: "GET",
      endPoint: `${configJSON.chatsListingEndpoint}?appointment_id=${this.state.id}&search=${this.state.chatSearchString}&per_page=10&page=${this.state.chatPage}`,
      contentType: configJSON.appointmentApiContentType,
      token: this.state.token
    })
  }

  onClickAddMemberButtomFromEditScreen = (chatData: IChatData | undefined | null) => {
    this.setState({ isAddMemberModeFromEditScreen: true, isAddMemberToChatMode: true, editChatMode: false })
    if (chatData) {
      this.setState({ selectedGroupChatForAddMemberEdit: chatData })
    }
  }

  onClickAddMemberButton = (event: any) => {
    if (event && event.chatData) {
      this.setState({
        selectedApptGroupChatForEdit: event.chatData,
      })
      if (event.groupMembers) {
        const memberList: ContactList[] = [];
        event.groupMembers.forEach((groupMember: CometChat.GroupMember) => {
          if (groupMember.getScope() !== "admin") {
            const contact: ContactList = {
              id: parseInt(groupMember.getUid()),
              name: groupMember.getName(),
              email: "",
              full_phone_number: ""
            }
            memberList.push(contact);
          }
        })

        this.setState({
          selectedMembersForEditChat: memberList,
          isAddMemberToChatMode: true,
          openChatCreateForm: true,
        })
      }
      // this.fetchPhoneBookContacts();
    }
  }

  onClickEditGroupChat = (event: any) => {
    if (event && event.chatData) {
      this.setState({
        selectedApptGroupChatForEdit: event.chatData,
        editChatMode: true,
      })
      if (event.groupMembers) {
        const contactList: ContactList[] = [];
        event.groupMembers.forEach((member: CometChat.GroupMember) => {
          if (member.getScope() !== "admin") {
            const contact: ContactList = {
              id: parseInt(member.getUid()),
              name: member.getName(),
              email: "",
              full_phone_number: ""
            }
            contactList.push(contact);
          }
        })

        this.setState({
          selectedMembersForEditChat: contactList,
          openChatCreateForm: true,
        })
      }
      // this.fetchPhoneBookContacts();
    }
  }

  handleAddMemberApiCall = async(message: Message) => {
    const addMemberResponse = handleAddMembersInGroupChat(message);
    if (addMemberResponse) {
      HelperFunctions.showSuccessToast(addMemberResponse.message)
      this.state.updateChildChatComponent?.()
    } else {
      HelperFunctions.showErrorToast("Something went wrong! Please try again")
    }
    if (this.state.isAddMemberModeFromEditScreen && this.state.selectedGroupChatForAddMemberEdit) {
      const groupInfo = await getGroupChatInfo(this.state.selectedGroupChatForAddMemberEdit.group.data.guid);
      this.onClickEditGroupChat({ chatData: this.state.selectedGroupChatForAddMemberEdit, groupMembers: groupInfo?.allMembers });
      this.setState({ isAddMemberModeFromEditScreen: false })
    }
    HelperFunctions.hideLoader();
  }

  handleEditGroupChatApiCall = (message: Message) => {
    const response = handleEditGroupChat(message);
    if (response) {
      HelperFunctions.showSuccessToast(response.message);
      const updatedChatList = this.state.chatList.map((item) => {
        if(item.guid[0] === response?.comet_response?.data?.guid){
          item.name = response?.comet_response?.data?.name;
        }
        return item;
      })
      this.setState({ chatList: updatedChatList })
      this.state.updateChildChatComponent?.()
    } else {
      HelperFunctions.showErrorToast("Something went wrong! Please try again")
    }
    this.closeChatCreate();
    HelperFunctions.hideLoader();
  }

  onClickDeleteGroupChat = async (guid: string) => {
    HelperFunctions.showLoader();
    this.setState({ selectedDeletedChatGuid: guid }, async() => {
      this.deleteAppointmentChatApiCallId = await deleteGroupChat({
        token: this.state.token,
        body: {
          guid: [guid]
        }
      })
    })
  }

  handleOnDeleteGroupChat = (message: Message) => {
    const response = handleDeleteGroupChat(message);
    if (response && response.message) {
      HelperFunctions.showSuccessToast(response.message)
      const chatList = this.state.chatList.filter((item) => item.guid[0] !== this.state.selectedDeletedChatGuid);
      const openChatList = this.state.openChatList.filter((item) => item.guid[0] !== this.state.selectedDeletedChatGuid);
      this.setState({ chatList, openChatList })
    } else {
      HelperFunctions.showErrorToast("Something went wrong! please try again")
    }
    HelperFunctions.hideLoader();
  }

  registerUpdateChatCallBack = (callback: any)=>{
    this.setState({
        updateChildChatComponent: callback
    })
}

  handleRsvpStatusUpdate = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && !responseJson.errors) {
      this.setState({
        isRsvpStatusUpdateMode: false
      }, () => {
        this.getAppointment(this.state.id)
      })
    } else {
      const toastMsg = responseJson.errors && responseJson.errors[0] && responseJson.errors[0].message ? responseJson.errors[0].message : "Something went wrong please try again!";
      HelperFunctions.showErrorToast(toastMsg);
    }
    HelperFunctions.hideLoader();
  }

  handleGetAppointmentCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && !responseJson.errors && responseJson.data) {
      const appointment: IAppointmentData = responseJson.data;
      const chatAppointment: AppointmentAttributes = { ...appointment.attributes };
      const registeredContacts = appointment.attributes.user_contacts.map((registeredContact) => {
        return {
          id: registeredContact.id,
          name: registeredContact.name,
          email: "",
          full_phone_number: registeredContact.full_phone_number,
        }
      });
      const phonebookContacts = appointment.attributes.invitation_members?.filter(item => !HelperFunctions.isValidEmail(item?.invite_record)).map((registeredContact) => {
        return {
          id: parseInt(registeredContact.id),
          name: registeredContact.contact_name || "",
          email: "",
          full_phone_number: registeredContact.invite_record,
          isNonRegistered: true,
       }
      })
      if (appointment.attributes) {
        this.setState({
          appointment,
          chatAppointment,
          contactList: registeredContacts,
          phoneBookContactList: phonebookContacts || [],
          fullContactList: registeredContacts,
          fullPhoneBookContactList: phonebookContacts || [],
        })
      }
    } else {
      this.redirectTo('Appointments')
    }
    HelperFunctions.hideLoader();
  }

  handleDeleteAppointment = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && !responseJson.errors) {
      this.setState({
        isDeleteModalOpen: false
      }, () => {
        const toastMsg = "Appointment deleted successfully";
        HelperFunctions.showSuccessToast(toastMsg);
        this.redirectTo("Appointments")
      })
    } else {
      const toastMsg = responseJson.errors && responseJson.errors[0] && responseJson.errors[0].message ? responseJson.errors[0].message : "Something went wrong please try again!";
      HelperFunctions.showErrorToast(toastMsg);
    }
    HelperFunctions.hideLoader();
  }

  setToken = async () => {
    const token = await HelperFunctions.getStorageData("authToken");
    if (token) {
      this.setState({ token: token }, () => {
        const appointmentId = this.props.navigation.getParam("id");
        if (appointmentId) {
          this.setState({
            id: appointmentId
          }, () => {
            this.getAppointment(appointmentId);
            this.getAppointmentChats();
            // this.fetchContacts();
          })
        }
      });
    } else {
      this.redirectTo("Appointments");
    }
  }

  setInvitedUsersForAppointmentDetails = (users:UserContact[], owner_id:number, invitation_members?:{[key: string]: string}[])=>{
    this.setState({
      invitedUserList: {
        owner_id,
        invited_users: rearrangeContacts(owner_id, users),
        invitation_members: invitation_members ? invitation_members : []
      },
      activeTab:null
    })
  }

  onUpdateRsvpStatus = async (status:string,appointmentId:string | number)=>{
    HelperFunctions.showLoader();
    const body = {
      status
    }
    this.rsvpStatusUpdateApiCallId = await apiCallFunction({
      method:"PATCH",
      endPoint:`bx_block_appointment_management/appointments/${appointmentId}/add_appointment_status`,
      contentType: configJSON.appointmentApiContentType,
      token: this.state.token,
      body
    })
  }

  onRsvpUpdateModeChange = ()=>{
    this.setState(prevState=>{
      return {
        isRsvpStatusUpdateMode: !prevState.isRsvpStatusUpdateMode
      }
    })
  }

  getAppointment = async (appointmentId:string | number) => {
    HelperFunctions.showLoader();
    this.getAppointmentApiCallId = await apiCallFunction({
      method: "GET",
      endPoint: `bx_block_appointment_management/appointments/${appointmentId}`,
      contentType: configJSON.appointmentApiContentType,
      token: this.state.token
    })
  }

  redirectTo = (endpoint: string, params?:{[key:string]:string | number}) => {
    this.props.navigation.navigate(endpoint, params)
  }

  closeUserListModal = ()=>{
    this.setState({
      invitedUserList: null,
      activeTab:null
    })
  }

  setActiveTab = (currentTab:string | null)=>{
    this.setState({
      activeTab: currentTab
    })
  }

  openOptionMenu = (event:React.MouseEvent<HTMLDivElement>)=>{
    this.setState({
      optionAnchorEle: event.currentTarget
    })
  }

  closeOptionMenu = (event:MouseEvent)=>{
    const menu = document.getElementById("options-menu");
    if (menu && !menu.contains(event.target as Node)) {
      this.setState({
        optionAnchorEle: null
      })
    }
  }

  openDeleteModal = (meetingTitle:string)=>{
    this.setState({
      isDeleteModalOpen: true,
      optionAnchorEle: null,
      meetingTitle
    })
  }

  closeDeleteModal = ()=>{
    this.setState({
      isDeleteModalOpen: false,
      meetingTitle: ""
    })
  }

  onDeleteAppointment = async ()=>{
    HelperFunctions.showLoader();
    this.deleteAppointmentApiCallId = await apiCallFunction({
      method:"DELETE",
      endPoint:`bx_block_appointment_management/appointments/${this.state.appointment?.id}`,
      contentType: configJSON.appointmentApiContentType,
      token: this.state.token
    })
  }

  goBack = ()=>{
    this.props.navigation.navigate("Appointments");
  }
  // Customizable Area End
}