import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Platform } from "react-native";
import { AllEvents } from "./AllEventController";

// Customizable Area Start
import { EventData } from "./types";
import { ContactList, EventsAttributes, IAddPost, IChatData, IComment, IGroupChat, IMentionInputType, IPhonebookContact, IPost, UserContact } from "../../../components/src/CommonTypes";
const { HelperFunctions } = require("../../../components/src/HelperFunctions");
const { ApiCallFunction: apiCallFunction } = require("../../appointmentmanagement/src/ApiCallFunction");
import { getEventDetail, handleEventDetailCall } from "./actions";
import _ from "lodash";
import {
  createChat,
  handleEditGroupChat,
  handleAddMembersInGroupChat,
  deleteGroupChat,
  handleDeleteGroupChat,
  addMembersInGroupChat,
  editGroupChat,
  handleRemoveMembersFromGroupChat,
  removeMembersInGroupChat,
  getGroupChatInfo,
} from "../../chat/src/actions";
import { CometChat } from "@cometchat-pro/chat";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  eventDetail: EventData | null;
  openDeleteConfirmPopup: boolean;
  userId: number | undefined;
  isEventRsvpStatusUpdateMode: boolean;
  eventDeleteConfirmationPopup: {
    header: string;
    message: string;
    type: string;
    eventId: string;
  } | null;
  recentPostDetails: IPost | null;
  postCommentModal: {
    id: string;
  } | null;
  commentsPageNum: number; 
  commentsList: IComment[];
  isLoadingCommentsList: boolean;
  selectedCommentId: string | null;
  isAllCommentsFetched: boolean;
  postDetails: IAddPost | null;
  recentPostUploadedBlobIds: number[];
  isPostUploading: boolean;
  numberOfPostFilesUploaded: number;
  postLikedUserList: { id: string | number, attributes: { like_by_name: string} }[] | undefined;
  searchedPostCommentMentionsList: IMentionInputType[];
  eventChatList: IGroupChat[];
  mediumToSmallMatchMedia: boolean;
  isChatListOpen: boolean;
  openEventChatCreateForm: boolean;
  contactList: ContactList[];
  phoneBookContactList: ContactList[];
  allContactsList: ContactList[];
  allPhonebookContactsList: ContactList[];
  openEventChatRooms: IGroupChat[];
  eventChatSearchString: string;
  isChatLoading: boolean;
  eventChat: EventsAttributes | null;
  selectedGroupChatForEdit: IChatData | null;
  editChatMode: boolean;
  isAddMemberToChatMode: boolean;
  selectedMembersForEditEventChat: ContactList[];
  selectedDeletedEventChatGuid: string;
  updateChildEventChatComponent?: any;
  registeredContactsPagination: {
    per_page: number,
    page_no: number,
    hasMore: boolean
  }
  callback?: any;
  isLoadingContacts: boolean;
  eventChatPage: number;
  isAllEventChatsLoaded: boolean;
  isAddMemberModeFromEventChatEditScreen: boolean;
  selectedEventChatForAddMemberEdit: IChatData | null;
  confirmationRemoveMemberFromEventChatPopUpData: {
      header: string;
      message: string;
      type: string;
      guid: string;
  } | null;
  tempEventChatMemberIdToRemove: number;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AllEventDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetEventDetails: string = "";
  apiUpdateRsvpStatusOfEvent: string = "";
  deleteEventApiCallId: string = "";
  recentPostApiCallId: string = "";
  likeRecentPostApiCallId: string = "";
  dislikeRecentPostApiCallId: string = "";
  getCommentsListApiCallId: string = "";
  addCommentInRecentPostApiCallId: string = "";
  deleteCommentFromRecentPostApiCallId: string = "";
  uploadPostFileApiCallId: string[] = [];
  addPostApiCallId: string = "";
  postLikedUsersListApiCallId: string = "";
  searchPostCommentMentionsApiCallId: string = "";
  fetchEventChatsListApiCallId: string = "";
  createEventChatApiCallId: string = "";
  deleteEventChatApiCallId: string = "";
  editEventChatApiCallId: string = "";
  addMemberInEventChatApiCallId: string = "";
  removeMemberInEventChatApiCallId: string = "";
  mediaQueryMediumToSmall = window.matchMedia("(max-width: 960px)");
  bottomChatRef: React.RefObject<HTMLDivElement>;
  chatObserver: IntersectionObserver | null = null
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      eventDetail: null,
      openDeleteConfirmPopup: false,
      isEventRsvpStatusUpdateMode: false,
      userId: undefined,
      eventDeleteConfirmationPopup: null,
      recentPostDetails: null,
      commentsList: [],
      commentsPageNum: 1,
      isLoadingCommentsList: false,
      postCommentModal: null,
      selectedCommentId: null,
      isAllCommentsFetched: false,
      postDetails: null,
      isPostUploading: false,
      recentPostUploadedBlobIds: [],
      numberOfPostFilesUploaded: 0,
      postLikedUserList: undefined,
      searchedPostCommentMentionsList: [],
      eventChatList:[],
      isChatListOpen: false,
      mediumToSmallMatchMedia: this.mediaQueryMediumToSmall.matches,
      openEventChatRooms: [],
      eventChatSearchString: "",
      isChatLoading: false,
      contactList: [],
      openEventChatCreateForm: false,
      phoneBookContactList: [],
      allContactsList: [],
      allPhonebookContactsList: [],
      eventChat: null,
      selectedGroupChatForEdit: null,
      editChatMode: false,
      isAddMemberToChatMode: false,
      selectedMembersForEditEventChat: [],
      selectedDeletedEventChatGuid: "",
      registeredContactsPagination: {
        per_page: 50,
        page_no: 1,
        hasMore: true
      },
      isLoadingContacts: false,
      eventChatPage: 1,
      isAllEventChatsLoaded: false,
      isAddMemberModeFromEventChatEditScreen: false,
      selectedEventChatForAddMemberEdit: null,
      confirmationRemoveMemberFromEventChatPopUpData: null,
      tempEventChatMemberIdToRemove: 0,
      // Customizable Area End
    };
    // Customizable Area End
    this.bottomChatRef = React.createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getToken();
    if (Platform.OS !== "web") {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.setToken();
    this.removeIdFromUrl();
    this.chatObserver = new IntersectionObserver(
      this.handleObserver,
      { root: null, threshold: 1.0 }
    );
    document.addEventListener('beforeunload', this.removeIdFromUrl);
    this.mediaQueryMediumToSmall.addEventListener('change', this.handleMediaSizeChange);
    if (this.bottomChatRef.current) {
      this.chatObserver.observe(this.bottomChatRef.current);
    }
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id){
      switch(apiCallId){
        case this.apiGetEventDetails:
          this.handleEventDetailCall(message)
          break;
        case this.apiUpdateRsvpStatusOfEvent:
          this.handleEventDetailRsvpStatusUpdate(message);
          break;
        case this.deleteEventApiCallId:
          this.handleDeleteEventCall(message);
          break;
        case this.recentPostApiCallId:
          this.handleRecentPostCall(message);
          break;
        case this.likeRecentPostApiCallId:
          this.handleLikePostApiCall(message);
          break;
        case this.dislikeRecentPostApiCallId:
          this.handleDisLikePostApiCall(message);
          break;
        case this.getCommentsListApiCallId:
          this.handleGetCommentsListApiCall(message);
          break;
        case this.addCommentInRecentPostApiCallId:
          this.handleAddPostCommentApiCall(message);
          break;
        case this.deleteCommentFromRecentPostApiCallId:
          this.handleDeletePostCommentApiCall(message);
          break;
        case this.addPostApiCallId:
          this.handleCreateRecentPostCall(message);
          break;
        case this.postLikedUsersListApiCallId:
          this.handleGetPostLikedUsersListCall(message);
          break;
        case this.searchPostCommentMentionsApiCallId:
          this.handlePostComentGetMentionsListCall(message);
          break;
        case this.fetchEventChatsListApiCallId:
          this.handleGetEventChatsListCall(message);
          break;
        case this.createEventChatApiCallId:
          this.onCreateEventChatApiHandler(message);
          break;
        case this.editEventChatApiCallId:
          this.handleEditGroupEventChatApiCall(message);
          break;
        case this.addMemberInEventChatApiCallId:
          this.handleAddMemberApiCall(message);
          break;
        case this.removeMemberInEventChatApiCallId:
          this.handleRemoveEventChatMemberApiCall(message);
          break;
        case this.deleteEventChatApiCallId:
          this.handleOnDeleteGroupEventChat(message);
          break;
      }
      if(this.uploadPostFileApiCallId.includes(apiCallId)){
        this.handlePostUploadFileApiCall(message);
      }
     
    }
    // Customizable Area End
  }

  navigateToEventDetail = (selectedUpcomingEvent: AllEvents) => {
    this.props.navigation.navigate("AddEventDetail", {
      event: selectedUpcomingEvent,
    });
  };

  // Customizable Area Start
  async componentWillUnmount() {
    document.removeEventListener('beforeunload', this.removeIdFromUrl);
    this.mediaQueryMediumToSmall.removeEventListener('change', this.handleMediaSizeChange);
    if (this.chatObserver) {
      this.chatObserver.disconnect();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    // Re-observe the bottom element if chatList is updated
    if (!prevState.isChatListOpen && this.state.isChatListOpen) {
      this.initObserver();
    }
    if (prevState.eventChatList !== this.state.eventChatList && this.state.isChatListOpen && this.bottomChatRef.current && this.chatObserver) {
      this.chatObserver.observe(this.bottomChatRef.current);
    }
    if (prevState.isChatListOpen && !this.state.isChatListOpen && this.chatObserver) {
      this.chatObserver.disconnect();
    }
  }

  initObserver = () => {
    this.chatObserver = new IntersectionObserver(this.handleObserver, {
      threshold: 0.5, // Trigger when fully in view
    });

    if (this.bottomChatRef.current) {
      this.chatObserver.observe(this.bottomChatRef.current);
    }
  };

  handleObserver = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    if (entry.isIntersecting && !this.state.isAllEventChatsLoaded) {
      this.loadMoreItems();
    }
  };

  loadMoreItems = () => {
    const { isChatLoading } = this.state;
    if (isChatLoading) return;
    this.setState({ isChatLoading: true, eventChatPage: this.state.eventChatPage + 1 }, () => {
      this.getEventChats();
    });
  };

  removeIdFromUrl = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete('chat_id');
    window.history.pushState(null, '', url.toString());
  };


  handleMediaSizeChange = (e: { matches: boolean }) => {
    this.setState({ mediumToSmallMatchMedia: e.matches }, () => {
      if(this.state.mediumToSmallMatchMedia && this.state.openEventChatRooms.length >= 2) {
        this.setState({ openEventChatRooms: this.state.openEventChatRooms.splice(1)})
      }
    });
  }

  onChangeSearchHandler = (value: string) => {
    this.setState({
      eventChatSearchString: value,
      eventChatPage: 1,
      isAllEventChatsLoaded: false,
    }, () => {
      this.getEventChats()
    })
  }

  toggleChatList = () => {
    this.setState({ isChatListOpen: !this.state.isChatListOpen })
  }

  openEventChatCreate = () => {
    this.setState({ openEventChatCreateForm: true })
  }

  closeChatCreate = () => {
    this.setState({ 
      openEventChatCreateForm: false, 
      selectedGroupChatForEdit: null,
      editChatMode: false,
      isAddMemberToChatMode: false,
      selectedMembersForEditEventChat: [],
      contactList: [ ...this.state.allContactsList],
      phoneBookContactList: [ ...this.state.allPhonebookContactsList],
      registeredContactsPagination: {
        per_page: 50,
        page_no: 1,
        hasMore: true
      } 
    })
  }

  setOpenChat = (chatDetail: IGroupChat) => {
    const { openEventChatRooms } = this.state;
    let updatedOpenChatList = [ ...openEventChatRooms];
    const maxOpenChatLists = this.state.mediumToSmallMatchMedia ? 1 : 2;
    const findIndex = updatedOpenChatList.findIndex((item) => item.guid === chatDetail.guid);
    if(findIndex === -1) {
      if (openEventChatRooms.length >= maxOpenChatLists) {
        updatedOpenChatList.pop();
      } 
      updatedOpenChatList = [{ ...chatDetail}, ...updatedOpenChatList];
    }
    this.setState({ openEventChatRooms: _.uniqBy(updatedOpenChatList, "guid") })
  }

  setOpenSelectedChat = (chatGuid: string) => {
    const { openEventChatRooms } = this.state;
    let updatedOpenChatList = openEventChatRooms.map((item) => {
      if(item.guid[0] === chatGuid) {
        item.isOpen = true
      } else {
        item.isOpen = false
      }
      return item;
    })
    this.props.navigation.history.push(`${this.props.navigation.history.location.pathname}?chat_id=${chatGuid}`)
    this.setState({ openEventChatRooms: updatedOpenChatList })
  }

  setCloseSelectedChat = (chatGuid?: string) => {
    const { openEventChatRooms } = this.state;
    let updatedOpenChatList: IGroupChat[] = [];
    if(chatGuid) {
      updatedOpenChatList = openEventChatRooms.filter((item) => item.guid[0] !== chatGuid)
    } else {
      updatedOpenChatList = openEventChatRooms.filter((item) => !item.isOpen)
    }
    this.setState({ openEventChatRooms: updatedOpenChatList })
  }

  onSearchContact = async (contact: string) => {
    this.setState({ contactList: [], phoneBookContactList: [], isLoadingContacts: true })
    if (contact) {
      const searchedRegisteredContacts = this.state.allContactsList.filter((contactEventItem) => {
        return (
          contactEventItem.name?.toLowerCase()?.includes(contact.toLowerCase()) ||
          contactEventItem.full_phone_number?.includes(contact)
        );
      })
      const searchedPhonebookContacts = this.state.allPhonebookContactsList.filter((phonebookEventContact) => {
        return (
          phonebookEventContact.name?.toLowerCase()?.includes(contact.toLowerCase()) ||
          phonebookEventContact.full_phone_number?.includes(contact)
        );
      })
      this.setState({ contactList: searchedRegisteredContacts, phoneBookContactList: searchedPhonebookContacts }, () => {
        this.setState({ isLoadingContacts: false })
      })
    } else {
      this.setState({
        registeredContactsPagination: {
          per_page: 50,
          page_no: 1,
          hasMore: true
        },
        contactList: [...this.state.allContactsList],
        phoneBookContactList: [...this.state.allPhonebookContactsList]
      }, () => {
        this.setState({ isLoadingContacts: false })
      })
    }
  }

  onClickRemoveMemberFromEventChatInfoScreen = (memberId: number, memberName: string, guid: string) => {
    this.setState({ confirmationRemoveMemberFromEventChatPopUpData: { guid: guid, header: "Remove Member", message: `Are you sure you want to remove ${memberName}?`, type: "chat" }, tempEventChatMemberIdToRemove: memberId })
  }

  onCloseRemoveMemberConfirmationModal = () => {
    this.setState({ confirmationRemoveMemberFromEventChatPopUpData: null, tempEventChatMemberIdToRemove: 0 })
  }

  onConfirmRemoveMemberFromEventChat = () => {
    this.removeExistingMemberFromChat(this.state.tempEventChatMemberIdToRemove.toString())
    this.onCloseRemoveMemberConfirmationModal();
  }

  removeExistingMemberFromChat = async (memberId: string) => {
    const { selectedGroupChatForEdit, confirmationRemoveMemberFromEventChatPopUpData } = this.state;
    this.removeMemberInEventChatApiCallId = await removeMembersInGroupChat({
      token: this.state.token,
      body: {
        guid: confirmationRemoveMemberFromEventChatPopUpData?.guid ? confirmationRemoveMemberFromEventChatPopUpData.guid : selectedGroupChatForEdit?.group.data.guid,
        uid: memberId
      }
    })
  }

  handleRemoveEventChatMemberApiCall = async (message: Message) => {
    const removeMemberResponse = handleRemoveMembersFromGroupChat(message);
    if (removeMemberResponse) {
      HelperFunctions.showSuccessToast(removeMemberResponse.message)
      this.state.updateChildEventChatComponent?.()
    } else {
      HelperFunctions.showErrorToast("Something went wrong! Please try again")
    }
  }


  onCreateEventChat = async (body: any) => {
    const { selectedGroupChatForEdit, editChatMode, isAddMemberToChatMode } = this.state;
    if (!editChatMode && !isAddMemberToChatMode) {
      HelperFunctions.showLoader();
      this.closeChatCreate();
      this.createEventChatApiCallId = await createChat({
        token: this.state.token,
        body
      })
    } else if (selectedGroupChatForEdit) {
      if (selectedGroupChatForEdit.group.data.name !== body.name && editChatMode) {
        this.editEventChatApiCallId = await editGroupChat({
          token: this.state.token,
          body: {
            guid: selectedGroupChatForEdit.group.data.guid,
            name: body.name,
            type: "public"
          }
        })
      }

      if (isAddMemberToChatMode) {
        this.addMemberInEventChatApiCallId = await addMembersInGroupChat({
          token: this.state.token,
          body: {
            guid: selectedGroupChatForEdit.group.data.guid,
            members: body.members.participants
          }
        })
      }
      this.closeChatCreate();
    }
  }

  onCreateEventChatApiHandler = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.comet_response?.data) {
      const addedChatGrp: IGroupChat = {
        ...responseJson.comet_response.data,
        guid: [responseJson.comet_response.data?.guid],
        event: this.state.eventDetail,
      }
      HelperFunctions.showSuccessToast(responseJson.message)
      this.setState({ eventChatList: [{...addedChatGrp}, ...this.state.eventChatList]})
    } else if (responseJson && responseJson.errors) {
      HelperFunctions.showErrorToast(responseJson.errors)
    } else {
      HelperFunctions.showErrorToast("Something went wrong please try again!")
    }
    HelperFunctions.hideLoader();
  }

  handleGetEventChatsListCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.meta?.next_page) {
      this.setState({ isAllEventChatsLoaded: false });
    }
    if (responseJson?.chats) {
      const filteredData = responseJson.chats?.filter((item: any) => item.name)
      if (this.state.eventChatPage === 1) {
        this.setState({
          eventChatList: _.uniqBy([...filteredData], "guid")
        })
      } else {
        this.setState({
          eventChatList: _.uniqBy([...this.state.eventChatList, ...filteredData], "guid")
        })
      }
    }
    this.setState({ isChatLoading: false })
  }

  getEventChats = async () => {
    this.setState({ isChatLoading: true })
    const eventId = this.props.navigation?.getParam("eventId");
    this.fetchEventChatsListApiCallId = await apiCallFunction({
      method: "GET",
      endPoint: `${configJSON.chatsListingEndpoint}?event_id=${eventId}&search=${this.state.eventChatSearchString}&per_page=10&page=${this.state.eventChatPage}`,
      contentType: configJSON.validationApiContentType,
      token: this.state.token
    })
  }

  onClickAddMemberButtomFromEditScreen = (chatData: IChatData | undefined | null) => {
    this.setState({ isAddMemberModeFromEventChatEditScreen: true, isAddMemberToChatMode: true, editChatMode: false })
    if (chatData) {
      this.setState({ selectedEventChatForAddMemberEdit: chatData })
    }
  }

  onClickAddMemberButton = (event: any) => {
    if (event && event.chatData) {
      this.setState({
        selectedGroupChatForEdit: event.chatData,
      })
      if (event.groupMembers) {
        const memberList: ContactList[] = [];
        event.groupMembers.forEach((groupMember: CometChat.GroupMember) => {
          if (groupMember.getScope() !== "admin") {
            const contact: ContactList = {
              id: parseInt(groupMember.getUid()),
              name: groupMember.getName(),
              email: "",
              full_phone_number: ""
            }
            memberList.push(contact);
          }
        })

        this.setState({
          selectedMembersForEditEventChat: memberList,
          isAddMemberToChatMode: true,
          openEventChatCreateForm: true,
        })
      }
    }
  }

  onClickEditGroupChat = (event: any) => {
    if (event && event.chatData) {
      this.setState({
        selectedGroupChatForEdit: event.chatData,
        editChatMode: true,
      })
      if (event.groupMembers) {
        const contactList: ContactList[] = [];
        event.groupMembers.forEach((member: CometChat.GroupMember) => {
          if (member.getScope() !== "admin") {
            const contact: ContactList = {
              id: parseInt(member.getUid()),
              name: member.getName(),
              email: "",
              full_phone_number: ""
            }
            contactList.push(contact);
          }
        })

        this.setState({
          selectedMembersForEditEventChat: contactList,
          openEventChatCreateForm: true,
        })
      }
    }
  }

  handleAddMemberApiCall = async (message: Message) => {
    const addMemberResponse = handleAddMembersInGroupChat(message);
    if (addMemberResponse) {
      HelperFunctions.showSuccessToast(addMemberResponse.message)
      this.state.updateChildEventChatComponent?.()
    } else {
      HelperFunctions.showErrorToast("Something went wrong! Please try again")
    }
    if (this.state.isAddMemberModeFromEventChatEditScreen && this.state.selectedEventChatForAddMemberEdit) {
      const groupInfo = await getGroupChatInfo(this.state.selectedEventChatForAddMemberEdit.group.data.guid);
      this.onClickEditGroupChat({ chatData: this.state.selectedEventChatForAddMemberEdit, groupMembers: groupInfo?.allMembers });
      this.setState({ isAddMemberModeFromEventChatEditScreen: false })
    }
    HelperFunctions.hideLoader();
  }

  handleEditGroupEventChatApiCall = (message: Message) => {
    const response = handleEditGroupChat(message);
    if (response) {
      HelperFunctions.showSuccessToast(response.message);
      const updatedChatList = this.state.eventChatList.map((item) => {
        if(item.guid[0] === response?.comet_response?.data?.guid){
          item.name = response?.comet_response?.data?.name;
        }
        return item;
      })
      this.setState({ eventChatList: updatedChatList })
      this.state.updateChildEventChatComponent?.()
    } else {
      HelperFunctions.showErrorToast("Something went wrong! Please try again")
    }
    this.closeChatCreate();
    HelperFunctions.hideLoader();
  }

  onClickDeleteGroupEventChat = async (guid: string) => {
    HelperFunctions.showLoader();
    this.setState({ selectedDeletedEventChatGuid: guid }, async() => {
      this.deleteEventChatApiCallId = await deleteGroupChat({
        token: this.state.token,
        body: {
          guid: [guid]
        }
      })
    })
  }

  handleOnDeleteGroupEventChat = (message: Message) => {
    const response = handleDeleteGroupChat(message);
    if (response && response.message) {
      HelperFunctions.showSuccessToast(response.message)
      const chatList = this.state.eventChatList.filter((item) => item.guid[0] !== this.state.selectedDeletedEventChatGuid);
      const openChatList = this.state.openEventChatRooms.filter((item) => item.guid[0] !== this.state.selectedDeletedEventChatGuid);
      this.setState({ eventChatList: chatList, openEventChatRooms: openChatList })
    } else {
      HelperFunctions.showErrorToast("Something went wrong! please try again")
    }
    HelperFunctions.hideLoader();
  }

  registerUpdateEventChatCallBack = (callback: any)=>{
    this.setState({
        updateChildEventChatComponent: callback
    })
  }

  setToken = async() => {
    const token = await HelperFunctions.getStorageData("authToken")
    this.setState({ token: token }, async()=>{
      const userData = await HelperFunctions.getUserData();
      if (userData) {
        this.setState({userId: userData.id});
        this.getEventDetail();
          this.getRecentPost();
      }
      this.getEventChats();
    });
  }

  handleGoBackFromScreen = () => {
    this.props.navigation.navigate("Events");
  }

  redirectTo = (endpoint: string, params?:{[key:string]:string | number}) => {
    this.props.navigation.navigate(endpoint, params)
  }

  handleGetPostLikedUsersListCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.data?.data) {
      this.setState({
        postLikedUserList: [...responseJson.data.data],
      });
    } else {
      this.setState({ postLikedUserList: [] });
    }
    HelperFunctions.hideLoader();
  };

  getLikedUsersList = async(postId: number | string) => {
    HelperFunctions.showLoader();
    this.postLikedUsersListApiCallId = await apiCallFunction({
      method: configJSON.validationApiMethodType,
      contentType: configJSON.appointmentApiContentType,
      token: this.state.token,
      endPoint: `${configJSON.postLikedUsersListApiEndpoint}?post_id=${postId}`,
    });
  }

  setLikedUserList = () => {
    this.setState({
      postLikedUserList: undefined,
    });
  };

  handleEventDetailCall = (message:Message) => {
    const responseJson = handleEventDetailCall(message);
    const registeredContacts = responseJson.data?.attributes?.user_contacts?.map((registeredContact: UserContact) => {
      return {
        id: registeredContact.id,
        name: registeredContact.name,
        email: "",
        full_phone_number: registeredContact.full_phone_number,
      }
    });
    const phonebookContacts = responseJson.data?.attributes?.invitation_members?.filter((item: IPhonebookContact) => !HelperFunctions.isValidEmail(item?.invite_record))?.map((registeredContact: IPhonebookContact) => {
      return {
        id: parseInt(registeredContact.id),
        name: registeredContact.contact_name || "",
        email: "",
        full_phone_number: registeredContact.invite_record,
        isNonRegistered: true,
     }
    })
    this.setState({
      eventDetail: responseJson.data,
      contactList: registeredContacts,
      allContactsList: registeredContacts,
      phoneBookContactList: phonebookContacts || [],
      allPhonebookContactsList: phonebookContacts || [],
    })
    HelperFunctions.hideLoader();
  }

  getEventDetail = async ()=> {
    HelperFunctions.showLoader();
    const eventId = this.props.navigation?.getParam("eventId");
    this.apiGetEventDetails = await getEventDetail({
      token: this.state.token,
      endPoint: eventId
    })
  }

  getRecentPost = async ()=> {
    HelperFunctions.showLoader();
    const eventId = this.props.navigation?.getParam("eventId");
    this.recentPostApiCallId = await HelperFunctions.apiCall({
      method: configJSON.validationApiMethodType,
      contentType: configJSON.validationApiContentType,
      token: this.state.token,
      endPoint: `${configJSON.recentPostsEndpoint}?event_id=${eventId}&per_page=1&page_no=1`,
    });
  }

  handleCreateRecentPostCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.errors && responseJson?.data) {
      this.setState({ recentPostDetails: responseJson.data })
    } else {
      const postErrorMsg = responseJson?.errors?.[0]?.message
        ? responseJson.errors[0].message
        : "Something went wrong, please try again!";
      HelperFunctions.showErrorToast(postErrorMsg);
    }
    this.setState({ isPostUploading: false })
    HelperFunctions.hideLoader();
  };

  onCreateRecentPost = async () => {
    const { recentPostUploadedBlobIds, postDetails } = this.state;
    this.closeCreatePostModal();
    HelperFunctions.showLoader();
    const eventId = this.props.navigation?.getParam("eventId");
    const body: {body?: string, event_id?: string, blob_ids?: string[]} = {}
    if(postDetails){
      body.body = postDetails.postText
    }
    body.event_id = eventId;
    if(recentPostUploadedBlobIds.length) {
      const blobIds = recentPostUploadedBlobIds.join(",")
      body.blob_ids = [blobIds];
    }

    this.addPostApiCallId = await HelperFunctions.apiCall({
      method: configJSON.postApiMethodType,
      token: this.state.token,
      endPoint: `${configJSON.recentPostsEndpoint}`,
      body,
      contentType: configJSON.validationApiContentType,
    });
    this.setState({ postDetails: null, recentPostUploadedBlobIds: [] });
    this.uploadPostFileApiCallId = []
  };

  onClickPostShare = async (postDetails: IAddPost) => {
    this.setState({
      postDetails: postDetails,
      isPostUploading: true
    }, () => {
      if (postDetails?.postImgFiles?.length) {
        postDetails.postImgFiles.forEach(async (file) => {
          const formData = new FormData();
          formData.append("media", file);
  
          const fileUploadApiId = await HelperFunctions.uploadFilesToServer({
            token: this.state.token,
            body: formData,
            type: "formData"
          });
          this.uploadPostFileApiCallId.push(fileUploadApiId)
        })
      } else {
        this.onCreateRecentPost();
      }
    })
  }

  handlePostUploadFileApiCall = (message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson && responseJson.blob_id){
      this.setState(prevState=>{
        return {
          recentPostUploadedBlobIds: [...prevState.recentPostUploadedBlobIds, responseJson.blob_id]
        }
      },()=>{
        const { recentPostUploadedBlobIds, postDetails } = this.state;
        if(postDetails && postDetails.postImages.length === recentPostUploadedBlobIds.length){
          this.onCreateRecentPost();
        }
      })
    }
  }

  likePost = async (postId: string) => {
    HelperFunctions.showLoader();
    const body = {
      "likeable_id": postId,
      "like_type": "post"
    }
    this.likeRecentPostApiCallId = await HelperFunctions.apiCall({
      method: configJSON.postApiMethodType,
      contentType: configJSON.validationApiContentType,
      token: this.state.token,
      endPoint: `${configJSON.likeRecentPostApiEndpoint}`,
      body,
    });
  }
  
  disLikePost = async (postId: string) => {
    HelperFunctions.showLoader();
    const body = {
      "likeable_id": postId,
      "like_type": "post"
    }
    this.dislikeRecentPostApiCallId = await HelperFunctions.apiCall({
      method: configJSON.postApiMethodType,
      contentType: configJSON.validationApiContentType,
      token: this.state.token,
      body,
      endPoint: `${configJSON.likeRecentPostApiEndpoint}`,
    });
  }
  
  handleLikePostApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.errors) {
      const { recentPostDetails } = this.state;
      if(recentPostDetails) {
        const updatedRecentPost = { ...recentPostDetails };
          updatedRecentPost.attributes.liked_by_me = true;
          updatedRecentPost.attributes.total_likes += 1;
          this.setState({ recentPostDetails: updatedRecentPost })
      }
    } else {
      const likePostErrMsg = responseJson?.errors?.[0]?.message
        ? responseJson.errors[0].message
        : "Something went wrong, please try again!";
      HelperFunctions.showErrorToast(likePostErrMsg);
    }
    HelperFunctions.hideLoader();
  }

  handleDisLikePostApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.errors) {
      const { recentPostDetails } = this.state;
      if(recentPostDetails) {
        const updatedRecentDislikePost = { ...recentPostDetails };
          updatedRecentDislikePost.attributes.liked_by_me = false;
          updatedRecentDislikePost.attributes.total_likes -= 1;
          this.setState({ recentPostDetails: updatedRecentDislikePost })
      }
    } else {
      const disLikePostErrMsg = responseJson?.errors?.[0]?.message
        ? responseJson.errors[0].message
        : "Something went wrong, please try again!";
      HelperFunctions.showErrorToast(disLikePostErrMsg);
    }
    HelperFunctions.hideLoader();
  }

  handleRecentPostCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.data?.length) {
      this.setState({ recentPostDetails: responseJson.data[0] });
    } else {
      this.setState({ recentPostDetails: null });
    }
    HelperFunctions.hideLoader();
  }

  handleEventDetailRsvpStatusUpdate = (message:Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (responseJson && !responseJson?.errors) {
      this.setState({
        isEventRsvpStatusUpdateMode: false
      },()=>{
        HelperFunctions.showSuccessToast("RSVP status updated.")
        this.getEventDetail();
        this.getRecentPost();
      })
    } else {
      const rsvpUpdateMsg = responseJson?.errors?.[0]?.message ? responseJson.errors[0].message : "Something went wrong, please try again!";
      HelperFunctions.showErrorToast(rsvpUpdateMsg);
    }
    HelperFunctions.hideLoader();
  }

  onUpdateEventRsvpStatus = async(status:string, eventId:string | number) => {
    HelperFunctions.showLoader();
    const body = {
      status
    };
    this.apiUpdateRsvpStatusOfEvent = await apiCallFunction({
      method: configJSON.patchApiMethodType,
      contentType: configJSON.validationApiContentType,
      endPoint:`${configJSON.eventsEndpoint}/${eventId}/add_event_status`,
      token: this.state.token,
      body
    })
  }

  onRsvpUpdateModeChange = ()=>{
    this.setState(prevState=>{
      return {
        isEventRsvpStatusUpdateMode: !prevState.isEventRsvpStatusUpdateMode
      }
    })
  }

  openEventDeleteModal = (title: string, eventId?: string | number) => {
    this.setState({
      eventDeleteConfirmationPopup: {
        header: "Delete Event?",
        message: `Are you sure that you want to delete ${title}?`,
        type: "delete",
        eventId: eventId as string
      }
    })
  }

  onCloseEventDeleteConfirmationModal = () => {
    this.setState({
      eventDeleteConfirmationPopup: null
    })
  }

  handleDeleteEventCall = (message:Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors) {
      HelperFunctions.showSuccessToast("Event deleted successfully!")
      this.redirectTo("Events");
    } else {
      const deletErrorMsg = responseJson?.errors?.[0]?.message ? responseJson.errors[0].message : "Something went wrong, please try again!";
      HelperFunctions.showErrorToast(deletErrorMsg);
    }
    HelperFunctions.hideLoader();
  }

  onAcceptEventDeleteConfirmation = async () => {
    if (this.state.eventDeleteConfirmationPopup) {
      const { type, eventId } = this.state.eventDeleteConfirmationPopup;
      if (type === "delete") {
        this.setState({
          eventDeleteConfirmationPopup: null
        })
        HelperFunctions.showLoader();
        this.deleteEventApiCallId = await apiCallFunction({
          method:configJSON.deleteApiMethodType,
          endPoint:`${configJSON.eventsEndpoint}/${eventId}`,
          contentType: configJSON.appointmentApiContentType,
          token: this.state.token
        })
      }
    }
  }

  openCreatePostModal = () => {
    this.setState({
      postDetails: {
        postText: "",
        postImages: [],
      }
    })
  }

  closeCreatePostModal = () => {
    this.setState({ postDetails: null })
  }

  onSubmitPostDetails = (postDetails: IAddPost) => {
    this.onClickPostShare(postDetails);
  }

  handleChangeCommentsPageNumber = () => {
    if(!this.state.isAllCommentsFetched){
      this.setState({ commentsPageNum: this.state.commentsPageNum + 1}, () => this.getCommentsList())
    }
  } 

  handlePostComentGetMentionsListCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.data) {
      const mentionsList: Array<IMentionInputType> = responseJson.data?.map((item: { attributes: { account_info: { id: number, user_name: string, name: string}}}) => {
        return {
          id: item.attributes.account_info.id.toString(),
          display: item.attributes.account_info.user_name,
        }
      })
      this.setState({
        searchedPostCommentMentionsList: [ ...mentionsList],
      });
    } else {
      this.setState({ searchedPostCommentMentionsList: [] });
    }
  };

  getPostComentMentionsList = async() => {
    const { eventDetail } = this.state;
    this.searchPostCommentMentionsApiCallId = await HelperFunctions.apiCall({
      method: configJSON.validationApiMethodType,
      contentType: configJSON.validationApiContentType,
      token: this.state.token,
      endPoint: `${configJSON.searchMentionsApiEndpoint}?event_id=${eventDetail?.id}`,
    });
  }

  getCommentsList = async ()=>{
    this.setState({
      isLoadingCommentsList: true
    })
    const { recentPostDetails } = this.state;
    this.getCommentsListApiCallId = await HelperFunctions.apiCall({
      method: configJSON.validationApiMethodType,
      contentType: configJSON.validationApiContentType,
      token: this.state.token,
      endPoint: `${configJSON.commentsApiEndpoint}?page_no=${this.state.commentsPageNum}&per_page=10&commentable_id=${recentPostDetails?.id}&order=desc`,
    });
  }

  handleGetCommentsListApiCall = (message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.meta?.next_page) {
      this.setState({ isAllCommentsFetched: true })
    }
    if(responseJson?.data?.length){
      this.setState({
        commentsList: [ ...this.state.commentsList, ...responseJson.data]
      })
    }
    this.getPostComentMentionsList();
    this.setState({
      isLoadingCommentsList: false
    })
  } 

  openPostCommentsModal = (postId: string)=>{
    this.setState({
      postCommentModal: {
        id: postId
      },
      commentsPageNum: 1
    }, () => { this.getCommentsList() })
  }

  closePostCommentsModal = ()=>{
    this.setState({
      postCommentModal: null,
      commentsPageNum: 1,
      commentsList: [],
      isAllCommentsFetched: false,
    })
  }

  scrollToTopOnPostComment = () => {
    const topCommentDiv = document.getElementById("top_comment_div");
    if (topCommentDiv) {
      topCommentDiv.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    }
  }

  onAddPostComment = async (comment:{ text: string, postId: string })=>{
    this.setState({
      isLoadingCommentsList: true
    })
    const body = {
      comment: {
        commentable_type: "BxBlockPosts::Post",
        commentable_id: comment.postId,
        comment: comment.text
      }
    }
    this.addCommentInRecentPostApiCallId = await HelperFunctions.apiCall({
      method: configJSON.postApiMethodType,
      contentType: configJSON.validationApiContentType,
      token: this.state.token,
      endPoint: `${configJSON.commentsApiEndpoint}`,
      body
    });
  }

  handleAddPostCommentApiCall = (message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson?.data){
      const { recentPostDetails, commentsList } = this.state;
      let updatedCommentsList = [{ ...responseJson.data}, ...commentsList];
      if(recentPostDetails){
        let updatedRecentPost = { ...recentPostDetails };
        updatedRecentPost.attributes.comments_count += 1;
        this.setState({ recentPostDetails: updatedRecentPost })
      }
      this.setState({
        commentsList: updatedCommentsList,
      })
      this.scrollToTopOnPostComment();
    } else{
      HelperFunctions.showErrorToast("Something went wrong! please try again")
    }
    this.setState({
      isLoadingCommentsList: false
    })
  }
  
  onDeletePostComment = async (commentId: string) => {
    this.setState({
      isLoadingCommentsList: true,
      selectedCommentId: commentId,
    })
    const eventId = this.props.navigation?.getParam("eventId");
    const { token, postCommentModal } = this.state;
    this.deleteCommentFromRecentPostApiCallId = await HelperFunctions.apiCall({
      method: configJSON.deleteApiMethodType,
      endPoint: `${configJSON.commentsApiEndpoint}/${commentId}?event_id=${eventId}&commentable_id=${postCommentModal?.id}`,
      contentType: configJSON.validationApiContentType,
      token
    })
  }

  handleDeletePostCommentApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.message) {
      const { recentPostDetails, commentsList, selectedCommentId } = this.state;
      let updatedCommentsList = commentsList.filter((item) => item.id !== selectedCommentId);
      if (recentPostDetails) {
        let updatedRecentPost = { ...recentPostDetails };
        updatedRecentPost.attributes.comments_count -= 1;
        this.setState({ recentPostDetails: updatedRecentPost })
      }
      this.setState({
        commentsList: updatedCommentsList,
      })
    } else {
      HelperFunctions.showErrorToast("Something went wrong! please try again")
    }
    this.setState({
      isLoadingCommentsList: false,
      selectedCommentId: null,
    })
  }

  // Customizable Area End
}
