// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
import { getStorageData } from "../../../framework/src/Utilities";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
import { IAccount } from "../../../components/src/CommonTypes";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    userInfo: {
        full_name: string;
        email: string | null;
        phone_number: string | number;
        country_code: string | number;
        image_url: string | null;
        interests: string;
        dob: string | null;
        user_name: string;
    } | null;
    isProfileIconLoading: boolean;
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ProfileDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getProfileDetailsApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            userInfo: null,
            isProfileIconLoading: true,
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    receive = async (from: string, message: Message) => {
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let authToken = message.getData(getName(MessageEnum.SessionResponseToken));
            if (!authToken) {
                authToken = await getStorageData("authToken");
            }
            this.setToken(authToken);
        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if(webApiRequestCallId === this.getProfileDetailsApiCallId){
                this.handleGetProfileDetailsApiCall(message);
            }
        }
    };

    async componentDidMount() {
        await super.componentDidMount();
        this.getToken();
        // Customizable Area Start
        // Customizable Area End
    }

    getToken = () => {
        const message: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(message);
    };


    // Customizable Area Start

    setToken = async (token: string | null) => {
       this.setState({ token },()=>{
         this.getUserProfile();
       })
    }

    redirectTo = (endpoint: string)=>{
       this.props.navigation.navigate(endpoint);
    }

    getUserProfile = async ()=>{
        helper.showLoader();
        this.getProfileDetailsApiCallId = await helper.apiCall({
            method: "GET",
            contentType: configJSON.validationApiContentType,
            endPoint: `${configJSON.profileEndpoint}`,
            token: this.state.token
        })
    }

    handleGetProfileDetailsApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.data) {
            const userData: IAccount = responseJson.data;
            this.setState({
                userInfo:{
                    full_name: userData.attributes.full_name,
                    email: userData.attributes.email,
                    phone_number: userData.attributes.phone_number,
                    country_code: userData.attributes.country_code,
                    image_url: userData.attributes.image_url,
                    interests: userData.attributes.interests.map(interest=> interest.name).join(", "),
                    dob: userData.attributes.date_of_birth,
                    user_name: userData.attributes.user_name
                }
            })
        }
        helper.hideLoader();
    }

    // Customizable Area End
}