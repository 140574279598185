// Customizable Area Start
import React from "react";
import GroupInfoController, {
    Props
} from "./GroupInfoController";
import { styled } from "@material-ui/styles";
import { CloseIcon, GroupFilledIcon, AddIcon, pendingUser, RedCheck, RedClose } from "./assets";
import { Box, IconButton, Typography } from "@material-ui/core";
import { MorePopUp, Button, ConfirmationPopUp, Avatar } from "../../../components/src";
import { DeleteIcon, EditIcon } from "../../appointmentmanagement/src/assets";
import GroupImage from "./components/GroupImage.web";
import { InvitedMember, ReverseGroupType } from "./types";
import { UserContact } from "../../../components/src/CommonTypes";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");

// Customizable Area End

export default class GroupInfo extends GroupInfoController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    Header = () => {
        const {
            selectedGroup
        } = this.state;
        return (
            <StyledGroupInfoHeader className="GroupInfo_StyledGroupInfoHeader">
                <div className="left">
                    <div>
                        <IconButton onClick={this.props.onClose}>
                            <img src={CloseIcon} />
                        </IconButton>
                    </div>
                    <p>Group Info</p>
                </div>
                <div className="right">
                    {
                        selectedGroup && selectedGroup.is_admin && (
                            <MorePopUp menuItems={[
                                {
                                    label: "Edit",
                                    icon: EditIcon,
                                    onClick: () => { this.props.navigateTo("EditGroup", { id: selectedGroup.id}) }
                                },
                                {
                                    label: "Delete",
                                    icon: DeleteIcon,
                                    onClick: this.onDeleteGroupHandler
                                }
                            ]}
                            />
                        )
                    }
                </div>
            </StyledGroupInfoHeader>
        )
    }

    Info = () => {
        const {
            groupDetail
        } = this.props;
        return (
            <>
                {
                    groupDetail && (
                        <StyledInfo className="GroupInfo_StyledInfo">
                            <GroupImage
                                image_url={groupDetail.image_url}
                                imageContainerStyles={{
                                    width: "6.5rem",
                                    height: "6.5625rem",
                                    borderRadius: "1rem"
                                }}
                                defaultImageStyles={{
                                    width: "2.5rem"
                                }}
                            />
                            <p className="capital-text name">{groupDetail.name}</p>
                            <p>Group Type: {ReverseGroupType[groupDetail.group_type]}</p>
                        </StyledInfo>
                    )
                }
            </>
        )
    }

    Members = () => {
        const {
            selectedGroup,
        } = this.state;

        const getTotalMembersCount = (userContacts: Array<UserContact> , invitationMembers: Array<InvitedMember>) => {
            
            const uniqueInvitedMembers = invitationMembers.filter(invitationMember => 
                !userContacts.some(user => user.email === invitationMember.invite_record)
            );
        
            return userContacts.length + uniqueInvitedMembers.length;
        }

        return (
            <>
                {
                    selectedGroup && (
                        <>
                            <StyledMembers className="GroupInfo_StyledMembers">
                                <div className="members-count">
                                    <div className="left">
                                        <img src={GroupFilledIcon} />
                                        Members
                                    </div>
                                    <div className="right">{getTotalMembersCount(selectedGroup.user_contacts || [], selectedGroup.invitation_members || [])}</div>
                                </div>
                                {selectedGroup.is_admin && <Button
                                    text="Add Members"
                                    icon={AddIcon}
                                    type="link"
                                    buttonStyles={{
                                        width: "fit-content",
                                        padding: 0,
                                        fontSize: "1rem"
                                    }}
                                    onClick={() => this.props.navigateTo("EditGroup", { id: selectedGroup.id })}
                                />}
                                <div className="members">
                                    {
                                        selectedGroup.user_contacts && selectedGroup.user_contacts.sort((a, b) => Number(b?.organizer) - Number(a?.organizer)).map(member => {
                                            return (
                                                <div className="member" key={member.id}>
                                                    <Avatar
                                                        text={member.name[0]}
                                                        image_url={member.image_url}
                                                    />
                                                    <div className="member-name-div"><p>{helper.truncateText(member.name, 20)}</p>
                                                        {member.organizer && <Typography component={"span"}>(Admin)</Typography>}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    {
                                         (selectedGroup.invitation_members || [])
                                            .filter(invitationMember =>
                                                !(selectedGroup.user_contacts || []).some(user => user.email === invitationMember.invite_record)
                                            )
                                            .map(invitationMember => (
                                                <div className="member" key={invitationMember.id}>
                                                    <div className="avatar-text">
                                                        {invitationMember.contact_name[0] || "U"}
                                                    </div>
                                                    <p>{invitationMember.contact_name || ""}</p>
                                                </div>
                                            ))
                                    }
                                </div>
                            </StyledMembers>
                            {selectedGroup?.is_admin && selectedGroup?.request_pending && selectedGroup.request_pending?.length > 0 &&
                                <StyledMembers className="GroupInfo_StyledMembers">
                                    <Box marginTop={"20px"} className="members-count">
                                        <Box className="left">
                                            <img src={pendingUser} />
                                            Pending Members
                                        </Box>
                                        <Box className="right">{selectedGroup.request_pending.length}</Box>
                                    </Box>
                                    <Box id="pending-members" className="members">
                                        {
                                            selectedGroup.request_pending.map(pendingMember => {
                                                return (
                                                    <Box justifyContent={"space-between"} className="member" key={pendingMember.id}>
                                                        <Box gridGap={"8px"} display={"flex"} alignItems={"center"}>
                                                            <Box className="avatar-text">
                                                                {pendingMember.attributes.account_info.full_name[0]}
                                                            </Box>
                                                            <p>{pendingMember.attributes.account_info.full_name}</p>
                                                        </Box>
                                                        <Box className="group-pending-request-action-btns" display={"flex"}>
                                                            <img id="accept-request-icon" onClick={() => this.acceptRejectGroupPendingRequest(pendingMember.id, "accepted")} src={RedCheck} alt="accept-request" />
                                                            <img id="reject-request-icon" onClick={() => this.acceptRejectGroupPendingRequest(pendingMember.id, "rejected")} src={RedClose} alt="reject-request" />
                                                        </Box>
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Box>
                                </StyledMembers>}
                        </>
                    )
                }
            </>
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            groupDeleteConfirmationPopup
        } = this.state;
        return (
            <StyledGroupInfoContainer className="GroupInfo_StyledGroupInfoContainer">
                {this.Header()}
                <div className="group-info-detail">
                    {this.Info()}
                    {this.Members()}
                </div>
                {
                    groupDeleteConfirmationPopup && (
                        <ConfirmationPopUp
                            open={Boolean(groupDeleteConfirmationPopup)}
                            header={groupDeleteConfirmationPopup.header}
                            message={groupDeleteConfirmationPopup.message}
                            onClose={this.onCloseConfirmationPopup}
                            onAccept={this.onAccept}
                            onReject={this.onCloseConfirmationPopup}
                        />
                    )
                }
            </StyledGroupInfoContainer>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledGroupInfoContainer = styled("div")({
    "&&.GroupInfo_StyledGroupInfoContainer": {
        display: "flex",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        flexDirection: "column",
        "& > .group-info-detail": {
            display: "flex",
            width: "100%",
            boxSizing: "border-box",
            flexDirection: "column",
            overflow: "auto",
            flex: 1,
            paddingBottom: "1rem"
        }
    }
})
const StyledGroupInfoHeader = styled("div")({
    "&.GroupInfo_StyledGroupInfoHeader": {
        display: "flex",
        width: "100%",
        boxSizing: "border-box",
        height: "4.75rem",
        borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0rem 1rem",
        "& .left, .right": {
            width: "fit-content",
            display: "flex",
            alignItems: "center"
        },
        "& > .left > p": {
            fontSize: "1.25rem",
            fontWeight: 500,
            color: "rgba(255, 255, 255, 1)",
            marginLeft: "0.2rem"
        }
    }
})
const StyledInfo = styled("div")({
    "&.GroupInfo_StyledInfo": {
        display: "flex",
        width: "100%",
        boxSizing: "border-box",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "0.3rem",
        padding: "3rem 1rem 2rem 1rem",
        "& .name": {
            wordWrap: "break-word",
        },
        "& > p": {
            fontWeight: 500,
            color: "rgba(255, 255, 255, 1)",
            "&:first-child": {
                fontSize: "1rem"
            },
            "&:last-child": {
                fontSize: "0.75rem"
            }
        }
    }
})
const StyledMembers = styled("div")({
    "&.GroupInfo_StyledMembers": {
        display: "flex",
        flexDirection: "column",
        padding: "0rem 1rem",
        gap: "1rem",
        "& > .members-count": {
            display: "flex",
            height: "3rem",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: "1rem",
            fontWeight: 400,
            color: "rgba(255, 255, 255, 1)",
            borderBottom: "1px solid #2d4953",
            flexShrink: 0,
            "& > .left": {
                display: "flex",
                alignItems: "center",
                gap: "0.5rem"
            },
            "& > .right": {
                color: "rgba(255, 255, 255, 0.8)"
            }
        },
        "& > .members": {
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            "& > .member": {
                display: "flex",
                gap: "0.5rem",
                fontSize: "0.875rem",
                fontWeight: 400,
                color: "rgba(255, 255, 255, 1)",
                alignItems: "center",
            },
            "& .member-name-div": {
                display: "flex",
                alignItems: "center",
                gap: "8px",
                fontSize: "0.875rem !important",
                textTransform: "capitalize",
                "& span": {
                    fontSize: "0.875rem",
                }
            },
            "& .group-pending-request-action-btns": {
                gap: "20px",
                paddingTop: "3px",
                "& img": {
                    cursor: "pointer",
                    width: "12px",
                    height: "12px",
                    "&:first-child": {
                        width: "18px",
                        height: "14px",
                    }
                }
            }
        }
    }
})
// Customizable Area End
