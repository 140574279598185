import { UserContact } from "../../../components/src/CommonTypes";

export const types = {};
export enum GroupType {
    PRIVATE = "private_group",
    PUBLIC = "public_group"
}

export const ReverseGroupType:{[key: string]: string} = {
  private_group: "Private",
  public_group: "Public"
}

export interface IGroup{
    id: number;
    name: string;
    description: string;
    image_url: string;
    group_type: string;
    type?: "my-group" | "shared-group",
    members_count?: number;
    members?: Array<GroupMember>;
    user_contacts?: Array<UserContact>;
    request_pending?: Array<GroupMember>;
    request_pending_count?: number;
    is_admin?: boolean;
    is_trending?: boolean;
    is_current_user_member?: boolean;
    invitation_members?: Array<InvitedMember>;
}

export interface GroupMember {
    id: number;
    type: string;
    attributes: GroupMemberAttributes;
  }
  
  export interface InvitedMember {
    id: number;
    invitable_id: number;
    invitable_type: string;
    status: string;
    invite_record: string;
    contact_name: string;
    created_at: string;
    updated_at: string;
    image_url: string;
  }

  interface GroupMemberAttributes {
    id: number;
    joined_status: string;
    account_id: number;
    group_id: number;
    joined_by_invitation: boolean;
    role: string;
    account_info: AccountInfo;
  }
  
  interface AccountInfo {
    id: number;
    full_phone_number: string;
    user_name: string;
    full_name: string;
    image_url: string;
  }