// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
const {
    HelperFunctions: helper,
} = require("../../../components/src/HelperFunctions");
import { CometChat } from '@cometchat-pro/chat';
import { v4 as uuidv4 } from "uuid";
import { initiateCometChat, cometChatLogin, getGroupChatInfo, getGroupChat, getGroupChatApiCallHandler, getGroupChatSearchMentionsApiCallHandler, getGroupChatSearchMentionsList } from "./actions";
import { IChatData, IMentionInputType } from "../../../components/src/CommonTypes";
import _ from "lodash";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    isFromAppointmentOrEvent?: boolean;
    matchMedia?: boolean;
    closeGroupChatPopup?: (chatGuid?: string) => void;
    onClickAddMemberButton?: (event: any)=> void;
    onClickEditButton?: (event: any)=> void;
    onDeleteGroupChat?: (event: string)=> void;
    registerUpdateCallBack?: (event: any)=> void;
    onClickRemoveMember?: (memberId: number, memberName: string, guid: string) => void;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    openChatInfo: boolean;
    guid: string | null;
    loading: boolean;
    groupChatId: string | null;
    messages: CometChat.BaseMessage[];
    uid: string;
    group_id: string;
    group: CometChat.Group | null;
    groupMembers: CometChat.GroupMember[];
    hideWhenOpenChatInfo: boolean;
    isMessagesLoading: boolean;
    hasMoreMessages: boolean;
    messageRequestRef: CometChat.MessagesRequest | null;
    prevChatContainerHeight: number;
    chatData: IChatData | null;
    chatMentionsList: IMentionInputType[];
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ViewGroupChatController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getGroupChatInfoApiCallId: string = "";
    getAllPostsApiId?: string;
    getChatGroupApiCallId: string = "";
    getMentionsListApiId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            openChatInfo: false,
            guid: "",
            loading: false,
            groupChatId: "",
            messages: [],
            uid: "",
            group: null,
            groupMembers: [],
            hideWhenOpenChatInfo: false,
            isMessagesLoading: false,
            hasMoreMessages: true,
            messageRequestRef: null,
            prevChatContainerHeight: 0,
            chatData: null,
            group_id: "",
            chatMentionsList: [],
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        // Customizable Area Start
        if(this.props.registerUpdateCallBack){
            this.props.registerUpdateCallBack(this.getChatGroup)
        }
        const searchParams = new URLSearchParams(this.props.navigation.history.location.search)
        const guid = searchParams.get("id") || searchParams.get("chat_id");
        const userData = await helper.getStorageData("userData", true);
        
        const token = await helper.getStorageData("authToken");
        if(this.props.isFromAppointmentOrEvent) {
            this.setState({ loading: true })
        }
        if (guid && userData) {
            this.setState({
                guid,
                uid: userData.id,
                token
            }, () => {
                this.getChatGroup();
            })
        }

        const chatContainer = document.getElementById("chat-container");
        if(chatContainer){
            chatContainer.addEventListener("scroll", this.handleScroll)
        }
        // Customizable Area End
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        const searchParams = new URLSearchParams(this.props.navigation.history.location.search)
        const guid = searchParams.get("id") || searchParams.get("chat_id");
        if(this.state.guid && guid && this.state.guid !== guid){
            this.setState({
                guid,
                messages: [],
                hasMoreMessages: true,
            },()=>{
                this.getChatGroup()
            })
        }
    }

    async componentWillUnmount() {
        const chatContainer = document.getElementById("chat-container");
        if(chatContainer){
            chatContainer.removeEventListener("scroll", this.handleScroll)
        }
    }

    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if(webApiRequestCallId === this.getChatGroupApiCallId){
                this.handleGetGroupChatApiCall(message);
            } else if(webApiRequestCallId === this.getMentionsListApiId){
                this.handleGetChatMentionsListCall(message)
            }
        }
        // Customizable Area End
    };

    // Customizable Area Start
    toggleChatInfo = ()=>{
        this.setState((prevState)=> {
            return {
                openChatInfo: !prevState.openChatInfo,
            }
        })
        if(this.props.isFromAppointmentOrEvent) {
            this.setState({ hideWhenOpenChatInfo: !this.state.hideWhenOpenChatInfo })
        }
    }

    getGroupChatInfo = async () => {
        const {
          guid
        } = this.state;
        const {
            isFromAppointmentOrEvent
        } = this.props;
        
        if(guid){
            this.setState({
                loading: true
            })
            const result = await getGroupChatInfo(guid);
            
            if(!result){
                if(isFromAppointmentOrEvent) {
                    this.props.closeGroupChatPopup?.(guid);
                } else {
                    this.props.navigation.history.push("/chats")
                }
                
            }else{
                this.setState({
                    group: result.group,
                    groupMembers: result.allMembers
                })
            }
            this.setState({
                loading: false
            })
        }
    }

    getMessagesFromCometChat = (isFirstTime?: boolean) => {
        const {
            guid,
            messageRequestRef,
            messages
        } = this.state;
        if (guid && messageRequestRef) {
            messageRequestRef.fetchPrevious().then(
                (messageList: CometChat.BaseMessage[]) => {
                    if (messageList && messageList.length > 0) {
                        this.setState({
                            messages: _.uniqBy([...messageList, ...messages], "id")
                        },()=>{
                            this.goToPreviousChat();
                        })
                    } else {
                        this.setState({
                            hasMoreMessages: false
                        })
                    }
                }
            ).finally(() => {
                this.setState({
                    isMessagesLoading: false
                })
                if(isFirstTime){
                    this.goToBottomChat()
                }
            });
        }
    }

    sendMessage = (event:{text: string}) => {
        const {
            guid
          } = this.state;
          
        if (guid) {
            const textMessage = new CometChat.TextMessage(
                guid,
                event.text,
                CometChat.RECEIVER_TYPE.GROUP
            );

            CometChat.sendMessage(textMessage).then(
                (message: CometChat.BaseMessage) => {
                    this.setState(prevState=>({messages: [...prevState.messages, message]}),()=>{
                        this.goToBottomChat();
                    })
                }
            );
        }
    }

    isSendByMe = (message: CometChat.BaseMessage)=>{
        const {
          uid
        } = this.state;
        
        return Boolean(message.getSender().getUid() === `${uid}`)
    }

    formatTime = (sentAt: number) => {
        const date = new Date(sentAt * 1000); // Convert Unix timestamp to milliseconds
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Convert to 12-hour format
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${formattedHours}:${formattedMinutes} ${ampm}`;
    };

    goToBottomChat = ()=>{
        const chatContainer = document.getElementById("chat-container");
        if(chatContainer){
            chatContainer.scrollTo({
                top: chatContainer.scrollHeight,
                behavior: "smooth"
            });
        }
    }

    goToPreviousChat = () => {
        const chatContainer = document.getElementById("chat-container");
        const {
         prevChatContainerHeight
        } = this.state;
        if (chatContainer && prevChatContainerHeight) {
            chatContainer.scrollTop = chatContainer.scrollHeight - prevChatContainerHeight;
        }
    }

    handleScroll = ()=>{
        const chatContainer = document.getElementById("chat-container");
        const {
            hasMoreMessages,
            isMessagesLoading
        } = this.state;
    
        if(chatContainer && chatContainer.scrollTop === 0 && !isMessagesLoading && hasMoreMessages){
            this.setState({
                prevChatContainerHeight: chatContainer.scrollHeight
            })
            this.getMessagesFromCometChat()
        }
    }

    addMessageListener = () => {
        CometChat.addMessageListener(
            uuidv4(),
            new CometChat.MessageListener({
                onTextMessageReceived: (message: CometChat.TextMessage) => {
                    if (
                        message.getConversationId() ===
                        this.state.chatData?.group.data.conversationId
                    ) {
                        this.setState(prevState => ({ messages: _.uniqBy([...prevState.messages, message], "id") }), () => {
                            this.goToBottomChat();
                        })
                    }
                }
            })
        );
    };

    getChatGroup = async ()=>{
        const {
          token,
          guid
        } = this.state;
        this.setState({
            loading: true
        })
        this.getChatGroupApiCallId = await getGroupChat({
            token,
            query: `guid=${guid}`
        })
    }

    handleGetGroupChatApiCall = (message: Message)=>{
        const response = getGroupChatApiCallHandler(message);
        if(response && response.data){
            this.setState({
                chatData: response.data
            }, async()=>{
                this.loginToCometChat(),
                this.getMentionsListApiId = await getGroupChatSearchMentionsList({
                    token: this.state.token,
                    query: `guid=${this.state.guid}`
                })
            })
        }else{
            helper.showErrorToast("Group chat not found")
            if(this.props.isFromAppointmentOrEvent) {
                this.props.closeGroupChatPopup?.("");
            } else {
                this.props.navigation.history.push("/chats")
            }
        }
        this.setState({
            loading: false
        })
    }

    handleGetChatMentionsListCall = (message: Message) => {
        const responseJson = getGroupChatSearchMentionsApiCallHandler(message);
        if (responseJson?.data) {
            const mentionsList: Array<IMentionInputType> = responseJson.data?.map((item: { attributes: { id: number, user_name: string, name: string } }) => {
                return {
                    id: item.attributes.id.toString(),
                    display: item.attributes.user_name,
                }
            })
            this.setState({
                chatMentionsList: [...mentionsList],
            });
        } else {
            this.setState({ chatMentionsList: [] });
        }
    };

    loginToCometChat = () => {
        const {
            uid,
            guid
        } = this.state;
        if (guid) {
            initiateCometChat({
                onSuccess: () => {
                    runEngine.debugLog("Initialization completed successfully")
                    cometChatLogin({
                        uid: uid,
                        onSuccess: () => {
                            runEngine.debugLog("Login successfully")
                            const messagesRequest = new CometChat.MessagesRequestBuilder()
                                .setGUID(guid)
                                .setLimit(30)
                                .build();
                            this.setState({
                                messageRequestRef: messagesRequest
                            })
                            this.getGroupChatInfo();
                            this.getMessagesFromCometChat(true);
                            this.addMessageListener()
                        },
                        onError: (error: any)=>{
                            runEngine.debugLog("Login failed", error)
                        }
                    })
                },
                onError: (error) => {
                    runEngine.debugLog("Initialization failed", error)
                }
            });
        }

    }

    handleGoToMentionedProfile = (id: string) => {
        this.props.navigation.navigate?.("CommunityForumProfile", { userId: id })
    }
    // Customizable Area End
}