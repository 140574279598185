import React from "react";
import { styled } from "@material-ui/styles";
import Button from "../../../../components/src/Button.web";
import { IEventCard, EVENT_TYPES } from "../../../../components/src/CommonTypes";
import CardScrollWrapper from "../../../events/src/components/CardScrollWrapper.web";
import CustomNoDataComponent from "../../../../components/src/CustomNoDataComponent.web";

interface DashboardEventsProps {
    events: IEventCard[];
    header: string;
    redirectTo: (endpoint: string, params?:{[key:string]:string | number})=>void;
    type: "event" | "appointment";
    loading?: boolean;
}


const DashboardEvents: React.FC<DashboardEventsProps> = (props: DashboardEventsProps) => {
    const { events, header, redirectTo, type, loading } = props;
    const goToDetailPage = (eventId: string)=>{
        if(type === "appointment"){
            redirectTo("AppointmentDetails", { id: eventId })
        }else if(type === "event"){
            redirectTo("EventDetail", { eventId: eventId })
        }
    }
    const goToListPage = ()=>{
        if(type === "appointment"){
            redirectTo("DashboardUpcomingAppointments");
        }else if(type === "event") {
            redirectTo("DashboardEvents");
        }
    }
    return (
        <StyledDashboardEvents className="DashboardEvents_StyledDashboardEvents_Container">
            <div className="events-header">
                <header>{header}</header>
                <Button
                    text="View All"
                    type="link"
                    onClick={goToListPage}
                />
            </div>
            <CardScrollWrapper showNoDataMessage={type === "event"} type={type === "appointment" ? "appointment" : undefined} redirectTo={(endpoint, eventId)=> goToDetailPage(eventId?.eventId as string)} typeOfEvent={EVENT_TYPES.UPCOMING} cardsList={events} />
            {
                !loading && type === "appointment" && events.length === 0 && (
                    <CustomNoDataComponent 
                      titleMsg={"No Upcoming Appointments"} 
                      subTitleMsg={"No scheduled Appointments. Stay tuned for the updates."}
                      useCommonEmptyImg={true} 
                    />
                )
            }
        </StyledDashboardEvents>
    )
}

const StyledDashboardEvents = styled("div")({
  "&.DashboardEvents_StyledDashboardEvents_Container":{
     display: "flex",
     flexDirection: "column",
     gap: "0.5rem",
     "& .events-header":{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& > header":{
            fontSize: "1rem",
            fontWeight: 500,
            color: "rgba(255, 255, 255, 1)"
        }
     }
  }
})

export default DashboardEvents;