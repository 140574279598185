import React, { useEffect, useState } from "react";
import { styled } from "@material-ui/styles";
import SelectAppointmentAndEvent from "./SelectAppointmentAndEvent.web";
import { Button, ConfirmationPopUp, InputText } from "../../../../components/src";
import { AppointmentAttributes, ContactList, EventsAttributes, IAppointmentData, IChatData, IEventData } from "../../../../components/src/CommonTypes";
import { closeIcon } from "../../../appointmentmanagement/src/assets";
import { RedClose } from "../../../groups/src/assets";
import { Dialog, Tooltip } from "@material-ui/core";
const {
    HelperFunctions: helper,
} = require("../../../../components/src/HelperFunctions");
import { LessIcon } from "../assets";
import SelectChatContacts from "./SelectChatContacts.web";
import { AddIcon } from "../../../../blocks/groups/src/assets";

interface Props {
    onClose: () => void;
    appointments?: IAppointmentData[];
    events?: IEventData[];
    contactList?: ContactList[];
    phoneBookContactList?: ContactList[];
    onSearchContact?: (event: string) => void;
    fetchContacts?: () => void;
    onSearch?: (event: string) => void;
    onCreateChat?: (data: any)=> void;
    fetchAppointmentEventContact?: (appointmentOrEvent: {
        type?:  string;
        attributes?: AppointmentAttributes | EventsAttributes | null;
        id: string;
    }) => void;
    isFromAppointmentOrEvent?: boolean;
    appointmentOrEventDetail?: {
        type?:  string;
        attributes?: AppointmentAttributes | EventsAttributes | null;
        id: string;
    } | null;
    type?: "events" | "appointments";
    isEditMode?: boolean;
    isAddMemberMode?: boolean;
    defaultStep?: number;
    chatDetails?: IChatData | null;
    selectedMembers?: ContactList[];
    fetchRegisteredContacts?: (event: any)=> void;
    isLoadingContacts?: boolean;
    registeredContactsPagination?: {
        per_page: number,
        page_no: number,
        hasMore: boolean
    }
    isGroupOwner?: boolean;
    addMemberForChatClick?: (groupChat: IChatData | undefined | null) => void;
    removeMemberApiCall?: (removeMemberId: string) => void;
}


const StartNewChatForm: React.FC<Props> = (props: Props) => {
    const {
        onClose,
        appointments,
        events = [],
        contactList = [],
        onSearchContact,
        onSearch,
        onCreateChat = ()=>{},
        appointmentOrEventDetail,
        isFromAppointmentOrEvent,
        type,
        isEditMode,
        defaultStep,
        chatDetails,
        selectedMembers,
        fetchRegisteredContacts,
        isLoadingContacts,
        registeredContactsPagination,
        fetchAppointmentEventContact,
        isGroupOwner,
        isAddMemberMode,
        removeMemberApiCall,
        addMemberForChatClick,
    } = props;
    const [selectedContactList, setSelectedContactList] = useState<ContactList[]>([]);
    const [step, setStep] = useState(1);
    const [selectedData, setSelectedData] = useState<{
        type?:  string;
        attributes?: AppointmentAttributes | EventsAttributes | null;
        id: string;
    } | null>(null);
    const [removedContactList, setRemovedContactList] = useState<ContactList[]>([]);
    const [chatName, setChatName] = useState("");
    const [tempMemberIdForDelete, setTempMemberIdForDelete] = useState<number>();
    const [confirmationRemoveMemberPopUpData, setConfirmationRemoveMemberPopUpData] = useState<{
        header: string,
        message: string,
        type: string,
        eventId: string,
    } | null>(null)

    const [fetchRegisteredContactsCallBack, setFetchRegisteredContactsCallBack] = useState<any>(null);

    const callBack = ()=>{
        if(fetchRegisteredContactsCallBack) fetchRegisteredContactsCallBack();
    }

    const onSearchContactHandler = (event: string) => {
        if (onSearchContact) {
            onSearchContact(event)
        }
    }

    const onAddContacts = (contacts: ContactList[]) => {
        const updatedList = [...contacts];
        setSelectedContactList(updatedList);
        setChatName(getChatName());
        if(isAddMemberMode) {
            onCreateChatHandler(updatedList);
        } else {
            onNext();
        }
    }

    const onCloseRemoveConfirmationModal = () => {
        setConfirmationRemoveMemberPopUpData(null)
    }

    const findItemPresentInEmailAndContactList = (accountId: number | string) => {
        return false;
    }

    const removeContact = (memberId: number, memberName: string) => {
        if (chatDetails?.group?.data?.guid) {
            setTempMemberIdForDelete(memberId);
            setConfirmationRemoveMemberPopUpData({ eventId: "", header: "Remove Member", message: `Are you sure you want to remove ${memberName}?`, type: "chat" })
        } else {
            const updatedList = selectedContactList.filter(item => item.id !== memberId);
            setSelectedContactList(updatedList);
        }
    } 

    const onConfirmRemoveMember = () => {
        if(tempMemberIdForDelete) {
            removeMemberApiCall?.(tempMemberIdForDelete.toString());
            const updatedList = selectedContactList.filter(item => item.id !== tempMemberIdForDelete);
            setSelectedContactList(updatedList);
        }
        onCloseRemoveConfirmationModal();
    }

    const onClickAddMemberButton = () => {
        setStep(2);
        addMemberForChatClick?.(chatDetails)
    }

    const onPrev = () => {
        if (step === 3) {
            setSelectedContactList(prevState => {
                return prevState.filter(contact => !removedContactList.find(item => item.id === contact.id))
            })
            setRemovedContactList([]);
        }
        setStep(step - 1);
    }

    const onNext = () => {
        setStep(step + 1);
    }

    const getChatName = () => {
        let chat_name = "";
        if(isEditMode && chatDetails){
           return chatDetails.group.data.name;
        }
        return chat_name;
    }

    const onCreateChatHandler = async (contactMembers?: ContactList[]) => {
        const userData = await helper.getUserData();
        let postData: any = {};
        const finalSelectedContactList = contactMembers?.length ? [ ...contactMembers] : selectedContactList.filter((contact) => !removedContactList.find(item => item.id === contact.id))
        if (userData) {
            postData = {
                name: chatName.trim(),
                type: "public",
                members: {
                    admins: [userData.id],
                    participants: finalSelectedContactList.map(contact=> contact.id)
                }
            }
            if(selectedData && (type === "appointments" || (isFromAppointmentOrEvent && type !== "events"))){
                postData.appointment_id = (selectedData as IAppointmentData).id
            } else if(selectedData && (type === "events" || (isFromAppointmentOrEvent && type !== "appointments"))){
                postData.event_id = (selectedData as IAppointmentData).id
            }
        }
        onCreateChat(postData);
    }

    useEffect(() => {
        if(isFromAppointmentOrEvent && appointmentOrEventDetail && !isEditMode){
            setStep(2);
            setSelectedData(appointmentOrEventDetail)
        }

        if(isEditMode && appointmentOrEventDetail){
            setSelectedData(appointmentOrEventDetail);
        }

        if(isEditMode && defaultStep){
            setStep(defaultStep);
        }

        if(isEditMode && selectedMembers){
            setSelectedContactList(selectedMembers);
        }

        if(isEditMode && chatDetails){
            setChatName(chatDetails.group.data.name)
        }

    }, [selectedMembers])

    const AddChat = () => {
        return (
            <Dialog
                open={true}
                PaperProps={{
                    style: {
                        background: "transparent"
                    }
                }}
            >
                <StyledAddChat className="GroupChat_StyledAddChat">
                    <div className="header">
                        {!isEditMode && <img className="back" src={LessIcon} onClick={onPrev} />}
                        <p>{isEditMode ? "Update Chat" : "Add Chat"}</p>
                        <img className="close" src={closeIcon} alt="close.svg" onClick={onClose} />
                    </div>
                    {
                        selectedData && (
                            <div className="body">
                                {
                                    !selectedData?.attributes?.image_url && (
                                        <div className="chat-image">
                                            {helper.getInitials(chatName)}
                                        </div>
                                    )
                                }
                                {
                                    selectedData?.attributes?.image_url && (
                                        <div className="chat-image">
                                            <img src={selectedData.attributes.image_url} />
                                        </div>
                                    )
                                }
                                <div className="chat-name-field">
                                    <InputText
                                        value={chatName}
                                        onChange={(event) => setChatName(event.target.value)}
                                        label="Chat Name*"
                                        type="text"
                                        maxLength={42}
                                    />
                                </div>
                                <div className="invited-person-list">
                                    <div>
                                        <p className="invited-text">Invited</p>
                                        {
                                            isGroupOwner && (
                                                <Button
                                                    text="Add Members"
                                                    icon={AddIcon}
                                                    type="link"
                                                    buttonStyles={{
                                                        width: "fit-content",
                                                        padding: 0,
                                                        fontSize: "0.75rem",
                                                        marginTop: "1rem"
                                                    }}
                                                    onClick={onClickAddMemberButton}
                                                />
                                            )
                                        }
                                    </div>
                                    <ul>
                                        {
                                            selectedContactList.map(contact => {
                                                return (
                                                    <li key={contact.id}>
                                                        <div className="left">
                                                            <div className="avatar-text">
                                                                {contact.name[0]}
                                                            </div>
                                                            <p className="capital-text">{contact.name}</p>
                                                        </div>
                                                        <div className="right">
                                                            <Tooltip title="Remove Member" placement="top">
                                                                <img id="remove-member-icon" onClick={() => removeContact(contact.id, contact.name)} src={RedClose} alt="remove-member" />
                                                            </Tooltip>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        )
                    }
                    <div className="footer">
                        <Button
                            text={isEditMode ? "Update": "Create"}
                            disabled={!chatName.trim()}
                            buttonStyles={{
                                background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
                                width: "100%"
                            }}
                            onClick={() => onCreateChatHandler()}
                        />
                    </div>
                </StyledAddChat>
            </Dialog>
        )
    }

    return (
        <>
            {
                step === 1 && (
                    <SelectAppointmentAndEvent
                        onClose={onClose}
                        appointments={appointments}
                        onSelectData={(event) => {
                            setSelectedData(event);
                            fetchAppointmentEventContact?.(event);
                            onNext();
                        }}
                        onSearch={onSearch}
                        events={events}
                        type={type}
                    />
                )
            }
            {
                step === 2 && (
                    <SelectChatContacts
                        open={true}
                        onClose={onClose}
                        contactsList={contactList}
                        filterContacts={selectedContactList}
                        onSearch={onSearchContactHandler}
                        onAdd={onAddContacts}
                        alreadySelected={findItemPresentInEmailAndContactList}
                        enableBack={(isFromAppointmentOrEvent || isAddMemberMode) ? false : true}
                        onClickBack={onPrev}
                        header="Select Member"
                        loading={isLoadingContacts}
                        fetchRemainingContacts={(event)=>{
                            setFetchRegisteredContactsCallBack(event);
                            if(fetchRegisteredContacts){
                                fetchRegisteredContacts(callBack)
                            }
                        }}
                        hasMoreRegisteredContacts={registeredContactsPagination && registeredContactsPagination.hasMore}
                    />
                )
            }
            {
                step === 3 && (
                    AddChat()
                )
            }
            <ConfirmationPopUp
                open={Boolean(confirmationRemoveMemberPopUpData)}
                header={confirmationRemoveMemberPopUpData?.header as string}
                message={confirmationRemoveMemberPopUpData?.message as string}
                onAccept={onConfirmRemoveMember}
                onReject={onCloseRemoveConfirmationModal}
                onClose={onCloseRemoveConfirmationModal}
            />
        </>
    )
}

const StyledAddChat = styled("div")({
    "&.GroupChat_StyledAddChat": {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#23404B",
        width: "26.25rem",
        height: "34.3125rem",
        boxShadow: "0px 0px 20px 0px #00000040",
        borderRadius: "1rem",
        "& > .header": {
            position: "relative",
            padding: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "1px solid #2d4953",

            "& p": {
                margin: 0,
                fontWeight: 500,
                color: "white",
                fontSize: "1.125rem"
            },
            "& img.close": {
                cursor: "pointer",
                position: "absolute",
                right: "1rem",
                top: "50%",
                transform: "translate(-50%,-50%)",
            },
            "& img.back": {
                cursor: "pointer",
                position: "absolute",
                left: "1rem",
                top: "50%",
                transform: "translate(50%,-50%)",
            }
        },
        "& > .body": {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflow: "auto",
            gap: "0.5rem",
            padding: "1rem",
            "& > .chat-image": {
                width: "6.5rem",
                height: "6.5625rem",
                background: "rgba(0, 0, 0, 0.2)",
                borderRadius: "1rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textTransform: "uppercase",
                alignSelf: "center",
                fontSize: "2.5rem",
                fontWeight: 400,
                color: "rgba(255, 255, 255, 0.8)",
                marginBottom: "1rem",
                flexShrink: 0,
                overflow: "hidden",
                "& > img":{
                    width: "100%",
                    height: "100%"
                }
            },
            "& > .invited-person-list": {
                "& .invited-text": {
                    fontSize: "0.75rem",
                    fontWeight: 500,
                    color: "rgba(255, 255, 255, 1)",
                    marginTop: "1rem"
                },
                "& > div": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                },
                "& > ul": {
                    listStyle: "none",
                    padding: 0,
                    "& > li": {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        height: "2.75rem",
                        "& > .left": {
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                            "& > p": {
                                fontSize: "0.875rem",
                                fontWeight: 400,
                                color: "rgba(255, 255, 255, 1)"
                            }
                        },
                        "& > .right > img": {
                            cursor: "pointer",
                            width: "15px",
                            height: "15px",
                        }
                    }
                }
            }
        },
        "& .footer": {
            padding: "1rem",
        },
    }
})

export default StartNewChatForm;