import React, { useState, useEffect } from "react";
import { Menu, Slider } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { StylesProvider, createGenerateClassName, ThemeProvider, createTheme } from '@material-ui/core/styles';
import InputText from "../../../../components/src/TextInput.web";
import MapLocation from "../../../../components/src/MapLocation.web";
import { SearchLocation, IInterest } from "../../../../components/src/CommonTypes";
import MapMenu from "../../../../components/src/MapMenu.web";
import { LatLngExpression } from 'leaflet';
import { Chip, SelectInterest } from "../../../../components/src";
interface DetectLocationProps {
    anchorEle: HTMLButtonElement | null;
    onClose:()=>void;
    onSearchLocation?:(event: string)=>void;
    predictions?: SearchLocation[];
    recentLocations?: SearchLocation[];
    filter?: {location?: SearchLocation | null, position?: LatLngExpression | null, radius?: number};
    onApplyFilter?: (event:{location: SearchLocation | null, position: LatLngExpression | null, radius?: number})=>void;
    onNearByLocationSelectGetCoordinates?: (place: string) => void;
    selectedNearByLocationCoordinates?: LatLngExpression | null;
    isSelectInterestVisible?: boolean;
    selectedInterest?: IInterest[];
    currentSelectedInterest?: IInterest[];
    interestList?: IInterest[];
    onSubmitNewInterest?: (event: string)=>{}
    onAddInterest?: (event: IInterest[])=> void;
}

const generateClassName = createGenerateClassName({
    productionPrefix: 'DetectLocation',  // Customize the prefix (use meaningful names)
});

const theme = createTheme(); 

const DetectLocation: React.FC<DetectLocationProps> = (props: DetectLocationProps) => {
    const { 
        anchorEle, 
        onClose, 
        onSearchLocation, 
        predictions, 
        recentLocations, 
        filter, 
        onApplyFilter, 
        onNearByLocationSelectGetCoordinates, 
        selectedNearByLocationCoordinates,
        selectedInterest,
        isSelectInterestVisible,
        interestList,
        onSubmitNewInterest,
        onAddInterest,
        currentSelectedInterest
    } = props;
    const [selectedLocation, setSelectedLocation] = useState<SearchLocation | null>(filter?.location ? filter.location : null)
    const [mapMenuAnchorEle, setAnchorEle] = useState<HTMLDivElement | null>(null)
    const [radius, setRadius] = useState<number>(filter?.radius ? filter.radius : 0)
    const [selectedMapLocationPosition, setSelectedMapLocationPosition] = useState<LatLngExpression | null>(null)
    const [currentInterests, setInterests] = useState<IInterest[]>([]);
    const [selectedInterests, setSelectedInterests] = useState<IInterest[]>([]);
    const [isInterestModalOpen, setInterestModal] = useState<boolean>(false);

    const openMapMenu = (event: React.MouseEvent<HTMLDivElement>)=>{
       setAnchorEle(event.currentTarget)
    }
    const onSelectLocationHandler = (event: SearchLocation)=>{
        setSelectedLocation(event);
        onNearByLocationSelectGetCoordinates?.(event?.structured_formatting?.main_text);
        setAnchorEle(null);
    }
    const onApplyFilterHandler = ()=>{
      onApplyFilter?.({ location: selectedLocation, position: selectedMapLocationPosition, radius: radius })
      if(onAddInterest){
        onAddInterest(selectedInterests);
      }
    }

    const onChangeMapLocation = (position: LatLngExpression) => {
        setSelectedMapLocationPosition(position)
    }

    const onResetFilter = ()=>{
        onApplyFilter?.({ location: null, position: null, radius: 0 })
        setSelectedMapLocationPosition(null)
    }

    const removeInterest = (interestId: string)=>{
        setSelectedInterests(prevState =>{
            return prevState.filter(interest=> interest.id !== interestId)
        })
    }

    const openInterestsModal = ()=>{
        setInterestModal(true)
        setInterests(selectedInterests);
    }

    useEffect(()=>{
      setSelectedLocation(filter?.location ? filter.location : null)
      setRadius(filter?.radius ? filter.radius : 0);
    },[filter]);

    useEffect(()=>{
        if(selectedNearByLocationCoordinates){
            setSelectedMapLocationPosition(selectedNearByLocationCoordinates)
        }
      },[selectedNearByLocationCoordinates]);
    
    useEffect(() => {
        if (selectedInterest) {
            setSelectedInterests(selectedInterest)
        }
    }, [selectedInterest]);

    useEffect(() => {
        if (currentSelectedInterest) {
            setInterests(currentSelectedInterest)
        }
    }, [currentSelectedInterest]);

    const SelectInterestField = () => {
        return (
            <StyledInput
                className={`DetectLocation_StyledInput ${selectedInterests.length > 0 ? "focused" : ""}`}
                style={{ cursor: "pointer" }}
                onClick={openInterestsModal}
            >
                {
                    selectedInterests.length === 0 && (
                        <label id="open-interest-modal" style={{ cursor: "pointer" }} onClick={openInterestsModal}>Select Interest</label>
                    )
                }
                {
                    selectedInterests.length > 0 && (
                        <label className={"focused"}>Select Interest</label>
                    )
                }
                {
                    selectedInterests && selectedInterests.length > 0 && (
                        <StyledChipList id="selected-interest-list" className="DetectLocation_StyledChipList">
                            <div className="chip-list">
                                {
                                    selectedInterests.slice(0, 3).map(interest => {
                                        return (
                                            <Chip
                                                id={interest.id}
                                                onRemove={removeInterest}
                                                key={interest.id}
                                                displayText={interest.attributes.name}
                                            />
                                        )
                                    })
                                }
                                {
                                    selectedInterests.length > 3 && (
                                        <div className="extra" onClick={openInterestsModal}>+{selectedInterests.length - 3} More</div>
                                    )
                                }
                            </div>
                        </StyledChipList>
                    )
                }
                <div style={{ position: "absolute", right: "1rem", transform: isInterestModalOpen ? "rotate(180deg)" : "rotate(0deg)" }}>
                    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.99953 7.03575L0.345703 1.38195L1.39953 0.328125L5.99953 4.92812L10.5995 0.328125L11.6534 1.38195L5.99953 7.03575Z" fill="white" />
                    </svg>
                </div>
            </StyledInput>
        )
    }

    return (
        <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={theme}>
        <CustomMenu
            open={Boolean(anchorEle)}
            anchorEl={anchorEle}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            getContentAnchorEl={null}
            className="Events_DetectLocation_Menu"
            transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
            }}
            PaperProps={{
                style:{
                    transform: "translateY(0.25rem)",
                }
            }}
            style={{
                zIndex: 2
            }}
        >
            <StyledDetectLocation className="Events_DetectLocation_Container">
                <InputText
                    onChange={()=>{}}
                    value={selectedLocation ? selectedLocation?.structured_formatting?.main_text : ""}
                    label="Choose Nearby Location"
                    type="text"
                    isContainerFocused={true}
                    readonly={true}
                    onClick={(event) => openMapMenu(event as React.MouseEvent<HTMLDivElement>)}
                    style={{ cursor: "pointer" }}
                    onClearInput={() => setSelectedLocation(null)}
                    inputStyles={{ cursor: "pointer" }}
                />
                <MapMenu
                    anchorEle={mapMenuAnchorEle}
                    predictions={predictions ? predictions : []}
                    recentLocations={recentLocations ? recentLocations : []}
                    onClose={()=> setAnchorEle(null)}
                    onSelect={onSelectLocationHandler}
                    onSearch={(event)=> onSearchLocation?.(event)}
                />
                <div className="radius-container">
                    <label>Radius</label>
                    <StyledSlider
                      className="DetectLocation_Slider" 
                      value={radius}
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value)=> `${value}km`}
                      onChange={(event, value)=> setRadius(value as number)}
                     />
                </div>
                <div className="map-container">
                    <MapLocation latLng={selectedMapLocationPosition} onChangeMapLocation={onChangeMapLocation} />
                </div>
                {
                    isSelectInterestVisible && (
                        SelectInterestField()
                    )
                }
                <button disabled={!(selectedMapLocationPosition)} style={{ opacity: !(selectedMapLocationPosition) ? 0.4 : 1 }} className="apply-filter" onClick={onApplyFilterHandler}>Apply Filter</button>
                <div className="reset-btn">
                    <button onClick={onResetFilter}>Reset</button>
                </div>
            </StyledDetectLocation>
            {
                isInterestModalOpen && (
                    <SelectInterest
                        interests={interestList as IInterest[]}
                        currentSelectedInterest={currentInterests}
                        open={isInterestModalOpen}
                        onClose={()=> setInterestModal(false)}
                        setCurrentSelectInterest={(event)=> setInterests(event)}
                        onAddInterests={(event)=> {
                            setSelectedInterests(event);
                            setInterestModal(false);
                        }}
                        onSubmitNewInterest={onSubmitNewInterest}
                    />
                )
            }
        </CustomMenu>
        </ThemeProvider>
        </StylesProvider>
    )
}

const StyledDetectLocation = styled("div")({
    "&.Events_DetectLocation_Container": {
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        border: "1px solid rgba(25, 181, 185, 0.27)",
        borderRadius: "1rem",
        width: "21.4375rem !important",
        padding: "1rem 1rem 1rem",
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        "& .radius-container":{
            padding:"0rem 0.5rem",
            "& label":{
               color: "rgba(242, 242, 242, 1)",
               fontSize: "0.875rem",
               fontWeight: "400"
            }
        },
        "& .map-container":{
            width: "100%",
            height: "10.125rem",
            borderRadius: "1rem",
            overflow: "hidden"
        },
        "& .apply-filter":{
            background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
            borderRadius: "62.5rem",
            color: "rgba(255, 255, 255, 1)",
            fontSize: "1rem",
            fontWeight: "500",
            outline: "none",
            border: "none",
            height: "3rem",
            width: "100%",
            cursor: "pointer"
        },
        "& .reset-btn": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& button": {
                background: "transparent",
                outline: "none",
                border: "none",
                color: "rgba(247, 83, 103, 1)",
                fontSize: "1rem",
                fontWeight: "500",
                cursor: "pointer"
            }
        }
    }
})

const CustomMenu = styled(Menu)({
    "&.Events_DetectLocation_Menu":{
        "& .MuiList-padding": {
            padding: 0,
        },
        "& .MuiPaper-root": {
            background: "transparent"
        }
    }
})

const StyledSlider = styled(Slider)({
    "&.DetectLocation_Slider": {
        "& .MuiSlider-rail": {
            background: "linear-gradient(180deg, #305F71 0%, #19282F 100%) !important",
            height: "0.5rem !important",
            boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.15) !important",
            borderRadius: "62.5rem !important"
        },
        "& .MuiSlider-track": {
            background: "linear-gradient(180deg, #23404B 0%, #19282F 100%) !important",
            boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.15) !important",
            height: "0.5rem",
            borderRadius: "62.5rem !important"
        },
        "& .MuiSlider-thumb":{
            height: "1.5rem",
            width: "1.5rem",
            background: "linear-gradient(90deg, #FF7F77 0%, #FF6376 100%) !important",
            marginTop: "-0.625rem"
        },
        "& .MuiSlider-valueLabel":{
            left: "calc(-30%) !important",
            top: "-40px !important",
            "& > span":{
                background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%) !important",
                padding: "0.2rem !important"
            }
        }
    }
})

const StyledInput = styled("div")({
    "&.DetectLocation_StyledInput": {
        position: "relative",
        padding: "0rem 0.9rem",
        height: "3rem",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        border: "1px solid rgba(28, 150, 154,0.3)",
        transition: "all 0.3s ease-in-out",
        maxHeight: "100%",
        alignItems: "center",
        borderRadius: "100px",
        display: "flex",
        "& input": {
            outline: "none",
            border: "none",
            fontWeight: 400,
            background: "transparent",
            position: "relative",
            zIndex: 1,
            color: "rgba(255, 255, 255, 1)",
            fontSize: "1rem",
        },
        "& label": {
            position: "absolute",
            color: "white",
            fontSize: "1rem",
            transition: "transform 0.2s ease-in-out",
            "&.focused": {
                transform: 'translate(-14px,-38px)'
            }
        },
        "&.focused": {
            marginTop: '1rem !important'
        }
    }
})

const StyledChipList = styled("div")({
    "&.DetectLocation_StyledChipList": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        "& .chip-list": {
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
            "& .extra": {
                color: "rgb(243, 121, 118)",
                fontSize: "0.875rem",
                fontWeight: 500,
                cursor: "pointer",
            }
        },
        "& .add-btn": {
            fontSize: "0.875rem",
            fontWeight: 500,
            color: "rgb(243, 121, 118)",
            cursor: "pointer",   
        }
    }
})

export default DetectLocation;