// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { AppointmentAttributes, ContactList, EventsAttributes, IAppointmentData, IChatData, IEventData, IGroupChat } from "../../../components/src/CommonTypes";
export const configJSON = require("./config.js");
const {
    HelperFunctions: helper,
} = require("../../../components/src/HelperFunctions");
import { 
    getUpcomingAppointments, 
    handleGetUpcomingAppointmentsAPICall,
    searchAppointments,
    createChat,
    getGroupChats,
    getUpcomingEvents,
    handleGetEvents,
    editGroupChat,
    addMembersInGroupChat,
    handleAddMembersInGroupChat,
    handleEditGroupChat,
    deleteGroupChat,
    handleDeleteGroupChat,
    getGroupChatInfo,
    removeMembersInGroupChat,
    handleRemoveMembersFromGroupChat,
} from "./actions";
import { CometChat } from "@cometchat-pro/chat";
import React from "react";
import _ from "lodash";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    activeTab: "all" | "appointments" | "events";
    searchString: string;
    userId: number | undefined;
    selectedGroupChatId: number | string | null;
    selectedGroupChatForEdit: IChatData | null;
    selectedMembersForEdit: ContactList[];
    selectedStartNewChatForm: "appointments" | "events" | "";
    openStartNewChatForm: boolean;
    contactList: ContactList[];
    phoneBookContactList: ContactList[];
    fullContactList: ContactList[],
    fullPhoneBookContactList: ContactList[],
    appointments: IAppointmentData[];
    groupChats: IGroupChat[];
    events: IEventData[];
    openSelectChatTypeDialog: boolean;
    isEditMode: boolean;
    isAddMembersMode: boolean;
    updateChildComponent?: any;
    registeredContactsPagination: {
        per_page: number,
        page_no: number,
        hasMore: boolean
    }
    callback?: any;
    isLoadingContacts: boolean;
    pageNoChats: number;
    isAllChatsLoaded: boolean;
    isChatsLoading: boolean;
    isAddMemberModeFromEditScreen: boolean;
    selectedGroupChatForAddMemberEdit: IChatData | null;
    confirmationRemoveMemberFromChatPopUpData: {
        header: string;
        message: string;
        type: string;
        guid: string;
    } | null;
    tempMemberIdToRemove: number;
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class GroupChatController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getUpcomingAppointmentsApiCallId: string = "";
    createChatApiCallId:string = "";
    getGroupChatsApiCallId: string = "";
    getEventsApiCallId: string = "";
    addMembersChatApiCallId: string = "";
    removeMemberChatApiCallId: string = "";
    editChatApiCallId: string = "";
    deleteChatGroupApiCallId: string = "";
    bottomRef: React.RefObject<HTMLDivElement>;
    observer: IntersectionObserver | null = null
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            userId: undefined,
            activeTab: "all",
            searchString: "",
            selectedGroupChatId: "",
            openStartNewChatForm: false,
            fullContactList: [],
            fullPhoneBookContactList: [],
            contactList: [],
            phoneBookContactList: [],
            appointments: [],
            groupChats: [],
            events: [],
            selectedStartNewChatForm: "",
            openSelectChatTypeDialog: false,
            selectedGroupChatForEdit: null,
            isEditMode: false,
            selectedMembersForEdit: [],
            isAddMembersMode: false,
            registeredContactsPagination: {
                per_page: 50,
                page_no: 1,
                hasMore: true
            },
            isLoadingContacts: false,
            isAllChatsLoaded: false,
            pageNoChats: 1,
            isChatsLoading: false,
            isAddMemberModeFromEditScreen: false,
            selectedGroupChatForAddMemberEdit: null,
            confirmationRemoveMemberFromChatPopUpData: null,
            tempMemberIdToRemove: 0,
            // Customizable Area End
        };

        // Customizable Area Start
        this.bottomRef = React.createRef();
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.getToken();
        // Customizable Area Start
        const searchParams = new URLSearchParams(this.props.navigation.history.location.search)
        const guid = searchParams.get("id");
        if(guid){
            this.setState({
                selectedGroupChatId: guid
            })
        }
        this.observer = new IntersectionObserver(
            this.handleObserver,
            { root: null, threshold: 1.0 }
          );
      
        if (this.bottomRef.current) {
            this.observer.observe(this.bottomRef.current);
        }
        // Customizable Area End
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        const searchParams = new URLSearchParams(this.props.navigation.history.location.search)
        const guid = searchParams.get("id");
        if(prevState.selectedGroupChatId !== guid){
            this.setState({
                selectedGroupChatId: guid
            })
        }
        if (!prevState.groupChats.length && this.state.groupChats.length) {
            this.initObserver();
          }
        if (prevState.groupChats !== this.state.groupChats && this.bottomRef.current && this.observer) {
            this.observer.observe(this.bottomRef.current);
        }
    }

    async componentWillUnmount(): Promise<void> {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    getToken = async () => {
        const token = await helper.getStorageData("authToken");
        const userData = await helper.getUserData();
        if (userData) {
            this.setState({ userId: userData.id });
        }
        this.setState({
            token: token
        },()=>{
            this.getChatGroups();
            this.getUpcomingAppointments();
            this.getEvents();
        })
    };

    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            switch (webApiRequestCallId) {
                case this.getUpcomingAppointmentsApiCallId:
                    this.handleGetUpcomingAppointmentsApiCall(message);
                    break;
                case this.createChatApiCallId:
                    this.onCreateChatApiHandler(message);
                    break;
                case this.getGroupChatsApiCallId:
                    this.handleGetChatGroupsApiCall(message);
                    break;
                case this.getEventsApiCallId:
                    this.handleGetEventApiCall(message);
                    break;
                case this.editChatApiCallId:
                    this.handleEditGroupChatApiCall(message);
                    break;
                case this.addMembersChatApiCallId:
                    this.handleAddMemberApiCall(message);
                    break;
                case this.removeMemberChatApiCallId:
                    this.handleRemoveMemberApiCall(message);
                    break;
                case this.deleteChatGroupApiCallId:
                    this.handleDeleteGroupChat(message);
                    break;
            }
        }
        // Customizable Area End
    };

    // Customizable Area Start
    redirectTo = (endpoint: string, params?: { [key: string]: string | number }) => {
        this.props.navigation.navigate(endpoint, params)
    }

    handleGoToAppointmentOrEvent = (itemId: number | string | undefined, type: string) => {
        if (itemId) {
            if (type === "appointment") {
                this.redirectTo("AppointmentDetails", { id: itemId })
            } else {
                this.redirectTo("EventDetail", { eventId: itemId })
            }
        }
    }

    changeTab = (newTab: "all" | "appointments" | "events") => {
        this.setState({
            activeTab: newTab,
            pageNoChats: 1,
            isAllChatsLoaded: false,
            searchString: "",
        }, () => {
            this.getChatGroups();
        })
    }

    goToChatView = (chatId: string) => {
        this.props.navigation.history.push(`/chats?id=${chatId}`)
        this.setState({
            selectedGroupChatId: chatId
        })
    }

    onChangeHandler = (value: string) => {
        this.setState({
            searchString: value,
            pageNoChats: 1,
            isAllChatsLoaded: false,
        }, () => {
            this.getChatGroups();
        })
    }

    initObserver = () => {
        this.observer = new IntersectionObserver(this.handleObserver, {
            threshold: 0.5, // Trigger when fully in view
        });

        if (this.bottomRef.current) {
            this.observer.observe(this.bottomRef.current);
        }
    };

    handleObserver = (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries;
        if (entry.isIntersecting && !this.state.isAllChatsLoaded) {
            this.loadMoreItems();
        }
    };

    loadMoreItems = () => {
        const { isChatsLoading } = this.state;
        if (isChatsLoading) return;
        this.setState({ isChatsLoading: true, pageNoChats: this.state.pageNoChats + 1 }, () => {
            this.getChatGroups();
        });
    };

    openStartNewChatForm = ()=>{
        const {
          activeTab
        } = this.state;
        if(activeTab !== "all"){
            this.setState({
                openStartNewChatForm: true,
                selectedStartNewChatForm: activeTab
            })
        }else{
            this.setState({
                openSelectChatTypeDialog: true
            })
        }
    }

    closeStartNewChatForm = ()=>{
        this.setState({
            openStartNewChatForm: false,
            selectedGroupChatForEdit: null,
            isEditMode: false,
            isAddMembersMode: false,
            selectedMembersForEdit: [],
            contactList: [],
            phoneBookContactList: [],
            registeredContactsPagination: {
                per_page: 50,
                page_no: 1,
                hasMore: true
            }
        })
    }

    onSelectChatType = (event: "appointments" | "events")=>{
        this.setState({
            selectedStartNewChatForm: event,
            openStartNewChatForm: true,
            openSelectChatTypeDialog: false
        })
    }

    closeSelectChatTypeModal = ()=>{
        this.setState({
            openSelectChatTypeDialog: false,
            contactList: [],
            phoneBookContactList: [],
            fullContactList: [],
            fullPhoneBookContactList: [],
            registeredContactsPagination: {
                per_page: 50,
                page_no: 1,
                hasMore: true
            }
        })
    }

    setContactsListOnEditMode = (type: "appointments" | "events", appointmentOrEventId: string) => {
        let selectedAppointmentOrEvent: { id: string, type?: string, attributes?: AppointmentAttributes | EventsAttributes | null } | null = null;
        if (type === "appointments") {
            selectedAppointmentOrEvent = this.state.appointments.find((item) => parseInt(item.id) === parseInt(appointmentOrEventId)) as { id: string, type?: string, attributes?: AppointmentAttributes | EventsAttributes | null }
        } else {
            selectedAppointmentOrEvent = this.state.events.find((item) => parseInt(item.id) === parseInt(appointmentOrEventId)) as { id: string, type?: string, attributes?: AppointmentAttributes | EventsAttributes | null }
        }
        if (selectedAppointmentOrEvent) {
            this.onSelectAppointmentEventSetContactsList(selectedAppointmentOrEvent)
        } else {
            this.closeStartNewChatForm();
            if (type === "appointments") {
                helper.showErrorToast("The appointment for this chat has expired.")
            } else {
                helper.showErrorToast("The event for this chat has expired.")
            }
        }
    }

    onSelectAppointmentEventSetContactsList = (appointmentOrEvent: {id: string, type?: string, attributes?: AppointmentAttributes | EventsAttributes | null}) => {
        const registeredContacts = appointmentOrEvent.attributes?.user_contacts?.filter((item) => !item.organizer).map((registeredContact) => {
            return {
                id: registeredContact.id,
                name: registeredContact.name,
                email: "",
                full_phone_number: registeredContact.full_phone_number,
            }
        }) as ContactList[];
        const emailAssignees = appointmentOrEvent.attributes?.assignee_email?.filter(item => helper.isValidEmail(item?.email)).map((registeredEmail) => {
            return {
                id: registeredEmail.id,
                name: registeredEmail.full_name || "",
                email: registeredEmail.email,
                full_phone_number: "",
            }
        }) as ContactList[];
        this.setState({
            contactList: [...registeredContacts, ...emailAssignees],
            phoneBookContactList: [],
            fullContactList: registeredContacts,
            fullPhoneBookContactList: []
        })
    }

    onSearchContact = async (contact: string) => {
        this.setState({ contactList: [], phoneBookContactList: [], isLoadingContacts: true })
        if (contact) {
            const searchedRegisteredContacts = this.state.fullContactList.filter((contactItem) => {
                return (
                    contactItem.name?.toLowerCase()?.includes(contact.toLowerCase()) ||
                    contactItem.full_phone_number?.includes(contact)
                );
            })
            const searchedPhonebookContacts = this.state.fullPhoneBookContactList.filter((phonebookContact) => {
                return (
                    phonebookContact.name?.toLowerCase()?.includes(contact.toLowerCase()) ||
                    phonebookContact.full_phone_number?.includes(contact)
                );
            })
            this.setState({ contactList: searchedRegisteredContacts, phoneBookContactList: searchedPhonebookContacts }, () => {
                this.setState({ isLoadingContacts: false })
            })
        } else {
            this.setState({
                registeredContactsPagination: {
                    per_page: 50,
                    page_no: 1,
                    hasMore: true
                },
                contactList: this.state.fullContactList,
                phoneBookContactList: this.state.fullPhoneBookContactList
            }, () => {
                this.setState({ isLoadingContacts: false })
            })
        }
    }

    getUpcomingAppointments = async ()=>{
        this.getUpcomingAppointmentsApiCallId = await getUpcomingAppointments({
            token: this.state.token,
            page_no: 1,
            per_page: 10
        })
    }

    handleGetUpcomingAppointmentsApiCall = (message: Message)=>{
        const response = handleGetUpcomingAppointmentsAPICall(message);
        this.setState({
            appointments: response.data
        })
    }

    onSearchAppointments = async (query: string)=>{
        if(query){
            this.getUpcomingAppointmentsApiCallId = await searchAppointments({
                token: this.state.token,
                query
            })
        }else{
            this.getUpcomingAppointments();
        }
    }

    onCreateChat = async (body: any) => {
        const {
            selectedStartNewChatForm,
            isEditMode,
            selectedGroupChatForEdit,
            isAddMembersMode
        } = this.state;

        if ((selectedStartNewChatForm === "appointments" || selectedStartNewChatForm === "events") && !isEditMode && !isAddMembersMode) {
            this.closeStartNewChatForm();
            helper.showLoader();

            this.createChatApiCallId = await createChat({
                token: this.state.token,
                body
            })
        } else if(selectedGroupChatForEdit){
            if(selectedGroupChatForEdit.group.data.name !== body.name && isEditMode){
                helper.showLoader();
                this.editChatApiCallId = await editGroupChat({
                    token: this.state.token,
                    body: {
                        guid: selectedGroupChatForEdit.group.data.guid,
                        name: body.name,
                        type: "public"
                    }
                })
            }

            if(isAddMembersMode){
                helper.showLoader();
                this.addMembersChatApiCallId = await addMembersInGroupChat({
                    token: this.state.token,
                    body: {
                        guid: selectedGroupChatForEdit.group.data.guid,
                        members: body.members.participants
                    }
                })
            }
            this.closeStartNewChatForm();
        }
    }

    onClickRemoveMemberFromInfoScreen = (memberId: number, memberName: string, guid: string) => {
        this.setState({confirmationRemoveMemberFromChatPopUpData:{ guid: guid, header: "Remove Member", message: `Are you sure you want to remove ${memberName}?`, type: "chat" }, tempMemberIdToRemove: memberId })
    }

    onCloseRemoveMemberConfirmationModal = () => {
        this.setState({ confirmationRemoveMemberFromChatPopUpData: null, tempMemberIdToRemove: 0 })
    }

    onConfirmRemoveMemberFromChat = () => {
        this.removeExistingMemberFromChat(this.state.tempMemberIdToRemove.toString())
        this.onCloseRemoveMemberConfirmationModal();
    }

    removeExistingMemberFromChat = async(memberId: string) => {
        const { selectedGroupChatForEdit, confirmationRemoveMemberFromChatPopUpData } = this.state;
        this.removeMemberChatApiCallId = await removeMembersInGroupChat({
            token: this.state.token,
            body: {
                guid: confirmationRemoveMemberFromChatPopUpData?.guid ? confirmationRemoveMemberFromChatPopUpData.guid : selectedGroupChatForEdit?.group.data.guid,
                uid: memberId
            }
        })
    }

    handleRemoveMemberApiCall = async(message: Message)=>{
        const removeMemberResponse = handleRemoveMembersFromGroupChat(message);
        if(removeMemberResponse){
            helper.showSuccessToast(removeMemberResponse.message)
            this.state.updateChildComponent?.()
        } else{
            helper.showErrorToast("Something went wrong! Please try again")
        }
    }

    onCreateChatApiHandler = (message: Message)=>{
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.comet_response && responseJson.comet_response.data) {
            const chatEvent: EventsAttributes = { ...responseJson?.event, name: responseJson?.event?.title }
            const addedGroupChat: IGroupChat = {
                ...responseJson.comet_response.data,
                guid: [responseJson.comet_response.data?.guid],
                appointment: responseJson?.appointment || null,
                event: chatEvent || null,
            }
            helper.showSuccessToast(responseJson.message)
            this.setState({ groupChats: [{ ...addedGroupChat }, ...this.state.groupChats] })
        } else if (responseJson && responseJson.errors) {
            helper.showErrorToast(responseJson.errors)
        } else {
            helper.showErrorToast("Something went wrong please try again!")
        }
        helper.hideLoader();
    }

    getChatGroups = async () => {
        this.setState({ isChatsLoading: true })
        this.getGroupChatsApiCallId = await getGroupChats({
            token: this.state.token
        }, this.state.activeTab, this.state.searchString, this.state.pageNoChats)
    }

    handleGetChatGroupsApiCall = (message: Message)=>{
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (!responseJson?.pagination?.next_page) {
            this.setState({ isAllChatsLoaded: true });
        }
        if (responseJson?.chats) {
            const filteredData = responseJson.chats?.filter((item: any) => item.name)
            if (this.state.pageNoChats === 1) {
                this.setState({
                    groupChats: _.uniqBy([...filteredData], "guid")
                })
            } else {
                this.setState({
                    groupChats: _.uniqBy([...this.state.groupChats, ...filteredData], "guid")
                })
            }

        }
        this.setState({ isChatsLoading: false })
    }

    getEvents = async ()=>{
        this.getEventsApiCallId = await getUpcomingEvents({
            token: this.state.token
        })
    }

    handleGetEventApiCall = (message: Message)=>{
        const response = handleGetEvents(message);
        this.setState({
            events: response.data
        })
    }

    onClickAddMemberButtomFromEditScreen = (chatData: IChatData | undefined | null) => {
        this.setState({ isAddMemberModeFromEditScreen: true, isAddMembersMode: true, isEditMode: false })
        if(chatData) {
            this.setState({ selectedGroupChatForAddMemberEdit: chatData })
        }
    }

    onClickAddMemberButton = (event: any)=>{
        if(event && event.chatData){
            this.setState({
                selectedGroupChatForEdit: event.chatData
            }, () => {
                this.setContactsListOnEditMode(this.state.selectedGroupChatForEdit?.appointment? "appointments" : "events", this.state.selectedGroupChatForEdit?.appointment?.id as string || this.state.selectedGroupChatForEdit?.event?.id as string)
            })
            if(event.groupMembers){
                const memberList: ContactList[] = [];
                event.groupMembers.forEach((groupMember: CometChat.GroupMember)=> {
                    if(groupMember.getScope() !== "admin"){
                        const contact: ContactList = {
                            id: parseInt(groupMember.getUid()),
                            name: groupMember.getName(),
                            email: "",
                            full_phone_number: ""
                        }
                        memberList.push(contact);
                    }
                })
                
                this.setState({
                    selectedMembersForEdit: memberList,
                    isAddMembersMode: true
                })
            }
        }
    }

    onClickEditButton = (event: any)=>{
        if(event && event.chatData){
            this.setState({
                selectedGroupChatForEdit: event.chatData,
                isEditMode: true
            }, () => {
                this.setContactsListOnEditMode(this.state.selectedGroupChatForEdit?.appointment? "appointments" : "events", this.state.selectedGroupChatForEdit?.appointment?.id as string || this.state.selectedGroupChatForEdit?.event?.id as string)
            })
            if(event.groupMembers){
                const contactList: ContactList[] = [];
                event.groupMembers.forEach((member: CometChat.GroupMember)=> {
                    if(member.getScope() !== "admin"){
                        const contact: ContactList = {
                            id: parseInt(member.getUid()),
                            name: member.getName(),
                            email: "",
                            full_phone_number: ""
                        }
                        contactList.push(contact);
                    }
                })
                
                this.setState({
                    selectedMembersForEdit: contactList,
                    isAddMembersMode: false
                })
            }
        }
    }

    handleAddMemberApiCall = async(message: Message)=>{
        const addMemberResponse = handleAddMembersInGroupChat(message);
        if(addMemberResponse){
            helper.showSuccessToast(addMemberResponse.message)
            this.state.updateChildComponent?.()
        } else{
            helper.showErrorToast("Something went wrong! Please try again")
        }
        if(this.state.isAddMemberModeFromEditScreen && this.state.selectedGroupChatForAddMemberEdit) {
            const groupInfo = await getGroupChatInfo(this.state.selectedGroupChatForAddMemberEdit.group.data.guid);
            this.onClickEditButton({ chatData: this.state.selectedGroupChatForAddMemberEdit, groupMembers: groupInfo?.allMembers });
            this.setState({ isAddMemberModeFromEditScreen: false })
        }
        helper.hideLoader();
    }

    handleEditGroupChatApiCall = (message: Message)=>{
        const response = handleEditGroupChat(message);
        if(response){
            helper.showSuccessToast(response.message);
            const updatedChatList = this.state.groupChats.map((item) => {
                if(item.guid[0] === response?.comet_response?.data?.guid){
                  item.name = response?.comet_response?.data?.name;
                }
                return item;
            })
            this.setState({ groupChats: updatedChatList })
            this.state.updateChildComponent?.()
        }else{
            helper.showErrorToast("Something went wrong! Please try again")
        }
        helper.hideLoader();
    }

    removeChatIdFromUrl = () => {
        const url = new URL(window.location.href);
        url.searchParams.delete('id');
        window.history.pushState(null, '', url.toString());
    };

    deleteGroupChat = async (guid: string)=>{
        helper.showLoader();
        this.deleteChatGroupApiCallId = await deleteGroupChat({
            token: this.state.token,
            body: {
                guid: [guid]
            }
        })
    }

    handleDeleteGroupChat = (message: Message)=>{
        const response = handleDeleteGroupChat(message);
        if(response && response.message){
            helper.showSuccessToast(response.message)
            const chatList = this.state.groupChats.filter((item) => item.guid[0] !== this.state.selectedGroupChatId);
            this.props.navigation.history.push("/chats");
            this.removeChatIdFromUrl();
            this.setState({ groupChats: chatList })
        } else{
            helper.showErrorToast("Something went wrong! please try again")
        }
        helper.hideLoader();
    }

    registerUpdateCallBack = (callback: any)=>{
        this.setState({
            updateChildComponent: callback
        })
    }

    // Customizable Area End
}