// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
import { getStorageData } from "../../../framework/src/Utilities";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
import { IComment, IMentionInputType, IMetaPagination, IPost } from "../../../components/src/CommonTypes";
import _ from "lodash";
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    postDetail: IPost | null;
    postId: string;
    commentId: string;
    typeOfPost: "event" | "group" | "";
    typeId: string;
    currentLikedPostId: string;
    currentPostLikedUserList: { id: string | number, attributes: { like_by_name: string } }[] | undefined;
    currentCommentModal: {
        id: string;
    } | null;
    postCommentsCurrentPage: number;
    currentPostCommentsList: IComment[];
    isLoadingComments: boolean;
    isAllCommentsFetched: boolean;
    currentPostCommentMetaData: IMetaPagination | null;
    commentMentionUsersList: IMentionInputType[];
    currentSelectedCommentId: string | number;
    userId: number | undefined;
    currentLikedCommentId: number | string;
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class UpcomingEventsListController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getPostDetailApiCallId: string = "";
    likeCurrentPostApiCallId: string = "";
    getCurrentPostCommentsApiCallId: string = "";
    createCommentApiCallId: string = "";
    deleteCommentApiCallId: string = "";
    currentPostLikedUsersListApiCallId: string = "";
    getMentionedUsersApiCallId: string = "";
    postCommentLikeDislikeApiCallId: string = "";
    postCommentLikedUsersListApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            commentId: "",
            postDetail: null,
            postId: "",
            typeId: "",
            typeOfPost: "",
            postCommentsCurrentPage: 1,
            currentCommentModal: null,
            currentLikedPostId: "",
            currentPostCommentsList: [],
            currentPostLikedUserList: undefined,
            isAllCommentsFetched: false,
            isLoadingComments: false,
            commentMentionUsersList: [],
            currentPostCommentMetaData: null,
            currentSelectedCommentId: "",
            userId: undefined,
            currentLikedCommentId: "",
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.getToken();
        // Customizable Area Start
        this.getPostDetail();
        (async () => {
            const userData = await helper.getUserData();
            if (userData) {
              this.setState({ userId: userData.id });
            }
          })();
        // Customizable Area End
    }

    getToken = () => {
        const message: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(message);
    };

    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = await getStorageData("authToken");
            this.setState({ token });
        }

        const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            switch (apiCallId) {
                case this.getPostDetailApiCallId:
                    this.handleGetPostDetailCall(message);
                    break;
                case this.currentPostLikedUsersListApiCallId:
                    this.handleGetPostLikedUsersListCall(message);
                    break;
                case this.likeCurrentPostApiCallId:
                    this.likePostApiCallHandler(message);
                    break;
                case this.getCurrentPostCommentsApiCallId:
                    this.handleGetCommentsListApiCall(message);
                    break;
                case this.createCommentApiCallId:
                    this.handleAddPostCommentApiCall(message);
                    break;
                case this.deleteCommentApiCallId:
                    this.handleDeletePostCommentApiCall(message);
                    break;
                case this.getMentionedUsersApiCallId:
                    this.handleGetCommentMentionsListCall(message);
                    break;
                case this.postCommentLikeDislikeApiCallId:
                    this.likePostCommentApiCallHandler(message);
                    break;
                case this.postCommentLikedUsersListApiCallId:
                    this.handleGetPostCommentLikedUsersListCall(message);
                    break;
            }
        }
        // Customizable Area End
    };

    // Customizable Area Start

    handleHidePostCommentsSection = () => {
        const { postDetail } = this.state;
        if (postDetail) {
            const updatedPost = { ...postDetail };
            updatedPost.attributes.show_comments_section = false;
            this.setState({ postDetail: updatedPost, currentPostCommentsList: [], postCommentsCurrentPage: 1, currentPostCommentMetaData: null })
        }
    }

    handleOpenPostCommentsSection = async () => {
        const { postDetail, postId } = this.state;
        if (postDetail) {
            const updatedPost = { ...postDetail };
            updatedPost.attributes.show_comments_section = true;
            this.setState({ postDetail: updatedPost, currentPostCommentsList: [], postCommentsCurrentPage: 1, currentPostCommentMetaData: null }, async () => {
                await this.getPostComments(postId);
                this.getCommentMentionsList();
            })
        }
    }

    handleUpdatePostCommentsReplyCount = (commentId: number | string, action?: string) => {
        const updatedPostCommentsList = this.state.currentPostCommentsList.map((item) => {
            if (item.id === commentId) {
                if (action === "decrease") {
                    item.attributes.reply_count -= 1
                } else {
                    item.attributes.reply_count += 1
                }
            }
            return item
        })
        this.setState({ currentPostCommentsList: updatedPostCommentsList })
    }

    handleShowCommentReplySection = (commentId: number | string) => {
        const updatedCommentRepliesList = this.state.currentPostCommentsList.map((item) => {
            if (item.attributes) {
                if (item.id === commentId) {
                    item.attributes.show_replies_section = true
                } else {
                    item.attributes.show_replies_section = false
                }
            }
            return item
        })
        this.setState({ currentPostCommentsList: updatedCommentRepliesList })
    }

    handleHideCommentReplySection = (commentId: number | string) => {
        const updatedCommentRepliesList = this.state.currentPostCommentsList.map((item) => {
            if (item.id === commentId) {
                item.attributes.show_replies_section = false
            }
            return item
        })
        this.setState({ currentPostCommentsList: updatedCommentRepliesList })
    }

    handleChangePostCommentsPageNumber = (postId: string) => {
        this.setState({ postCommentsCurrentPage: this.state.postCommentsCurrentPage + 1}, () => this.getPostComments(postId))
    }

    handleGetCommentMentionsListCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson?.data) {
            const mentionsUserList: Array<IMentionInputType> = responseJson.data?.map((item: { attributes: { account_info: { id: number, user_name: string, name: string } } }) => {
                return {
                    id: item.attributes.account_info.id.toString(),
                    display: item.attributes.account_info.user_name,
                }
            })
            this.setState({
                commentMentionUsersList: [...mentionsUserList],
            });
        } else {
            this.setState({ commentMentionUsersList: [] });
        }
    };

    scrollToTopOnAddComment = () => {
        const topDiv = document.getElementById("top_comment_div");
        if (topDiv) {
            topDiv.scrollTo({
                top: 0,
                behavior: "smooth"
            })
        }
    };

    onLikePostComment = async (commentId: string | number) => {
        const body = {
            like_type: "comment",
            likeable_id: commentId,
        };
        this.postCommentLikeDislikeApiCallId = await helper.apiCall({
            method: configJSON.PostAPiMethod,
            contentType: configJSON.validationApiContentType,
            token: this.state.token,
            endPoint: `${configJSON.likePostEndPoint}`,
            body,
        });
        this.setState({
            currentLikedCommentId: commentId,
        });
    };

    likePostCommentApiCallHandler = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && (responseJson.message || responseJson.data)) {
            const { currentLikedCommentId, currentPostCommentsList } = this.state;
            const updatedCommentsList = currentPostCommentsList.map((commentItem) => {
                if (commentItem.id === currentLikedCommentId) {
                    if (commentItem.attributes.liked_by_me) {
                        commentItem.attributes.total_likes -= 1;
                    } else {
                        commentItem.attributes.total_likes += 1;
                    }
                    commentItem.attributes.liked_by_me = !commentItem.attributes.liked_by_me;
                }
                return commentItem;
            });
            this.setState({
                currentPostCommentsList: updatedCommentsList,
            });
        } else {
            const likePostCommentErrMsg = responseJson?.errors?.[0]?.message
            ? responseJson.errors[0].message
            : "Something went wrong, please try again!";
          helper.showErrorToast(likePostCommentErrMsg);
        }
        helper.hideLoader();
    };

    handleGetPostCommentLikedUsersListCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson?.data?.data) {
            this.setState({
                currentPostLikedUserList: [...responseJson.data.data],
            });
        } else {
            this.setState({ currentPostLikedUserList: [] });
        }
        helper.hideLoader();
    };

    getCommentLikedUsersList = async (commentId: number | string) => {
        helper.showLoader();
        this.postCommentLikedUsersListApiCallId = await helper.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            token: this.state.token,
            endPoint: `${configJSON.likedUsersListApiEndpoint}?comment_id=${commentId}`,
        });
    }

    getPostComments = async (postId: string | number) => {
        this.setState({
            isLoadingComments: true,
        });
        this.getCurrentPostCommentsApiCallId = await helper.apiCall({
            method: configJSON.validationApiMethodType,
            contentType: configJSON.exampleApiContentType,
            token: this.state.token,
            endPoint: `${configJSON.commentsListApiEndpoint}?page_no=${this.state.postCommentsCurrentPage}&per_page=15&commentable_id=${postId}&order=desc`,
        });
    };

    handleGetCommentsListApiCall = (message: Message) => {
        const response = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (response?.meta) {
            this.setState({ currentCommentModal: response?.meta })
        }
        if (response && response.data) {
            this.setState({
                currentPostCommentsList: _.uniqBy([...this.state.currentPostCommentsList, ...response.data], "id")
            })
        }
        this.setState({
            isLoadingComments: false,
        });
    };

    onAddPostComment = async (comment: { text: string; postId: string }) => {
        this.setState({
            isLoadingComments: true,
        });
        const request = {
            comment: {
                commentable_id: comment.postId,
                commentable_type: "BxBlockPosts::Post",
                comment: comment.text,
            },
        };
        this.createCommentApiCallId = await helper.apiCall({
            method: configJSON.PostAPiMethod,
            contentType: configJSON.exampleApiContentType,
            token: this.state.token,
            endPoint: `${configJSON.commentsListApiEndpoint}`,
            body: request,
        });
    };

    handleAddPostCommentApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.data) {
            const { postDetail, currentPostCommentsList } = this.state;
            let updatedCommentsList = [{ ...responseJson.data }, ...currentPostCommentsList];
            if(postDetail) {
                const updatedPost = { ...postDetail};
                updatedPost.attributes.comments_count += 1;
                this.setState({
                    currentPostCommentsList: _.uniqBy(updatedCommentsList, "id"),
                    postDetail: updatedPost,
                });
                this.scrollToTopOnAddComment();
            }
        } else {
            helper.showErrorToast("Something went wrong! please try again");
        }
        this.setState({
            isLoadingComments: false,
        });
    };

    onDeletePostComment = async (commentId: string | number) => {
        this.setState({
            isLoadingComments: true,
            currentSelectedCommentId: commentId,
        });
        const { typeId, token, typeOfPost, postId } = this.state;
        this.deleteCommentApiCallId = await helper.apiCall({
            method: configJSON.deletePostAPiMethod,
            endPoint: `${configJSON.commentsListApiEndpoint}/${commentId}?${typeOfPost}_id=${typeId}&commentable_id=${postId}`,
            contentType: configJSON.exampleApiContentType,
            token: token,
        });
    };

    handleDeletePostCommentApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.message) {
            const { postDetail, currentPostCommentsList, currentSelectedCommentId } = this.state;
            
            let updatedCommentsList = currentPostCommentsList.filter((item) => item.id !== currentSelectedCommentId);
            if(postDetail) {
                const updatedPost = { ...postDetail};
                updatedPost.attributes.comments_count -= 1;
                this.setState({
                    currentPostCommentsList: _.uniqBy(updatedCommentsList, "id"),
                    postDetail: updatedPost,
                });
            }
        } else {
            helper.showErrorToast("Something went wrong! please try again");
        }
        this.setState({
            isLoadingComments: false,
            currentSelectedCommentId: "",
        });
    };

    getCommentMentionsList = async () => {
        const { typeId, typeOfPost } = this.state;
        this.getMentionedUsersApiCallId = await helper.apiCall({
            method: configJSON.validationApiMethodType,
            contentType: configJSON.exampleApiContentType,
            token: this.state.token,
            endPoint: `${configJSON.searchMention}?${typeOfPost}_id=${typeId}`,
        });
    };

    handleGetPostDetailCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson?.data) {
            this.setState({
                postDetail: responseJson.data,
            }, () => {
                this.handleOpenPostCommentsSection()
            });
        } else {
            this.setState({ postDetail: null });
            const errorPostToastMsg = responseJson?.errors?.[0]?.message
                ? responseJson.errors[0].message
                : "Something went wrong, please try again!";
            helper.showErrorToast(errorPostToastMsg);
        }
        helper.hideLoader();
    };

    getPostDetail = async () => {
        helper.showLoader();
        const token = await getStorageData("authToken");
        const type = helper.getParamFromRoute(this.props.navigation, "type")
        const typeId = helper.getParamFromRoute(this.props.navigation, "typeId");
        const postId = helper.getParamFromRoute(this.props.navigation, "postId");
        this.setState({ postId, typeId, typeOfPost: type });
        const type_id = type === "event" ? "event_id" : "group_id"
        const endpoint = type === "event" ? configJSON.getPostDetailEndpoint : configJSON.groupPostApiEndpoint;
        this.getPostDetailApiCallId = await helper.apiCall({
            method: configJSON.validationApiMethodType,
            contentType: configJSON.validationApiContentType,
            token,
            endPoint: `${endpoint}/${postId}?${type_id}=${typeId}`,
        });
    };

    onLikeCurrentPost = async () => {
        const body = {
            likeable_id: this.state.postId,
            like_type: "post",
        };
        this.likeCurrentPostApiCallId = await helper.apiCall({
            method: configJSON.PostAPiMethod,
            contentType: configJSON.validationApiContentType,
            token: this.state.token,
            endPoint: `${configJSON.likePostEndPoint}`,
            body,
        });
    };

    likePostApiCallHandler = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && (responseJson.message || responseJson.data)) {
            const { postDetail } = this.state;
            if (postDetail) {
                let updatedPostDetail = { ...postDetail };
                if (updatedPostDetail.attributes.liked_by_me) {
                    updatedPostDetail.attributes.total_likes -= 1;
                } else {
                    updatedPostDetail.attributes.total_likes += 1;
                }
                updatedPostDetail.attributes.liked_by_me = !updatedPostDetail.attributes.liked_by_me;
                this.setState({
                    postDetail: { ...updatedPostDetail },
                });
            }
        } else {
            const likePostErrMsg = responseJson?.errors?.[0]?.message
                ? responseJson.errors[0].message
                : "Something went wrong, please try again!";
            helper.showErrorToast(likePostErrMsg);
        }
    };

    handleGetPostLikedUsersListCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson?.data?.data) {
            this.setState({
                currentPostLikedUserList: [...responseJson.data.data],
            });
        } else {
            this.setState({ currentPostLikedUserList: [] });
        }
        helper.hideLoader();
    };

    getLikedUsersList = async (postId: number | string) => {
        helper.showLoader();
        this.currentPostLikedUsersListApiCallId = await helper.apiCall({
            method: configJSON.validationApiMethodType,
            contentType: configJSON.validationApiContentType,
            token: this.state.token,
            endPoint: `${configJSON.likedUsersListApiEndpoint}?post_id=${postId}`,
        });
    }

    setCurrentPostLikedUserList = () => {
        this.setState({
            currentPostLikedUserList: undefined,
        });
    };

    redirectTo = (endpoint: string, params?: { [key: string]: string | number }) => {
        this.props.navigation.navigate(endpoint, params)
    }

    // Customizable Area End
}