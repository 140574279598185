import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import { EmailList, ContactList, IInterest, UserContact } from "../../../components/src/CommonTypes";
import { GroupMember, GroupType, IGroup, InvitedMember } from "./types";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
import { searchContactApiCallHandler, fetchPhoneBookContacts, handleFetchPhoneBookContactsAPICall } from "../../chat/src/actions";

export type ContactResponse = { 
    type: string, 
    id: number, 
    attributes: { 
        email: string, 
        full_phone_number: string, 
        full_name: string, 
        id: number,
        image_url?: string
    } 
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    id: string;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: string;
    loading: boolean;
    imageInputRef: React.RefObject<HTMLInputElement> | null;
    selectedImage: string | null;
    imageError: string | null;
    base64Image: File | null;
    isFormUpdated: boolean;
    title: string;
    description: string;
    groupType: string;
    email: string;
    emailError: string;
    openEmailDropdown: boolean;
    selectedEmailList: EmailList[];
    emailList: EmailList[];
    contactList: ContactList[];
    phoneBookContactList: ContactList[];
    selectedContactList: ContactList[];
    openContactList: boolean;
    viewEmailAndContactData: {
        value: EmailList[] | ContactList[];
        type: "email" | "contact";
    } | null;
    auto_approve: boolean;
    interests: IInterest[];
    selectedInterest: IInterest[];
    currentSelectedInterest: IInterest[];
    interestModalOpen: boolean;
    isEditMode: boolean;
    groupId: number | string;
    userId: number | null;
    isLoadingContacts: boolean;
    callback?: any;
    registeredContactsPagination: {
        per_page: number,
        page_no: number,
        hasMore: boolean
    }
    isNewInterestAddApiRunning: boolean;
    // Customizable Area End
}
interface SS { }

export default class AddGroupController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    emailSuggestionAPICallId: string = "";
    searchContactApiCallId: string = "";
    fetchContactsApiCallId: string = "";
    fetchInterestsApiCallId: string = "";
    addnewInterestApiCallId: string = "";
    createGroupApiCallId: string = "";
    getGroupDetailApiCallId: string = "";
    fetchPhoneBookApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            token: "",
            loading: false,
            imageInputRef: React.createRef<HTMLInputElement>(),
            selectedImage: null,
            imageError: null,
            base64Image: null,
            isFormUpdated: false,
            title: "",
            description: "",
            groupType: "",
            email: "",
            emailError: "",
            openEmailDropdown: false,
            selectedEmailList: [],
            emailList: [],
            selectedContactList: [],
            openContactList: false,
            viewEmailAndContactData: null,
            contactList: [],
            phoneBookContactList: [],
            auto_approve: true,
            interests: [],
            selectedInterest: [],
            currentSelectedInterest: [],
            interestModalOpen: false,
            isEditMode: false,
            groupId: "",
            userId: null,
            isLoadingContacts: false,
            registeredContactsPagination: {
                per_page: 50,
                page_no: 1,
                hasMore: true
            },
            isNewInterestAddApiRunning: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const userData = await helper.getUserData();
        if (userData) {
            this.setState({
                userId: userData.id
            })
        }
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = message.getData(getName(MessageEnum.SessionResponseToken));
            if (!token) {
                token = await helper.getStorageData("authToken");
            }
            this.setState({
                token: token
            },()=>{
                this.fetchInterests();
                const groupId = this.props.navigation.getParam("id");
                if (groupId) {
                    this.setState({
                        groupId: groupId,
                        isEditMode: true
                    }, () => {
                        this.getGroupDetail();
                    })
                }
            })
        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            switch (webApiRequestCallId) {
                case this.emailSuggestionAPICallId:
                    this.handleEmailSuggestionAPICall(message)
                    break;
                case this.fetchContactsApiCallId:
                    this.handleFetchContactsAPICall(message)
                    break;
                case this.searchContactApiCallId:
                    this.searchContactApiCallHandler(message)
                    break;
                case this.fetchInterestsApiCallId:
                    this.handleFetchInterestsAPICall(message)
                    break;
                case this.addnewInterestApiCallId:
                    this.handleAddNewInterestApiCall(message)
                    break;
                case this.createGroupApiCallId:
                    this.handleCreateGroupApiCall(message);
                    break;
                case this.getGroupDetailApiCallId:
                    this.handleGetGroupDetailApiCall(message)
                    break;
                case this.fetchPhoneBookApiCallId:
                    this.handleFetchPhoneBookContactsApiCal(message);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start

    onSelectImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const result = await helper.covertToObjectUrl(event);

        if (!result.imgSizeExceed) {
            this.setState({
                selectedImage: result.selectedImage,
                imageError: null,
                isFormUpdated: true
            })
            if(event.target.files){
                this.setState({
                    base64Image: event.target.files[0]
                })
            }
            
        } else {
            this.setState({
                imageError: "Please upload jpg and png up to 10 MB."
            })
        }
    }

    onChangeInputs = (value: string, name: string) => {
        const sanitizedValue = value.replace(/^\s+/, '');
        this.setState(prevState => ({ ...prevState, [name]: sanitizedValue, isFormUpdated: true }))
    }

    redirectTo = (endpoint: string, params?: { [key: string]: string | number }) => {
        this.props.navigation.navigate(endpoint, params)
    }

    goBack = () => {
        this.props.navigation.goBack()
    }

    onEmailChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        this.setState({ email: value, emailError: "", isLoadingContacts: true })
        this.emailSuggestionAPICallId = await helper.apiCall({
            method: "GET",
            contentType: webConfigJSON.validationApiContentType,
            token: this.state.token,
            endPoint: `account_block/accounts/auto_suggestions?email=${value}`
        })
    }

    handleEmailSuggestionAPICall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.suggestion_list) {
            this.setState({
                emailList: responseJson.suggestion_list,
                isLoadingContacts: false
            })
        } else {
            this.setState({
                emailList: [],
                isLoadingContacts: false
            })
        }
    }

    openSelectEmailDropdown = () => {
        this.setState(() => ({ openEmailDropdown: true }))
    }

    closeSelectEmailDropdown = () => {
        this.setState(() => ({ openEmailDropdown: false }))
    }

    onEnterEmail = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        event.currentTarget.blur()
        //If user enter a invalid email
        if (!helper.isValidEmail(value)) {
            this.setState({
                openEmailDropdown: false,
                emailError: "*Please enter a valid email"
            })
            return;
        }

        //If user enter a already selected email
        if (this.state.selectedEmailList.find(email => email.email === value)) {
            this.setState({
                openEmailDropdown: false,
                emailError: "This email is already selected"
            })
            return;
        }

        const registeredEmail = this.state.emailList.find((item) => item.email === value);
        let addedEmailGroup = { email: value, id: -1, full_name: "" };
        if (registeredEmail) {
            addedEmailGroup.email = registeredEmail.email;
            addedEmailGroup.id = registeredEmail.id;
            addedEmailGroup.full_name = registeredEmail.full_name;
        }

        this.setState(prevState => {
            return {
                selectedEmailList: [...prevState.selectedEmailList, { ...addedEmailGroup}],
                isFormUpdated: true,
                emailError: "",
                openEmailDropdown: false,
                email: ""
            }
        })
    }

    onSelectEmail = (email: EmailList) => {
        if (!this.state.selectedEmailList.find(item => item.email === email.email)) {
            this.setState(prevState => {
                return {
                    openEmailDropdown: false,
                    isFormUpdated: true,
                    selectedEmailList: [...prevState.selectedEmailList, email],
                    email: ""
                }
            })
        } else {
            this.setState({
                openEmailDropdown: false,
                email: ""
            })
        }
    }

    onRemoveEmail = (emailId: string) => {
        const newEmailList = this.state.selectedEmailList.filter(email => email.email !== emailId);
        this.setState({
            isFormUpdated: true,
            selectedEmailList: newEmailList
        })
    }

    findItemPresentInEmailAndContactList = (accountId: number | string) => {
        const { selectedEmailList, selectedContactList } = this.state;
        const selected = !!(selectedEmailList.find(email=> email.id === accountId));
        return selected;
    }

    findItemPresentContactListForGroupEmail = (accountId: number | string) => {
        const { selectedEmailList, selectedContactList } = this.state;
        const selected = !!(selectedEmailList.find(email=> email.id === accountId) || selectedContactList.find(contact=> contact.id === accountId) || selectedContactList.find(contact=> contact.full_phone_number === accountId));
        return selected;
    }

    openContactList = () => {
        this.setState({
            openContactList: true,
            registeredContactsPagination:{
                per_page: 50,
                page_no: 1,
                hasMore: true
            },
        })
        this.fetchPhoneBookContacts();
    }

    onRemoveContact = (contactId: number) => {
        const newContactList: ContactList[] = this.state.selectedContactList.filter(contact => contact.id !== contactId);
        this.setState({
            isFormUpdated: true,
            selectedContactList: newContactList
        })
    }

    viewEmailAndContact = (type: "email" | "contact") => {
        if (type === "email") {
            this.setState({
                viewEmailAndContactData: { type, value: this.state.selectedEmailList }
            })
        } else if (type === "contact") {
            this.setState({
                viewEmailAndContactData: { type, value: this.state.selectedContactList }
            })
        }
    }

    closeContactList = () => {
        this.setState({
            openContactList: false,
            registeredContactsPagination: {
                per_page: 50,
                page_no: 1,
                hasMore: true
            },
            contactList: [],
            phoneBookContactList: []
        })
    }

    onSearchContact = async (contact: string) => {
        this.setState({ contactList: [], phoneBookContactList: [], isLoadingContacts: true }, async() => {
            if(contact){
                this.searchContactApiCallId = await helper.apiCall({
                    method: "GET",
                    contentType: webConfigJSON.validationApiContentType,
                    token: this.state.token,
                    endPoint: `${webConfigJSON.searchContactsApiEndPoint}?query=${contact}`
                })
            }else{
                this.setState({
                    registeredContactsPagination: {
                        per_page: 50,
                        page_no: 1,
                        hasMore: true
                    }
                })
                this.fetchPhoneBookContacts();
            }
        })
    }

    searchContactApiCallHandler = (message: Message) => {
        const response = searchContactApiCallHandler(message);
        this.setState({
            contactList: response.data,
            phoneBookContactList: response.phoneBookContactsList,
            isLoadingContacts: false
        })
    }

    onAddContacts = (contacts: ContactList[]) => {
        this.setState(prevState => {
            return {
                selectedContactList: [...contacts],
                isFormUpdated: true,
                openContactList: false
            }
        })
    }

    fetchContacts = async (callback?: any) => {
        this.setState({
            isLoadingContacts: true,
            callback
        })
        const {
            registeredContactsPagination,
            token
          } = this.state;
        this.searchContactApiCallId = "";
        this.fetchContactsApiCallId = await helper.apiCall({
            method: "GET",
            contentType: webConfigJSON.validationApiContentType,
            token: token,
            endPoint: `account_block/accounts/registered_users?per_page=${registeredContactsPagination.per_page}&page_no=${registeredContactsPagination.page_no}`
        })
    }

    handleFetchContactsAPICall = (message: Message) => {
        const response = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (response && response.data && response.data.users) {
            this.setState(prevState => {
                return {
                    contactList: [...prevState.contactList, ...response.data.users]
                }
            })
        }
        this.setState(prevState => {
            return {
                isLoadingContacts: false,
                registeredContactsPagination: {
                    ...prevState.registeredContactsPagination,
                    page_no: prevState.registeredContactsPagination.page_no + 1,
                    hasMore: response && response.data && response.data.users ? true : false
                }
            }
        })
        if(this.state.callback){
            this.state.callback()
        }
    }

    fetchPhoneBookContacts = async ()=>{
        this.setState({
            isLoadingContacts: true
        })
        this.searchContactApiCallId = "";
        this.fetchPhoneBookApiCallId = await fetchPhoneBookContacts({
            token: this.state.token
        })  
    }

    handleFetchPhoneBookContactsApiCal = (message: Message) => {
        const response = handleFetchPhoneBookContactsAPICall(message);
        this.setState({
            phoneBookContactList: response.data
        }, () => {
            this.fetchContacts();
        })
    }

    closeViewEmailAndContactModal = () => {
        this.setState({
            viewEmailAndContactData: null
        })
    }

    onDoneViewEmailAndContact = (value: EmailList[] | ContactList[]) => {
        const { viewEmailAndContactData } = this.state;

        if (viewEmailAndContactData && viewEmailAndContactData.type === "email") {
            this.setState({
                selectedEmailList: value as EmailList[],
                viewEmailAndContactData: null,
                isFormUpdated: true
            })
        } else if (viewEmailAndContactData && viewEmailAndContactData.type === "contact") {
            this.setState({
                selectedContactList: value as ContactList[],
                isFormUpdated: true,
                viewEmailAndContactData: null,
            })
        }
    }

    onChangeAutoApprovalRequest = (value: boolean)=>{
        this.setState({
            auto_approve: value,
            isFormUpdated: true,
        })
    }

    onOpenSelectInterestModal = () => {
        this.setState({
            interestModalOpen: true,
            currentSelectedInterest: this.state.selectedInterest
        })
    }

    onCloseSelectInterestModal = () => {
        this.setState({
            interestModalOpen: false,
            currentSelectedInterest: []
        })
    }

    fetchInterests = async ()=>{
        this.fetchInterestsApiCallId = await helper.apiCall({
            method: "GET",
            contentType: webConfigJSON.validationApiContentType,
            token: this.state.token,
            endPoint: webConfigJSON.interestApiEndPoint
        })
    }

    handleFetchInterestsAPICall = (message: Message) => {
        const response = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (response && response.data) {
            this.setState({
                interests: response.data
            })
        } else {
            this.setState({
                interests: []
            })
        }
    }

    setCurrentSelectedInterest = (interests: IInterest[])=>{
        this.setState({
            currentSelectedInterest: interests
        })
    }

    onAddInterests = (interests: IInterest[])=>{
        this.setState({
            selectedInterest: interests,
            interestModalOpen: false,
            currentSelectedInterest: [],
            isFormUpdated: true
        })
    }

    onSubmitNewInterest = async (event: string) => {
        this.setState({ isNewInterestAddApiRunning: true })
        this.addnewInterestApiCallId = await helper.apiCall({
            contentType: webConfigJSON.validationApiContentType,
            method: "POST",
            endPoint: webConfigJSON.addNewInterestApiEndPoint,
            body: {
                name: event
            }
        })
    }

    handleAddNewInterestApiCall = (message: Message) => {
        const response = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (response && response.data) {
            const modifiedResponseData = response.data;
            modifiedResponseData.attributes.manual = true;
            this.setState((prevState) => {
                return {
                    currentSelectedInterest: [...prevState.currentSelectedInterest, modifiedResponseData],
                    isNewInterestAddApiRunning: false
                }
            })
            this.fetchInterests();
        }
    }

    removeInterestFromSelectedInterest = (interestId: string) => {
       this.setState(prevState=>{
         return {
            selectedInterest: prevState.selectedInterest.filter(interest=> interest.id !== interestId),
            isFormUpdated: true
         }
       })
    }

    getInvitedGroupNonRegisteredMembers = (event: { attributes: IGroup}) => {
        const { invitation_members } = event.attributes
        if (invitation_members) {
            const invitedGroupMembersContact: ContactList[] = [];
            invitation_members.forEach((member: InvitedMember) => {
                if (!helper.isValidEmail(member.invite_record)) {
                    invitedGroupMembersContact.push({
                        name: member.contact_name || "Unknown",
                        full_phone_number: member.invite_record,
                        id: member.id,
                        email: "",
                        isNonRegistered: true,
                        image_url: member?.image_url || "",
                    })
                }
            })
            return invitedGroupMembersContact;
        }
        return []
    }

    handleGetGroupDetailApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        helper.hideLoader();
        if (responseJson && responseJson.data) {
            let emailSelectedInvites: Array<{ id: number, full_name: string, email: string }> = [];
            let selectedInterest: IInterest[] = [];
            let registeredContacts: ContactList[] = [];
            const responseData = responseJson.data.attributes;
            responseData.invitation_members.map((member: { [key: string]: string }) => {
                if (helper.isValidEmail(member.invite_record)) {
                    emailSelectedInvites.push({
                        id: -1,
                        full_name: "",
                        email: member.invite_record
                    })
                }
            })
            responseData.interests.map((item: { id: string, name: string, role: string}) => {
                if (item.role !== "user") {
                    selectedInterest.push({
                        id: item.id,
                        attributes: {
                            name: item.name,
                            created_at: "",
                            manual: false
                        },
                        type: ""
                    })
                } else {
                    selectedInterest.push({
                        id: item.id,
                        attributes: {
                            name: item.name,
                            created_at: "",
                            manual: true
                        },
                        type: ""
                    })
                }
            }) 
            const filteredMembers = responseData.members.filter((member: GroupMember) => {
                // Extract account ID from the member's attributes
                const accountId = member.attributes.account_id;
              
                // Find the corresponding user contact
                const userContact = responseData.user_contacts.find((contact: UserContact) => contact.id === accountId);
              
                // Exclude members where the corresponding user contact has `organizer: true`
                return !(userContact?.organizer === true);
            });
            filteredMembers.forEach((member: GroupMember) => {
                registeredContacts.push({
                    name: member.attributes.account_info.full_name,
                    full_phone_number: member.attributes.account_info.full_phone_number,
                    id: member.attributes.account_id,
                    email: "",
                    isNonRegistered: false,
                    image_url: member.attributes.account_info.image_url
                })
            })
            const nonRegisteredUserContacts = this.getInvitedGroupNonRegisteredMembers(responseJson.data)
            this.setState({
                selectedImage: responseData.image_url,
                title: responseData.name,
                description: responseData.description,
                selectedEmailList: [
                    ...responseData?.assignee_email,
                    ...emailSelectedInvites
                ],
                groupType: responseData.group_type,
                auto_approve: responseData.auto_approve,
                selectedInterest: selectedInterest,
                selectedContactList: [ ...registeredContacts, ...nonRegisteredUserContacts],
            })
        } else {
            this.redirectTo("Groups")
        }

    }

    getGroupDetail = async () => {
        helper.showLoader();
        const token = await helper.getStorageData("authToken");
        this.getGroupDetailApiCallId = await helper.apiCall({
            method: "GET",
            endPoint: `${webConfigJSON.groupApiEndPoint}/${this.state.groupId}`,
            contentType: webConfigJSON.validationApiContentType,
            token
        })
    }

    onCreateGroup = async ()=> {
        helper.showLoader();
        const { isEditMode, groupId } = this.state;
        if (isEditMode) {
            this.createGroupApiCallId = await helper.apiCall({
                method: "PUT",
                token: this.state.token,
                endPoint: `${webConfigJSON.groupApiEndPoint}/${groupId}`,
                body: this.getFormDataForPost(),
                type: "formData"
            })
        } else {
            this.createGroupApiCallId = await helper.apiCall({
                method: "POST",
                token: this.state.token,
                endPoint: webConfigJSON.groupApiEndPoint,
                body: this.getFormDataForPost(),
                type: "formData"
            })
        }
    }

    handleCreateGroupApiCall = (message: Message) => {
        const response = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (response && response.data) {
            if(this.state.isEditMode) {
                helper.showSuccessToast("Successfully group updated!")
                this.props.navigation.history.push(`/groups?group_id=${response.data?.id}`)
            } else {
                helper.showSuccessToast("Successfully group created!")
                this.redirectTo("Groups")
            }
        } else{
            const toastMsg = response && response.error && response.error[0] ? response.error[0] : "Something went wrong please try again!"
            helper.showErrorToast(toastMsg)
        }
        helper.hideLoader();
    }

    getFormDataForPost = ()=>{
        const {
          title,
          description,
          selectedInterest,
          base64Image,
          groupType,
          selectedEmailList,
          selectedContactList,
          auto_approve
        } = this.state;
        const bodyData = new FormData();
        const nonRegisteredUsers = [ ...selectedEmailList.filter(email=> email.id === -1).map(email=> email.email), ...selectedContactList.filter(contact => contact.isNonRegistered).map(contact => contact.full_phone_number)]
        if(base64Image){
            bodyData.append("group[image]", base64Image);
        }
        bodyData.append("group[name]", title);
        bodyData.append("group[description]", description);
        bodyData.append("group[group_type]", groupType);
        bodyData.append("group[interest_ids]", JSON.stringify(selectedInterest.map(interest=> interest.id)));
        bodyData.append("group[member_ids]", JSON.stringify([
            ...selectedContactList.filter(contact => !contact.isNonRegistered).map(contact=> contact.id)
        ]));
        bodyData.append("group[assignee_email]", JSON.stringify([
            ...selectedEmailList.filter(email=> email.id !== -1).map(email=> email.id),
        ]));
        if(groupType === GroupType.PUBLIC){
            bodyData.append("group[auto_approve]", JSON.stringify(auto_approve));
        }
        bodyData.append("group[non_registered_users]", JSON.stringify(nonRegisteredUsers));
        return bodyData;
    }

    disableCreateBtn = ()=>{
        const {
            title,
            selectedInterest,
            groupType,
            isEditMode,
            isFormUpdated,
        } = this.state;

        if(!title.trim() || selectedInterest.length === 0 || !groupType){
            return true;
        }
        if (isEditMode && !isFormUpdated) {
            return true;
        }
        return false;
    }

    // Customizable Area End
}
